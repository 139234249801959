import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import PlayStore from "../../assets/img/play_store.png";
import AppStore from "../../assets/img/app_store.png";
import qr from "../../assets/img/qr.png";

function DownloadApp({ open, onClode }) {
    let [isOpen, setIsOpen] = useState(true);

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
            <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
                {/* Container to center the panel */}
                <div className="flex min-h-full items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto  rounded bg-white w-[520px] h-[686px] flex flex-col items-center">
                        <Dialog.Title className="text-5xl font-GilroyBold text-fcNavy2 text-center p-8">Get The App</Dialog.Title>
                        <Dialog.Description>
                            <img src={qr} alt="PlayStore" className="text-white w-[350px] h-[350px]" />
                        </Dialog.Description>

                        <p className=" text-2xl font-GilroySemiBold text-fcNavy2 mt-8">
                            SCAN TO DOWNLOAD
                        </p>
                        <p className=" text-lg font-GilroySemiBold text-fcNavy2 mt-8">
                            Or download from the app stores
                        </p>
                        <div className="flex items-center justify-between space-x-5 w-[350px] mt-8">
                            <div className='flex items-center justify-between bg-primary text-white rounded-full px-6 py-3 space-x-2'>
                                <span><img src={PlayStore} alt="PlayStore" className="text-white w-[113px] h-[30px]" /></span>
                            </div>
                            <div className='flex items-center justify-between bg-fcPrimary text-white rounded-full px-6 py-3 space-x-2'>
                                <span><img src={AppStore} alt="App Store" className="text-white w-[113px] h-[30px]" /></span>
                            </div>
                        </div>

                        {/* <button onClick={() => setIsOpen(false)}>Deactivate</button>
                    <button onClick={() => setIsOpen(false)}>Cancel</button> */}
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}

export default DownloadApp;