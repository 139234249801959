import React from 'react'
import redlogo from "../../assets/redlogo.png"
import { HiOutlineX } from "react-icons/hi"; 
import { Link } from 'react-router-dom';

const ChallengeNav = () => {
  return (
    <div className=' w-full'>
      <div className='flex justify-between items-center mx-10 mt-5'>
        <img src={redlogo} alt="redlogo" className='w-[100px]' />
        <Link to="/dashboard">
        <HiOutlineX  className='text-xl'/>
        </Link>
     
      </div>
    </div>
  )
}

export default ChallengeNav
