import React from 'react'
import ProfileImage from "../../../assets/profile-img.png"

const ActiveClients = () => {
  return (
    <div>
      <div className='flex my-4  '>
        <img src={ProfileImage} className="w-14 h-14" />
        <img src={ProfileImage} className="w-14 h-14"/>
        <img src={ProfileImage}className="w-14 h-14" />
       
      </div>
    </div>
  )
}

export default ActiveClients
