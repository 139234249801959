import React, { useState } from "react";
import { Link } from "react-router-dom";
import GoogleFitIcon from "../../../components/icons/GoogleFitIcon";
import BoldAngleArrowRight from "../../../components/icons/BoldAngleArrowRight";
import SideBar from "../sidebar/SideBar";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import streakImage from "../../../assets/img/streaksimg.png";
import workOutImage from "../../../assets/img/workoutimg.png";
import timmerimage from "../../../assets/img/timmerimg.png";
import caloriesImage from "../../../assets/img/caloriesimg.png";
import Button from "../../../components/common/Button";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import SmallHeartIcon from "../../../components/icons/SmallHeartIcon";
import FlowerIcon from "../../../components/icons/FlowerIcon";
import InviteIcon from "../../../components/icons/InviteIcon";
import Modal from "../../../components/common/Modal";
import CloseIcon from "../../../components/icons/CloseIcon";
import CopyLinkIcon from "../../../components/icons/CopyLinkIcon";
import WhatsappIcon from "../../../components/icons/WhatsappIcon";
import TwitterIcon from "../../../components/icons/TwitterIcon";
import Messenger from "../../../components/icons/Messenger";
import FacebookIcon from "../../../components/icons/FacebookIcon";
import EmailIcon from "../../../components/icons/EmailIcon";
import WhiteEmail from "../../../components/icons/WhiteEmail";
import MessageIcon from "../../../components/icons/MessageIcon";
import FacebookIconWithBg from "../../../components/icons/FacebookIconWithBg";
import SmsIcon from "../../../components/icons/SmsIcon";
import WeightIcon from "../../../components/icons/WeightIcon";
import BoldLock from "../../../components/icons/BoldLock";
import WalkIcon from "../../../components/icons/WalkIcon";
import WeightMeterIcon from "../../../components/icons/WeightMeterIcon";

const Statistic = () => {
  const [invite, setInvite] = useState(false);
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85%] ml-[15%] bg-fcGray6 h-screen px-40 py-5">
          <div>
            <nav className=" border-fcGray4 p-5 z-50 bg-fcGray6 sticky top-0">
              <p className="text-2xl text-fcNavy2 font-GilroySemiBold">
                My Statistics
              </p>
            </nav>
            <div className="flex gap-8 mt-5">
              <div className="w-[70%] z-40">
                <div className="bg-white p-4 mb-10 rounded-xl text-center ">
                  <div className="flex gap-4 items-center flex-nowrap  overflow-x-scroll scroll-smooth no-scrollbar cursor-pointer">
                    <div className="bg-fcGray3 p-6 rounded-md items-center justify-center mx-auto mt-4">
                      <BoldLock />
                    </div>
                  </div>
                  <p className="text-fcNavy text-xl font-GilroySemiBold pt-4  ">
                    Statistics Locked
                  </p>
                  <p className="text-sm text-fcNavy py-4">
                    Please allow access to Google Fit to track your weight,
                    steps, calories and
                    <br /> workouts
                  </p>
                  <div className="flex  items-center justify-center space-x-8 bg-fcGray3 p-4 mx-16 mb-4">
                    <Link
                      to=""
                      className="text-fcPrimary font-GilroySemiBold text-xl flex  space-x-4 items-center"
                    >
                      <GoogleFitIcon className="" />
                      <span className="">Sync With Google Fit</span>
                    </Link>
                    <BoldAngleArrowRight />
                  </div>
                </div>

                <div className="bg-white p-4 mb-10 rounded-xl relative">
                  <div className="flex gap-2">
                    <WeightIcon className="h-6 w-6" />
                    <p className="text-xl font-GilroyMedium">Workouts</p>
                  </div>
                  <div className="py-6">
                    <h1 className="text-3xl font-GilroyBold">No data</h1>
                    <p className="text-sm font-GilroyMedium">Last 7 days</p>
                  </div>
                  <div className="">
                    <p className="flex items-center">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">10</span>
                    </p>
                    <p className="flex items-center my-12">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">20</span>
                    </p>
                    <div className="text-xs font-GilroyMedium flex items-center space-x-10 text-center">
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">S</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">S</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">M</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">T</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">W</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">T</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4 text-fcPrimary">
                        F
                      </p>
                      <p className="-mt-12 ">0</p>
                    </div>
                    <div className="absolute bg-fcGray3 p-6 rounded-md items-center justify-center mx-auto top-36 right-60">
                      <BoldLock />
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 mb-10 rounded-xl relative">
                  <div className="flex gap-2">
                    <WeightMeterIcon />
                    <p className="text-xl font-GilroyMedium">Weight</p>
                  </div>

                  <div className="">
                    <p className="flex items-center my-12">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">111</span>
                    </p>
                    <p className="flex items-center">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">105</span>
                    </p>
                    <p className="flex items-center mt-12">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">99</span>
                    </p>
                    <div className="flex items-start justify-around space-x-20 text-xs font-GilroyMedium mb-5">
                      <p>Oct</p>
                      <p>Nov</p>
                      <p>Dec</p>
                    </div>
                    <div className="">
                      <Button className="text-sm font-semibold w-full text-center p-4 border border-fcGray4 rounded-full">
                        LOG WEIGHT
                      </Button>
                    </div>
                    
                    <div className="absolute bg-fcGray3 p-6 rounded-md items-center justify-center mx-auto top-36 right-60">
                      <BoldLock />
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 mt-4 rounded-xl relative">
                  <div className="flex gap-2">
                    <WalkIcon className="w-6 h-6" />
                    <p className="text-xl font-GilroyMedium">Steps</p>
                  </div>
                  <div className="py-6">
                    <h1 className="text-3xl font-GilroyBold">No data</h1>
                    <p className="text-sm font-GilroyMedium">Last 7 days</p>
                  </div>
                  <div className="">
                    <p className="flex items-center">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">10</span>
                    </p>
                    <p className="flex items-center my-12">
                      <hr className="border-fcGray4 w-full" />{" "}
                      <span className="text-fcGray5">20</span>
                    </p>
                    <div className="text-xs font-GilroyMedium flex items-center space-x-10 text-center">
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">S</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">S</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">M</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">T</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">W</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4">T</p>
                      <p className="p-3 border-t-2 w-1/2 border-fcGray4 text-fcPrimary">
                        F
                      </p>
                      <p className="-mt-12 ">0</p>
                    </div>
                    <div className="absolute bg-fcGray3 p-6 rounded-md items-center justify-center mx-auto top-36 right-60">
                      <BoldLock />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-[18%] fixed ml-[51%]">
                <div className="bg-white p-4 mb-5 rounded-xl">
                  <div className="flex items-center justify-between mb-5">
                    <p className="text-xl leading-10 tracking-wide font-GilroyBold">
                      Weekly Progress
                    </p>
                    <ArrowAngleRight />
                  </div>
                  <div>
                    <div className="flex items-center space-x-6 mb-1">
                      <div>
                        <img src={streakImage} alt="" className="w-12 h-12" />
                      </div>
                      <div>
                        <p className="text-xs font-GilroyMedium">Streaks</p>
                        <p className="text-3xl font-GilroyBold">1</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-6 mb-1">
                      <div>
                        <img src={workOutImage} alt="" className="w-12 h-12" />
                      </div>
                      <div>
                        <p className="text-xs font-GilroyMedium">Workout</p>
                        <p className="text-3xl font-GilroyBold">1/5</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-6 mb-1">
                      <div>
                        <img src={timmerimage} alt="" className="w-12 h-12" />
                      </div>
                      <div>
                        <p className="text-xs font-GilroyMedium">Minutes</p>
                        <p className="text-3xl font-GilroyBold">150</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-6 mb-1">
                      <div>
                        <img src={caloriesImage} alt="" className="w-12 h-12" />
                      </div>
                      <div>
                        <p className="text-xs font-GilroyMedium">Calories</p>
                        <p className="text-3xl font-GilroyBold">3536</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="border-fcGray4 mb-5" />
                <div className="bg-white p-4 rounded-xl mb-5">
                  <div className="flex justify-between mb-5">
                    <div>
                      <p className="text-xl leading-10 tracking-wide font-GilroyBold">
                        My Progress
                      </p>
                      <p className="text-sm text-fcGray4 font-GilroyMedium">
                        Oct 31 - Nov 6
                      </p>
                    </div>
                    <ArrowAngleRight />
                  </div>
                  <div>
                    <div className="flex space-x-6 mb-2">
                      <div className="flex items-center justify-center">
                        <div className="p-2 bg-fcPrimary rounded-full">
                          <p className="text-base bg-white px-2 py-3 rounded-full font-GilroyBold ">
                            76%
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-base font-GilroySemiBold">
                          Discipline
                        </p>
                        <p className="text-sm text-fcGray4 font-GilroyMedium">
                          1/4 tasks
                        </p>
                      </div>
                    </div>
                    <div className="flex space-x-6">
                      <div className="flex items-center justify-center">
                        <div className="p-2 bg-fcPrimary rounded-full">
                          <p className="text-base bg-white px-2 py-3 rounded-full font-GilroyBold ">
                            76%
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-base font-GilroySemiBold">Goal</p>
                        <p className="text-sm text-fcGray4 font-GilroyMedium">
                          10/15 kg lost
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Button
                    onClick={() => setInvite(true)}
                    className=" w-full h-[48px] border border-fcGray4 flex items-center p-4 rounded-full"
                  >
                    <InviteIcon className="mr-32" />
                    <span className="text-base font-GilroySemiBold">
                      Invite
                    </span>
                  </Button>
                  <Modal show={invite}>
                    <main>
                      <nav className="flex items-center justify-between mb-6">
                        <p className="text-xl font-GilroyBold">Share this…</p>
                        <CloseIcon
                          onClick={() => setInvite(false)}
                          className="cursor-pointer"
                        />
                      </nav>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <CopyLinkIcon />
                          <span className="text-base font-GilroySemiBold">
                            Copy Link
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <WhiteEmail />
                          <span className="text-base font-GilroySemiBold">
                            Email
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <SmsIcon />
                          <span className="text-base font-GilroySemiBold">
                            messages
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <WhatsappIcon />
                          <span className="text-base font-GilroySemiBold">
                            Email
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <Messenger />
                          <span className="text-base font-GilroySemiBold">
                            Copy Link
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <FacebookIconWithBg />
                          <span className="text-base font-GilroySemiBold">
                            Copy Link
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                          <TwitterIcon />
                          <span className="text-base font-GilroySemiBold">
                            Copy Link
                          </span>
                        </div>
                      </div>
                    </main>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Statistic;
