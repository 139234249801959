import React from "react";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";

import Jane from "../../../assets/Jane.png";
import AccountIcon from "../../../assets/AccountIcon.svg";
import AccountIconWhite from "../../../assets/AccountIconWhite.svg";
import notification from "../../../assets/notification.svg";

const NotificationAlert = () => {
  return (
    <div>
      {/* <main className="w-[85% ml-[15%] bg-fcGray6 px-40 py-5 ">
        <div className="bg-fcGray6 w-full h-full rounded-3xl">
          
          <div className="flex gap-8 mt-2">
            <div className="w-[70%] z-40">
              <div className="bg-white p-4 mb-10 h-[900px] rounded-2xl ">
                <div className=" items-center mx-auto justify-center mt-[300px] text-center">
                  <img
                    src={notification}
                    alt="notofication"
                    className="w-[64px] mx-auto"
                  />
                  <p className="text-[#2A2E43] font-GilroySemiBold text-[20px]">
                    No notifications yet
                  </p>
                  <p className="text-[#2A2E43CC] text-[16px] font-GilroyRegular">
                    Stay tuned! Notifications about <br /> your activity will
                    show up here
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[20%] h-9/10 fixed ml-[49%] mr-96 overflow-y-scroll">
              <div className="bg-white p-4 mb-16 rounded-2xl">
                <div className="flex items-center justify-between py-4">
                  <p className="text-sm leading-10 tracking-wide font-GilroyBold">
                    Who to follow
                  </p>
                  <ArrowAngleRight />
                </div>
                <div>
                  <div>
                    <div className="flex gap-4 items-center">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                </div>
              </div>
              <button className="flex w-full border border-[#B9B9B9] rounded-full items-center pl-4 gap-16 py-4  mt-8 bg-[#F7F7F7] ">
                <img
                  src={AccountIconWhite}
                  alt="accounticon"
                  className="w-[24px]"
                />
                <p className="font-GilroySemiBold text-[16px] text-[#2A2E43]">
                  Invite
                </p>
              </button>
            </div>
          </div>
        </div>
      </main> */}
      <div className=" items-center mx-auto justify-center mt-[300px] text-center">
        <img
          src={notification}
          alt="notofication"
          className="w-[64px] mx-auto"
        />
        <p className="text-fcNavy2 text-xl font-GilroySemiBold  py-4">
          No notifications yet
        </p>
        <p className="text-fcGray text-base font-GilroyRegular">
          Stay tuned! Notifications about <br /> your activity will show up here
        </p>
      </div>
    </div>
  );
};

export default NotificationAlert;
