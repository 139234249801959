import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { TextField } from "./TextField";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import logoimg from "../../../assets/logo.svg";
import "../../../App.css";
import FacebookIcon from "../../../components/icons/FacebookIcon";

const Register = () => {
  const [open, setOpen] = useState(false);

  // handle toggle
  const toggle = () => {
    setOpen(!open);
  };

  const validate = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .min(8),

    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref('password'), null], 'Password must match')
    //   .required('Confirm password is required'),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        // confirmPassword: ''
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
        <main className="  flex flex-row gap-4  ">
          <section className="left-wrapper md:w-2/3 w-full bg-primary   h-screen  ">
            <div id="hero" className="h-screen w-full"></div>
            <div id="leftsideimg">
              <img
                src={logoimg}
                alt="logo"
                className="w-[120px] absolute top-6 left-[100px] "
              />
            </div>
          </section>
          <section className="right  border-lightGray w-1/4  items-center justify-center mx-[80px] my-auto ">
            <div className="">
              <h1 className="text-center text-[#2A2E43] text-[32px] font-GilroySemiBold">
                Create Account
              </h1>
            </div>
            <div className="mt-6">
              <Form>
                <div className="mb-2">
                  <TextField name="email" type="email" placeholder="Email" />
                  <div className=" items-center justify-center relative mx-auto ">
                    <TextField
                      name="password"
                      type={open === false ? "password" : "text"}
                      placeholder="Password"
                    />
                    <div className="text-2xl absolute top-4 right-5">
                      {open === false ? (
                        <AiFillEye onClick={toggle} />
                      ) : (
                        <AiFillEyeInvisible onClick={toggle} />
                      )}
                    </div>
                  </div>

                  <Link to="/onboard">
                    <button className="bg-primary w-full px-4 py-3 rounded-full text-yellow-50 mt-8 text-sm ">
                      CREATE ACCOUNT
                    </button>
                  </Link>

                  <div className="flex  justify-center gap-2  items-center mt-8">
                    <hr className="text-fcNavy3 w-[200px]" />
                    <p className="text-fcNavy3 text-sm">OR</p>
                    <hr className="text-fcNavy3 w-[200px]" />
                  </div>
                  <div className="my-4">
                    <button className="flex text-white gap-7 opacity-100 border border-fcGray4 w-full rounded-full py-3 px-4 mx-auto  cursor-pointer bg-[#3F51B5]">
                      <FacebookIcon />
                      <p className="text-sm font-GilroyRegular my-auto">
                        CONTINUE WITH FACEBOOK
                      </p>
                    </button>
                    <button className="flex gap-7 opacity-100 border border-fcGray4 mt-4  w-full rounded-full  py-3 px-4 mx-auto cursor-pointer">
                      <FcGoogle className="text-xl cursor-pointer" />
                      <p className="text-sm text-center font-GilroyRegular my-auto ">
                        CONTINUE WITH GOOGLE
                      </p>
                    </button>
                  </div>
                  <div className="my-8">
                    <p className=" text-sm opacity-100 items-center flex justify-center text-gray-400">
                      Already have an account?{" "}
                      <Link
                        className="text-primary underline text-sm  pl-2"
                        to="/login"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </div>

               
                <div>
                  <p className="items-center text-center  justify-center mt-6 text-[12px] text-[#2A2E43CC] ">
                    By Clicking Create Account, you agree to our
                    <span className="underline px-1 cursor-pointer">
                      {" "}
                      Privacy Policy<br />
                    </span>{" "}
                  
                    and
                    <span className="underline px-1 cursor-pointer">
                      {" "}
                      Terms of use{" "}
                    </span>
                  </p>
                </div>
              </Form>
            </div>
          </section>
        </main>
      )}
    </Formik>
  );
};

export default Register;
