import React from 'react';
import profileImageOne from '../../../assets/img/profile-1.png';
import profileImageTwo from '../../../assets/img/profile-2.png';
import profileImageThree from '../../../assets/img/profile-3.png';
import challengeCardImage from '../../../assets/img/emailbg-1.png';
import AddUserIcon from '../../../components/icons/AddUserIcon';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const ChallengeCard = () => {
  const [joinChallenge, setJoinChallenge] = useState(false);
  return (
    <div>
      <div className="p-2 relative ">
        <div className="h-[218px] w-[350px] bg-gradient-to-r from-black via-purple-500 to-fcPrimary">
          <img
            src={challengeCardImage}
            alt=""
            className=" hover:scale-[1.05] transition ease-in-out rounded-xl h-[218px] w-[350px]"
          />
        </div>
        <div class="absolute bottom-8 w-[350px] px-2">
          <p class="text-[12px] text-white font-GilroyMedium mb-1">
            10 days + 8 workouts
          </p>
          <p class="text-[16px] text-white font-GilroySemiBold mb-1">
            6-pack ABS with Alper
          </p>
          <div class="flex items-center justify-between">
            <div class="flex">
              <img src={profileImageOne} alt="" class="" />
              <img src={profileImageTwo} alt="" class="" />
              <img src={profileImageThree} alt="" class="" />
            </div>
            {/* <div onClick={() => setJoinChallenge(true)}>
              <Link
                to=""
                className={`flex items-center text-white px-3 py-1 rounded-full ${
                  joinChallenge ? 'bg-fcPrimary' : 'bg-[#CC6841]'
                }`}
              >
                <AddUserIcon className="mr-2" />
                <span className={`${joinChallenge ? 'hidden' : 'block'}`}>
                  Join
                </span>
                <span className={`${joinChallenge ? 'block' : 'hidden'}`}>
                  Joined
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
