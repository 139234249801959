import React from "react";
import profileImage from "../../../assets/profile-img.png";

const ChallengeParticipants = () => {
  return (
    <div>
      <div className=" flex-col items-center cursor-pointer relative">
        <div className="w-20 flex items-center justify-center ">
          <img
            src={profileImage}
            alt=""
            className="border-fcGray6 rounded-full "
          />
        </div>
        <p className="text-center">You</p>
        <p className="px-2 text-fcWhite bg-fcPrimary rounded-full text-center absolute bottom-7 right-1">2</p>
      </div>
    </div>
  );
};

export default ChallengeParticipants;
