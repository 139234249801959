import React from 'react';
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding3 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step2">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">What’s your name?</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              Tell us your name. This would be used to identify you
            </p>
          </div>
          <div className="py-4 mx-auto items-center justify-center">

            <input className='mx-auto w-full text-3xl font-GilroyRegular  border-none focus:outline-0 text-center focus:outline-primary ' />
            <hr className=' text-fcGray4' />
          </div>
          <a href="/onboard/step4">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding3;
