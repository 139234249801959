import React, { useState } from "react";
import FieldInput from "../../../components/common/FieldInput";
import SearchIcon from "../../../components/icons/SearchIcon";
import SideBar from "../sidebar/SideBar";
import profileImage from "../../../assets/profile-img.png";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import Button from "../../../components/common/Button";
import { Link } from "react-router-dom";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import yogaImage from "../../../assets/img/yogga.png";
import emailbgImage from "../../../assets/img/emailbg-3.png";
import RatingFilledIcon from "../../../components/icons/RatingFilledIcon";
import RatingEmptyIcon from "../../../components/icons/RatingEmptyIcon";
import CallingIcon from "../../../components/icons/CallingIcon";
import MapIcon from "../../../components/icons/MapIcon";
import TopSearch from "./TopSearch";
import PeopleSearch from "./PeopleSearch";
import CliqSearch from "./CliqSearch";
import GymSearch from "./GymSearch";
import CoachesSearch from "./CoachesSearch";
import DiscoverSubcathegory from "./DiscoverSubcathegory";
import CloseIcon from "../../../components/icons/CloseIcon";
import { GrFormCheckmark } from "react-icons/gr";
import CliqCardMax from "../../../components/common/CliqCardMax";
import UserCardMax from "../../../components/common/UserCardMax";
import CoachCardMin from "../../../components/common/CoachCardMin";
import GymCard from "../../../components/common/GymCard";

const Discover = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentSearchStep, setCurrentSearchStep] = useState(1);
  const [joinCliq, setJoinCliq] = useState(false);
  const [addToCliq, setAddToCliq] = useState(false);

  const handleFilterSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setShowSearch(true);
  };

  const displaySearchCathegory = (currentSearchStep) => {
    switch (currentSearchStep) {
      case 1:
        return <TopSearch />;
      case 2:
        return <PeopleSearch />;
      case 3:
        return <CliqSearch />;
      case 4:
        return <GymSearch />;
      case 5:
        return <CoachesSearch />;

      default:
        break;
    }
  };
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85%] ml-[15%] bg-fcGray6 px-40 py-5">
          <div>
            <nav className="bg-fcGray6 sticky top-0 mb-2 py-5  z-50">
              <h1 className="text-2xl font-GilroySemiBold">Discover</h1>
            </nav>
            <div className="flex">
              <div className="w-[70%]">
                <div className="p-4 border-b border-fcGray4 bg-white rounded-t-xl">
                  <div
                    className={` rounded-full bg-fcGray6 flex items-center space-x-2 w-full p-2 ${
                      searchValue === ""
                        ? "border-0"
                        : "border border-fcPrimary"
                    }`}
                  >
                    <div className="">
                      <SearchIcon />
                    </div>
                    <div className="w-full">
                      <FieldInput
                        value={searchValue}
                        onChange={handleFilterSearch}
                        placeholder="Search fitcliq"
                        className="border-0 outline-none bg-transparent w-11/12"
                      />
                    </div>
                    <CloseIcon
                      onClick={() => setSearchValue("")}
                      className={`cursor-pointer ${
                        searchValue === "" ? "hidden" : "block"
                      }`}
                    />
                  </div>
                </div>

                <div
                  className={`bg-white rounded-b-xl w-full ${
                    showSearch ? "block" : "hidden"
                  }`}
                >
                  <DiscoverSubcathegory
                    currentSearchStep={currentSearchStep}
                    setCurrentSearchStep={setCurrentSearchStep}
                  />
                  {displaySearchCathegory(currentSearchStep)}
                </div>

                <div className={`${showSearch ? "hidden" : "block"}`}>
                  <div className="bg-white rounded-b-xl w-full mb-10">
                    <div className="p-4">
                      <h1 className="text-xl font-GilroySemiBold mb-4">
                        Who to follow
                      </h1>
                    <UserCardMax />
                    <UserCardMax />
                    <UserCardMax />
                      <div className="flex items-center justify-center">
                        <Link
                          to=""
                          className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
                        >
                          More people to follow
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl w-full p-4 mb-10">
                    <h1 className="text-xl font-GilroySemiBold mb-4">
                      Coaches recommended for you
                    </h1>
                    <div className="grid grid-cols-4 gap-5 mb-5">
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                      <CoachCardMin />
                    </div>
                    <div className="flex items-center justify-center">
                      <Link
                        to=""
                        className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
                      >
                        More people to follow
                      </Link>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl w-full p-4 mb-10">
                    <h1 className="text-xl font-GilroySemiBold ">
                      Cliqs to join
                    </h1>
                    <p className="text-base font-GilroyRegular my-2">
                      Check out these popular cliqs just for you
                    </p>
                    <CliqCardMax />
                    <CliqCardMax />
                    <CliqCardMax />
                    <CliqCardMax />
                  
                    <div className="flex items-center justify-center">
                      <Link
                        to=""
                        className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
                      >
                        More people to follow
                      </Link>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl w-full p-4">
                    <h1 className="text-base font-GilroySemiBold mb-2">
                      Gyms near you
                    </h1>
                    <p className="text-base font-GilroyRegular mb-4">
                      Check out these popular gyms near you
                    </p>
                    <GymCard />
                    <GymCard />
                    <div className="flex items-center justify-center">
                      <Link
                        to=""
                        className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
                      >
                        More people to follow
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-[18%] fixed ml-[51%]">
                <div className="bg-white p-4 mb-5 rounded-2xl">
                  <div className="flex items-center justify-between">
                    <p className="text-xl leading-10 tracking-wide font-GilroySemiBold">
                      Search filters
                    </p>
                    <ArrowAngleRight />
                  </div>
                  <div className="mb-10">
                    <h1 className="text-base font-GilroySemiBold">People</h1>
                    <div className="">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-GilroyMedium">From anyone</p>
                        <input
                          type="radio"
                          name="people"
                          className="accent-fcPrimary cursor-pointer h-5 w-5"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-GilroyMedium">
                          People you follow
                        </p>
                        <input
                          type="radio"
                          name="people"
                          className="accent-fcPrimary cursor-pointer h-5 w-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h1 className="text-base font-GilroySemiBold">Location</h1>
                    <div className="">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-GilroyMedium">Anywhere</p>
                        <input
                          type="radio"
                          className="accent-fcPrimary cursor-pointer h-5 w-5"
                          name="Location"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-GilroyMedium">Near you</p>
                        <input
                          type="radio"
                          className="accent-fcPrimary cursor-pointer h-5 w-5"
                          name="Location"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Discover;
