import React from "react";
import ChallengeNav from "../../../components/common/ChallengeNav";
import uploadcover from "../../../assets/uploadcover.svg";
import PrimaryButton from "../../../components/common/PrimaryButton";
import { NavLink } from "react-router-dom";

const NewChallenge = () => {
  
  const arr = [
    {
      description:
        "Give your challenge a title. Describe your challenge and set workouts and limits",
      title: "Make private",
      subtitle: "When you make a Cliq private, only you can see it.",
    },
  ];
  const arr2 = [
    {
      title: "Set time limits",
      subtitle: "This is the total time minutes for this challenge",
      img: "https://s.alicdn.com/@sc04/kf/H78781a3917ae4127bca74354f23243bc6.jpg_220x220.jpg",
    },
  ];

  // const [open, setOpen] = useState(false);

  return (
    <div className="">
      <ChallengeNav />
      <div className="items-center justify-center grid mx-auto py-7">
        <p className="py-9 text-[#2A2E43] font-GilroySemiBold text-center ">
          Create A Challenge
        </p>
        <div className="bg-fcGray4 h-40 w-full my-auto mx-auto pt-14 rounded-md">
          {/* <input
            onChange={handleselectedFile}
            id="file"
            type="file"
            accept=".jpg, .jpeg, .png"
            className="hidden"
            multiple
          /> */}
          <img
            src={uploadcover}
            alt="uploadcover"
            className="items-center mx-auto my-auto flex"
          />
          <p className="text-center">Upload Cover</p>
        </div>
        <div>
          {arr.map((e, i) => {
            return (
              <div key={i} className="">
                <p className="py-4 text-sm text-[#2A2E43CC]">{e.description}</p>
              </div>
            );
          })}
        </div>
        <div>
          <input
            name="name"
            type="name"
            placeholder="Name"
            className="form-control  w-full  h-14 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
          />
          <input
            name="text"
            type="message"
            placeholder="Description"
            className="form-control  w-full my-4  h-[150px] opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
          />
        </div>
        <div className="pt-4">
          {arr.map((e, i) => {
            return (
              <div key={i} className="">
                <p className=" text-sm text-[#2A2E43CC] font-GilroyMedium">
                  {e.title}
                </p>
                <div className="flex justify-between ">
                  <p className="text-[12px] text-fcGray5">{e.subtitle}</p>
                  <input
                    type="checkbox"
                    className="accent-orange text-[14px] rounded-lg"
                    value=""
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="py-4">
          {arr2.map((e, i) => {
            return (
              <div key={i} className="">
                <p className=" text-sm text-[#2A2E43CC] font-GilroyMedium">
                  {e.title}
                </p>
                <div className="flex justify-between ">
                  <p className="text-[12px] text-fcGray5">{e.subtitle}</p>
                  <input
                    type="checkbox"
                    className="accent-orange text-[14px] rounded-lg"
                    value=""
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <div className="hidden">
            <div className="flex justify-center gap-16 text-sm cursor-pointer">
              <p className="  hover:border-b-2 border-primary  ">
                Time Inverval
              </p>
              <p className="hover:border-b-2 border-primary ">Specific Dates</p>
            </div>
            <div>
              <input
                name="name"
                type="name"
                placeholder="Start Date"
                className="form-control mt-4  w-full  h-12 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
              />
              <input
                name="name"
                type="name"
                placeholder="Duration"
                className="form-control my-2  w-full  h-12 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
              />
            </div>
          </div>
        </div>
        <NavLink to="/dashboard/challenge/workout">
          <PrimaryButton>Next Step</PrimaryButton>
        </NavLink>
      </div>
    </div>
  );
};

export default NewChallenge;
