import React from 'react';
import image1 from "../../assets/image1.png";

import profileImage from "../../assets/img/profile-1.png";

function CliqCardMin() {
    return (
        <div className="cursor-pointer ">
            <div className=" h-48 w-48">
                <img src={image1} alt="" className="" />
            </div>
            <p className="text-xl font-GilroySemiBold my-2">
                Fitness Freak
            </p>
            <div className="flex items-center">
                <img
                    src={profileImage}
                    alt=""
                    className="w-8 h-8 "
                />
                <img
                    src={profileImage}
                    alt=""
                    className="w-8 h-8 -ml-4"
                />
                <img
                    src={profileImage}
                    alt=""
                    className="w-8 h-8 -ml-4"
                />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                    {" "}
                    <p>+2.4k</p>
                </div>
            </div>
        </div>
    );
}

export default CliqCardMin;