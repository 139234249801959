import React from "react";
import FieldInput from "../../../../components/common/FieldInput";
import Button from "../../../../components/common/Button";
import profileImage from '../../../../assets/profile-img.png';
import { Link } from "react-router-dom";
import AddUserIcon from '../../../../components/icons/AddUserIcon';
import SearchIcon from '../../../../components/icons/SearchIcon';
import { useState } from "react";
import EmptyPeopleIcon from '../../../../components/icons/EmptyPeopleIcon';

const InviteFriends = (setCurrentStep, formData, setFormData) => {
  const [friendsList, setFriendsList] = useState(['']);
  const [showFriendsList, setShowFriendsList] = useState(false);
  const [showInvited, setShowInvited] = useState(true);
  return (
    <>
      <main>
        <div>
          <div className="flex items-center justify-between px-32 mb-10">
            <h1
              onClick={() => setShowFriendsList(true)}
              className="py-2 font-GilroySemiBold text-base cursor-pointer"
            >
              Member[{friendsList.length}]
            </h1>
            <h1
              onClick={() => setShowFriendsList(false)}
              className="border-b-4 border-fcPrimary py-2 cursor-pointer"
            >
              Friends
            </h1>
          </div>
          <div className={`${showFriendsList ? "block" : "hidden"}`}>
            {friendsList.length <= 0 ? (
              <div className="py-20">
                <div className="flex items-center justify-center">
                  {" "}
                  <EmptyPeopleIcon className="w-32 h-32 mb-8" />
                </div>
                <div className="text-center">
                  <h1 className="text-3xl font-GilroyBold">
                    This List is empty
                  </h1>
                  <p className="text-base font-GilroyMedium">
                    People added to this List will show up here.
                  </p>
                </div>
              </div>
            ) : (
              <div className="p">
                <div className="flex items-center justify-between mb-8 grouo hover:bg-fcGray6 p-2 cursor-pointer">
                  <div className="flex items-center space-x-2">
                    <div>
                      <img src={profileImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <h1 className="font-GilroyMedium text-base">
                        Adeshina Adedapo
                      </h1>
                      <p className="text-xs font-GilroyRegular">
                        Lekki, Nigeria
                      </p>
                    </div>
                  </div>
                  <div>
                    <Link
                      to=""
                      className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                    >
                      <AddUserIcon /> <span>Invite</span>
                    </Link>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-8 grouo hover:bg-fcGray6 p-2 cursor-pointer">
                  <div className="flex items-center space-x-2">
                    <div>
                      <img src={profileImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <h1 className="font-GilroyMedium text-base">
                        Adeshina Adedapo
                      </h1>
                      <p className="text-xs font-GilroyRegular">
                        Lekki, Nigeria
                      </p>
                    </div>
                  </div>
                  <div>
                    <Link
                      to=""
                      className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                    >
                      <AddUserIcon /> <span>Invite</span>
                    </Link>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                  <div className="flex items-center space-x-2">
                    <div>
                      <img src={profileImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <h1 className="font-GilroyMedium text-base">
                        Adeshina Adedapo
                      </h1>
                      <p className="text-xs font-GilroyRegular">
                        Lekki, Nigeria
                      </p>
                    </div>
                  </div>
                  <div>
                    <Link
                      to=""
                      className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                    >
                      <AddUserIcon /> <span>Invite</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`${showFriendsList ? "hidden" : "block"}`}>
            <div className=" px-2 space-x-2 flex items-center border-0 rounded-full bg-fcGray6 mb-10 outline-none">
              <SearchIcon className="w-10" />
              <FieldInput
                type="text"
                name="name"
                id="name"
                className=" w-[900px] bg-transparent p-3 outline-none"
              />
            </div>
            <div
              onClick={() => setShowInvited(!showInvited)}
              className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer"
            >
              <div className="flex items-center space-x-2">
                <div>
                  <img src={profileImage} alt="" className="w-12 h-12" />
                </div>
                <div>
                  <h1 className="font-GilroyMedium text-base">
                    Adeshina Adedapo
                  </h1>
                  <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                </div>
              </div>
              <div>
                <Link
                  to=""
                  className={`flex items-center space-x-2 rounded-full  py-1 px-3 ${
                    showInvited ? "bg-fcGray4" : "bg-fcPrimary text-white"
                  }`}
                >
                  <AddUserIcon />
                  {showInvited ? <span>Invite</span> : <span>Invited</span>}
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
              <div className="flex items-center space-x-2">
                <div>
                  <img src={profileImage} alt="" className="w-12 h-12" />
                </div>
                <div>
                  <h1 className="font-GilroyMedium text-base">
                    Adeshina Adedapo
                  </h1>
                  <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                </div>
              </div>
              <div>
                <Link
                  to=""
                  className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                >
                  <AddUserIcon /> <span>Invite</span>
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between mb-8 grouo hover:bg-fcGray6 p-2 cursor-pointer">
              <div className="flex items-center space-x-2">
                <div>
                  <img src={profileImage} alt="" className="w-12 h-12" />
                </div>
                <div>
                  <h1 className="font-GilroyMedium text-base">
                    Adeshina Adedapo
                  </h1>
                  <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                </div>
              </div>
              <div>
                <Link
                  to=""
                  className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                >
                  <AddUserIcon /> <span>Invite</span>
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
              <div className="flex items-center space-x-2">
                <div>
                  <img src={profileImage} alt="" className="w-12 h-12" />
                </div>
                <div>
                  <h1 className="font-GilroyMedium text-base">
                    Adeshina Adedapo
                  </h1>
                  <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                </div>
              </div>
              <div>
                <Link
                  to=""
                  className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                >
                  <AddUserIcon /> <span>Invite</span>
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
              <div className="flex items-center space-x-2">
                <div>
                  <img src={profileImage} alt="" className="w-12 h-12" />
                </div>
                <div>
                  <h1 className="font-GilroyMedium text-base">
                    Adeshina Adedapo
                  </h1>
                  <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                </div>
              </div>
              <div>
                <Link
                  to=""
                  className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                >
                  <AddUserIcon /> <span>Invite</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Button
              type="submit"
              onClick={() => setCurrentStep(3)}
              className="border border-fcGray4 bg-transparent text-fcDark  w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
            >
              Back
            </Button>
            <Button
              type="submit"
              onClick={() => setCurrentStep(3)}
              className="bg-fcDark text-white w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
            >
              Done
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default InviteFriends;
