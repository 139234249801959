import React, { useState } from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";
import { Link } from "react-router-dom";
import like from "../../assets/like.svg"

// import "../../App.css";
// import CircularProgressBar from "./CircularProgressbar";

const Onboarding16 = () => {
  const [checkedValues, setValue] = useState([]);
  const handleChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    console.log(value, checked);
    if (checked) {
      setValue((pre) => [...pre, value]);
    } else
      setValue((pre) => {
        return [...pre.filter((onboarding) => onboarding !== value)];
      });
  };
  console.log(checkedValues);
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[90%]"></div>
            </div>
            <a href="/onboard/step15">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">
              Creating your personalized
              <br /> program
            </p>
          </div>
          {/* <CircularProgressBar className="mx-auto items-center justify-center" />  */}
          <div className="py-4 items-center  justify-center  mx-auto grid w-[460px]">
            <div className="circle-wrap">
            <div className="inside-circle bg-white  "> 25% </div>
              <div className="circle">
                <div className="mask half">
                  <div className="fill bg-primary"></div>
                </div>
              </div>
            </div>
              <div className="items-center justify-center mx-auto ">
                <img src={like} alt="like " className="w-[100px]" />
                <p className="bg-[#00A50626] text-center rounded-full font-bold mt-2 ">All set!</p>
              </div>
            <div className=" items-center  justify-center  mx-auto mt-7 space-y-5">
              <label className="text-sm">
                <input
                  type="checkbox"
                  className="rounded-none accent-orange"
                  value="Analyzing your profile"
                  onChange={handleChange}
                />{" "}
                Analyzing your profile
              </label>
              <br />
              <label className="text-sm">
                <input
                  type="checkbox"
                  className="accent-orange rounded-lg"
                  value=" Estimating your metabolic age"
                  onChange={handleChange}
                />{" "}
                Estimating your metabolic age
              </label>

              <br />
              <label className="text-sm">
                <input
                  type="checkbox"
                  className="accent-orange text-[14px] rounded-lg"
                  value=" Adapting the plan to your busy schedule"
                  onChange={handleChange}
                />{" "}
                Adapting the plan to your busy schedule
              </label>

              <br />
              <label className="text-sm">
                <input
                  type="checkbox"
                  className="accent-orange rounded-lg"
                  value=" Selecting suitable workouts and recipes"
                  onChange={handleChange}
                />{" "}
                Selecting suitable workouts and recipes
              </label>
            </div>
          </div>
          <a href="/onboard/step18">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding16;
