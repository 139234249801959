import React, { useState } from "react";
import BoldAngleArrowRight from "../../../components/icons/BoldAngleArrowRight";
import SmallAddIcon from "../../../components/icons/SmallAddIcon";
import profileImageOne from "../../../assets/img/profile-1.png";
import profileImageTwo from "../../../assets/img/profile-2.png";
import profileImageThree from "../../../assets/img/profile-3.png";
import whiteUserIcon from "../../../assets/img/whiteuserIcon.png";
import NoChallenges from "./NoChallenges";
import MyChallengeFilled from "./MyChallengeFilled";
import BrowseChallengeCard from "./BrowseChallengeCard";

const Challenges = ({ className }) => {
  const [showMyChallenge, setShowMyChallenge] = useState(false);
  return (
    <div className={className}>
      <div className="bg-white rounded-xl p-4 mb-10">
        <div className="flex items-center justify-between mb-5">
          <h1 className="text-xl font-GilroySemiBold text-center">
            My Challenges
          </h1>
          <div className=" flex items-center space-x-3">
            <SmallAddIcon
              onClick={() => setShowMyChallenge(true)}
              className="cursor-pointer"
            />
            <BoldAngleArrowRight className="cursor-pointer" />
          </div>
        </div>
        <div className="">
          <NoChallenges className={`${showMyChallenge ? "hidden" : "block"}`} />
          <MyChallengeFilled
            className={`${showMyChallenge ? "block" : "hidden"}`}
          />
        </div>
      </div>
      <div className="bg-white rounded-xl p-4 mb-10">
        <div className="flex items-center justify-between mb-5">
          <h1 className="text-xl font-GilroySemiBold text-center">
            Browse Challenges
          </h1>
          <div className="">
            <BoldAngleArrowRight className="cursor-pointer" />
          </div>
        </div>
        <div className="">
          <BrowseChallengeCard />
          <BrowseChallengeCard />
          <BrowseChallengeCard />
          {/* <div id="emailbg-1" className=" h-[450px] p-6 rounded-xl mb-5">
            <div class="mt-80 ">
              <p class="text-[12px] text-white font-GilroyMedium mb-1">
                10 days + 8 workouts
              </p>
              <p class="text-[16px] text-white font-GilroySemiBold mb-1">
                6-pack ABS with Alper
              </p>
              <div class="flex items-center justify-between">
                <div class="flex">
                  <img src={profileImageOne} alt="" class="" />
                  <img src={profileImageTwo} alt="" class="" />
                  <img src={profileImageThree} alt="" class="" />
                </div>
                <div class="flex items-center justify-between text-white bg-[#CC6841] px-3 py-1 rounded-full space-x-2">
                  <a href="/">
                    <img src={whiteUserIcon} alt="" />
                  </a>
                  <span>Join</span>
                </div>
              </div>
            </div>
          </div>
          <div id="emailbg-2" className=" h-[450px] p-6 rounded-xl mb-5">
            <div class="mt-80">
              <p class="text-[12px] text-white font-GilroyMedium mb-1">
                10 days + 8 workouts
              </p>
              <p class="text-[16px] text-white font-GilroySemiBold mb-1">
                6-pack ABS with Alper
              </p>
              <div class="flex items-center justify-between">
                <div class="flex">
                  <img src={profileImageOne} alt="" class="" />
                  <img src={profileImageTwo} alt="" class="" />
                  <img src={profileImageThree} alt="" class="" />
                </div>
                <div class="flex items-center justify-between text-white bg-[#CC6841] px-3 py-1 rounded-full space-x-2">
                  <a href="/">
                    <img src={whiteUserIcon} alt="" />
                  </a>
                  <span>Join</span>
                </div>
              </div>
            </div>
          </div>
          <div id="emailbg-3" className=" h-[450px] p-6 rounded-xl mb-5">
            <div class="mt-80">
              <p class="text-[12px] text-white font-GilroyMedium mb-1">
                10 days + 8 workouts
              </p>
              <p class="text-[16px] text-white font-GilroySemiBold mb-1">
                6-pack ABS with Alper
              </p>
              <div class="flex items-center justify-between">
                <div class="flex">
                  <img src={profileImageOne} alt="" class="" />
                  <img src={profileImageTwo} alt="" class="" />
                  <img src={profileImageThree} alt="" class="" />
                </div>
                <div class="flex items-center justify-between text-white bg-[#CC6841] px-3 py-1 rounded-full space-x-2">
                  <a href="/">
                    <img src={whiteUserIcon} alt="" />
                  </a>
                  <span>Join</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Challenges;
