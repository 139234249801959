import React from "react";

const TwitterIcon = ({className}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        className={className}
      >
        <g id="icons8-twitter" transform="translate(-6 -6)">
          <path
            id="Path_2873"
            data-name="Path 2873"
            d="M38,33.556A4.444,4.444,0,0,1,33.556,38H10.444A4.444,4.444,0,0,1,6,33.556V10.444A4.444,4.444,0,0,1,10.444,6H33.556A4.444,4.444,0,0,1,38,10.444Z"
            fill="#03a9f4"
          />
          <path
            id="icons8-twitter-96"
            d="M30,11.286a9.985,9.985,0,0,1-2.831.775,4.919,4.919,0,0,0,2.167-2.705A9.885,9.885,0,0,1,26.2,10.539a4.911,4.911,0,0,0-8.385,4.454,14.032,14.032,0,0,1-10.148-5.1A4.82,4.82,0,0,0,7,12.348a4.869,4.869,0,0,0,2.19,4.064A5.013,5.013,0,0,1,6.962,15.8v.057a4.895,4.895,0,0,0,3.948,4.787,4.9,4.9,0,0,1-1.294.175,5.232,5.232,0,0,1-.928-.09,4.933,4.933,0,0,0,4.6,3.39,9.949,9.949,0,0,1-6.115,2.089A9.74,9.74,0,0,1,6,26.142a14.071,14.071,0,0,0,7.549,2.19,13.85,13.85,0,0,0,14.01-13.888c0-.211-.007-.422-.017-.63A9.687,9.687,0,0,0,30,11.286"
            transform="translate(4.003 3.335)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};

export default TwitterIcon;
