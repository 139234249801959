import React from 'react';

const ProfileCircle = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_profile-circle"
          data-name="vuesax/bold/profile-circle"
          transform="translate(-492 -252)"
        >
          <g id="profile-circle" transform="translate(492 252)">
            <path
              id="Vector"
              d="M21.667,10.833A10.833,10.833,0,1,0,3.5,18.785c0,.011,0,.011-.011.022.108.108.238.195.347.292a2.27,2.27,0,0,0,.184.152c.2.162.412.314.618.466.076.054.141.1.217.152a6.449,6.449,0,0,0,.65.39c.076.043.162.1.238.141a6.6,6.6,0,0,0,.682.325,2.771,2.771,0,0,0,.26.119q.357.146.715.26a2.438,2.438,0,0,0,.26.087c.26.076.52.141.78.206a1.537,1.537,0,0,0,.238.054,8.573,8.573,0,0,0,.921.141.544.544,0,0,1,.13.022c.368.033.737.054,1.105.054s.737-.022,1.094-.054a.544.544,0,0,0,.13-.022,8.573,8.573,0,0,0,.921-.141c.076-.011.152-.043.238-.054.26-.065.531-.119.78-.206a2.437,2.437,0,0,1,.26-.087c.238-.087.487-.163.715-.26a2.771,2.771,0,0,0,.26-.119q.341-.146.683-.325c.087-.043.163-.1.238-.141.217-.13.433-.249.65-.39a2.629,2.629,0,0,0,.217-.152c.217-.152.423-.3.618-.466a2.269,2.269,0,0,1,.184-.152c.119-.1.238-.195.347-.292,0-.011,0-.011-.011-.022A10.775,10.775,0,0,0,21.667,10.833Zm-5.482,5.384a10.423,10.423,0,0,0-10.7,0A5.231,5.231,0,0,0,4.29,17.3a9.208,9.208,0,1,1,13.087,0A5.013,5.013,0,0,0,16.185,16.218Z"
              transform="translate(2.167 2.167)"
              fill="#ff603e"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M4.063,0a4.057,4.057,0,0,0-.054,8.114h.2A4.058,4.058,0,0,0,4.063,0Z"
              transform="translate(8.938 7.507)"
              fill="#ff603e"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              transform="translate(26 26) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default ProfileCircle;
