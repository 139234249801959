import React from 'react';
import SideBar from '../sidebar/SideBar';
import { BiShareAlt } from 'react-icons/bi';
import LongArrowLeftIcon from '../../../components/icons/LongArrowLeftIcon';
import HorrizontalDottedIcon from '../../../components/icons/HorrizontalDottedIcon';
import cliqfreakheroImage from '../../../assets/home-img-1.png';
import { Link } from 'react-router-dom';
import AddUserIcon from '../../../components/icons/AddUserIcon';
import profileImage from '../../../assets/profile-img.png';
import SmallGlobalIcon from '../../../components/icons/SmallGlobalIcon';
import { useState } from 'react';
import onebar from '../../../assets/onebar.svg';
import hotbody from '../../../assets/hotbody.png';
import Abburner from '../../../assets/Ab-burner.png';
import fullbody from '../../../assets/fullbody.png';
import Quickcore from '../../../assets/Quick-core.png';
import Button from '../../../components/common/Button';
import PlayCircleIcon from '../../../components/icons/PlayCircleIcon';
import WorkOutWithBg from '../../../components/icons/WorkOutWithBg';
import DurationIconWithBg from '../../../components/icons/DurationIconWithBg';
import BoldAngleArrowRight from '../../../components/icons/BoldAngleArrowRight';
import SmallHeartIcon from '../../../components/icons/SmallHeartIcon';
import FlowerIcon from '../../../components/icons/FlowerIcon';
import LikeIcon from '../../../components/icons/LikeIcon';
import JoinChallengeProgressCard from './JoinChallengeProgressCard';
import GalleryAddIcon from '../../../components/icons/GalleryAddIcon';
import ChallengeParticipants from './ChallengeParticipants';
import LogoutIcon from '../../../components/icons/LogoutIcon';

const JoinChallenge = () => {
  const [showCreatePostPopUp, setCreatePostPopUp] = useState(false);
  const myArr = [
    {
      title: 'Ab Burner',
      time: '30 mins . intensity',
      img: './assets/fullbody.png',
    },
    {
      title: 'Quick Core',
      time: '10 mins . intensity',
    },
    {
      title: 'Hot Body, Arms, Abs and strong core',
      time: '10 mins . intensity',
    },
    {
      title: 'Full Body Build',
      time: '7 mins . intensity',
    },
  ];
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85%] ml-[15%] bg-fcGray6 px-60 py-20">
          <div>
            <nav className="flex bg-fcGray6 items-center justify-between sticky top-0 mb-10 py-5  z-50">
              <div className="flex items-center space-x-3">
                <div className="bg-white p-2 rounded-full">
                  <LongArrowLeftIcon className=" cursor-pointer" />
                </div>
                <h1 className="text-2xl font-GilroySemiBold">
                  30 Days Challenge
                </h1>
              </div>
              <div className="flex items-center space-x-3">
                <div className="bg-white p-2 rounded-full">
                  {' '}
                  <BiShareAlt className="cursor-pointer" />
                </div>
                <div className="bg-white p-2 rounded-full">
                  {' '}
                  <HorrizontalDottedIcon className="cursor-pointer" />
                </div>
              </div>
            </nav>
            <div className="bg-white rounded-b-xl">
              <div className="bg-fcGray6 mb-8">
                <img
                  src={cliqfreakheroImage}
                  alt=""
                  className="w-full rounded-tl-xl rounded-tr-xl h-96 -mt-5"
                />
              </div>
              <div className="px-5 py-5 mb-10 ">
                <h1 className="text-2xl font-GilroySemiBold mb-8">
                  30 Days Challenge
                </h1>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <img src={profileImage} alt="" />
                      <img src={profileImage} alt="" className="-ml-6" />
                      <img src={profileImage} alt="" className="-ml-6" />
                      <img src={profileImage} alt="" className="-ml-6" />
                    </div>
                    <div className="flex items-center -ml-6">
                      <p className="text-white bg-fcPrimary p-2 font-GilroyMedium rounded-full text-base">
                        +6.3k
                      </p>
                    </div>
                    <p className="text-base font-GilroyMedium ml-3">
                      63,567 members
                    </p>
                  </div>
                  <div className="flex justify-between space-x-5">
                    <Link
                      to=""
                      className="flex items-center  py-2 px-3 text-sm font-GilroyMedium rounded-full space-x-3 bg-white border-fcGray6 border-2"
                    >
                      <LogoutIcon className="h-5 w-5" /> <span>Leave</span>
                    </Link>
                    <Link
                      to=""
                      className="flex items-center text-white  py-2 px-3 text-sm font-GilroyMedium rounded-full space-x-3 bg-fcPrimary"
                    >
                      <AddUserIcon /> <span>Invite</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-9">
              <div className="w-4/6">
                <div className="bg-white  items-center rounded-xl p-4 mb-5 ">
                  <div className="flex items-center mb-3 justify-between">
                    <span>
                      <p>Participants</p>
                    </span>
                    <BoldAngleArrowRight />
                  </div>
                  <div className="flex gap-5 overflow-x-scroll scroll-smooth whitespace-nowrap no-scrollbar">
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                    <ChallengeParticipants />
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl mb-10">
                  <section className="">
                    <p className=" text-xl font-GilroySemiBold">
                      Your Workouts
                    </p>
                    <div className="flex relative">
                      <div className="flex flex-col items-center justify-center absolute top-14">
                        <div className="w-5 h-5 ml-2 text-fcGray4 bg-white rounded-full border-2"></div>
                        <div className="vertical border-l-2 text-fcGray4 h-[100px]  ml-[7px] mt-4"></div>
                      </div>
                      <div className="flex justify-between items-center ml-10 w-full">
                        <div className="flex items-center  gap-2 my-6">
                          <img src={Abburner} alt="ab" className="w-[100px]" />
                          <div>
                            <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                              Ab Burner
                            </p>
                            <div className="flex gap-2 my-1">
                              <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                30 mins . intensity
                              </p>
                              <img
                                src={onebar}
                                alt="onebar"
                                className="w-[13px]"
                              />
                            </div>
                            <div className="flex gap-2">
                              <div className="flex items-center gap-2">
                                <SmallHeartIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  4
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                <FlowerIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  224
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <Button className="flex items-center bg-fcRed2 text-white rounded-full py-1 px-4">
                            <PlayCircleIcon className="mr-1" />
                            Start
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="flex flex-col items-center justify-center absolute top-14">
                        <div className="w-5 h-5 ml-2 text-fcGray4 bg-white rounded-full border-2"></div>
                        <div className="vertical border-l-2 text-fcGray4 h-[100px]  ml-[7px] mt-4"></div>
                      </div>
                      <div className="flex justify-between items-center ml-10 w-full">
                        <div className="flex items-center gap-2 my-6">
                          <img src={fullbody} alt="ab" className="w-[100px]" />
                          <div>
                            <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                              Ab Burner
                            </p>
                            <div className="flex gap-2 my-1">
                              <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                30 mins . intensity
                              </p>
                              <img
                                src={onebar}
                                alt="onebar"
                                className="w-[13px]"
                              />
                            </div>
                            <div className="flex gap-2">
                              <div className="flex items-center gap-2">
                                <SmallHeartIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  4
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                <FlowerIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  224
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <Button className="flex items-center bg-fcRed2 text-white rounded-full py-1 px-4">
                            <PlayCircleIcon className="mr-1" />
                            Start
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="flex flex-col items-center justify-center absolute top-14">
                        <div className="w-5 h-5 ml-2 text-fcGray4 bg-white rounded-full border-2"></div>
                        <div className="vertical border-l-2 text-fcGray4 h-[100px]  ml-[7px] mt-4"></div>
                      </div>
                      <div className="flex justify-between items-center ml-10 w-full">
                        <div className="flex items-center  gap-2 my-6">
                          <img src={Quickcore} alt="ab" className="w-[100px]" />
                          <div>
                            <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                              Ab Burner
                            </p>
                            <div className="flex gap-2 my-1">
                              <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                30 mins . intensity
                              </p>
                              <img
                                src={onebar}
                                alt="onebar"
                                className="w-[13px]"
                              />
                            </div>
                            <div className="flex gap-2">
                              <div className="flex items-center gap-2">
                                <SmallHeartIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  4
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                <FlowerIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  224
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <Button className="flex items-center bg-fcRed2 text-white rounded-full py-1 px-4">
                            <PlayCircleIcon className="mr-1" />
                            Start
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="flex flex-col items-center justify-center absolute top-14">
                        <div className="w-5 h-5 ml-2 text-fcGray4 bg-white rounded-full border-2"></div>
                      </div>
                      <div className="flex justify-between items-center ml-10 w-full">
                        <div className="flex items-center  gap-2 my-6">
                          <img src={hotbody} alt="ab" className="w-[100px]" />
                          <div>
                            <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                              Ab Burner
                            </p>
                            <div className="flex gap-2 my-1">
                              <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                30 mins . intensity
                              </p>
                              <img
                                src={onebar}
                                alt="onebar"
                                className="w-[13px]"
                              />
                            </div>
                            <div className="flex gap-2">
                              <div className="flex items-center gap-2">
                                <SmallHeartIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  4
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                <FlowerIcon />
                                <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                                  224
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <Button className="flex items-center bg-fcRed2 text-white rounded-full py-1 px-4">
                            <PlayCircleIcon className="mr-1" />
                            Start
                          </Button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="bg-white p-4 rounded-xl">
                  <p className=" text-xl font-GilroySemiBold mb-2">Progress</p>
                  <div className="flex gap-5 overflow-x-scroll scroll-smooth whitespace-nowrap no-scrollbar">
                    <div className="w-40 mt-2 h-36 p-4 rounded-xl flex items-center justify-center bg-fcGray7 mb-10">
                      <label
                        htmlFor="file"
                        className="cursor-pointer text-center text-base font-GilroySemiBold"
                      >
                        <GalleryAddIcon className="h-6 w-6 ml-8" /> <br />
                        Add Image
                        <input
                          id="file"
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          className="hidden"
                        />
                      </label>
                    </div>
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                    <JoinChallengeProgressCard />
                  </div>
                </div>
              </div>
              <div className="w-1/3">
                <div className="bg-white p-4 rounded-xl mb-8">
                  <h1 className="text-xl font-GilroySemiBold mb-4">About</h1>
                  <p className="text-base fobt-GilroyRegular mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Labore vero laborum quia ipsam sed blanditiis aspernatur
                    quasi ea hic? Sapiente.
                  </p>
                  <Link to="" className="underline text-base font-GilroyMedium">
                    See More
                  </Link>
                  <div className="flex space-x-4 mt-5">
                    <SmallGlobalIcon />
                    <div>
                      <p className="text-base font-GilroyMedium">Public</p>
                      <p className="text-base font-GilroyRegular">
                        Anyone can see who’s in the cliq and what they post.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl mb-8">
                  <h1 className="text-xl font-GilroySemiBold mb-4">Stats</h1>
                  <div className="flex items-center space-x-4 mb-5">
                    <DurationIconWithBg className="h-12 w-12" />
                    <div>
                      <p className="text-xs font-GilroyRegular">Duration</p>
                      <p className="text-3xl font-GilroySemiBold">4 weeks</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <WorkOutWithBg className="h-12 w-12" />
                    <div>
                      <p className="text-xs font-GilroyRegular">Workout</p>
                      <p className="text-3xl font-GilroySemiBold">10</p>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl mb-8">
                  <div className="flex items-center justify-between">
                    <Link
                      to={'../leaderboard'}
                      className="text-xl font-GilroySemiBold mb-4"
                    >
                      Leaderboard
                    </Link>
                    <BoldAngleArrowRight />
                  </div>
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center space-x-3">
                      <p>1</p>
                      <p className=" font-GilroyMedium px-3 py-1 text-2xl rounded-full bg-fcyellow2">
                        A
                      </p>
                      <p className="text-base font-GilroyMedium">Adedapo</p>
                    </div>
                    <p className="text-base font-GilroySemiBold">835</p>
                  </div>
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center space-x-3">
                      <p>2</p>
                      <p className=" font-GilroyMedium px-3 py-1 text-2xl rounded-full bg-fcPrimary">
                        M
                      </p>
                      <p className="text-base font-GilroyMedium">Adedapo</p>
                    </div>
                    <p className="text-base font-GilroySemiBold">835</p>
                  </div>
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center space-x-3">
                      <p>3</p>
                      <p className=" font-GilroyMedium px-3 py-1 text-2xl rounded-full bg-fcyellow2">
                        B
                      </p>
                      <p className="text-base font-GilroyMedium">Adedapo</p>
                    </div>
                    <p className="text-base font-GilroySemiBold">835</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default JoinChallenge;
