import React from "react";
import Header from "../../../components/common/Header";
import ParagraphText from "../../../components/common/ParagraphText";


const PrivacyPolicy = () => {
  const content = [
    {
      description: "1. Categories of personal data we collect",
    },
    {
      description: "2. For what purposes we process your personal data",
    },
    {
      description:
        "3. Under what legal bases we process your personal data (Applies only to EEA-based users)",
    },
    {
      description: "4. With whom we share your personal data",
    },
    {
      description: "5. How you can exercise your privacy rights",
    },
    {
      description: "6. Age limitation",
    },
    {
      description: "7. International data transfers",
    },
    {
      description: "8. Changes to this privacy policy",
    },
    {
      description: "9. Data retention",
    },
    {
      description: "10. How “do not track” requests are handled",
    },
    {
      description: "11. Personal data controller",
    },
    {
      description: "12. Contact us",
    },
  ];
  return (
    <div>
      <Header />
      <section className="mx-auto w-[1040px]">
        <div>
          <p className="text-8xl font-GilroyBold  text-fcNavy pt-24">
            Privacy policy
          </p>
          <p className="font-GilroySemiBold text-2xl text-fcNavy pt-8">
            Effective date: March 14, 2023
          </p>
        </div>
        <div className="py-24">
          <ParagraphText>
            In order to use some of our apps or websites, we may ask you to
            enter your name, email, current weight and height, target weight,
            fitness level, areas for improvement, and food preferences, and ask
            other onboarding questions. In some of our apps you are able to skip
            some of the onboarding questions by tapping on Skip or similar
            indication. We also automatically collect from your device: language
            settings, IP address, time zone, type and model of a device, device
            settings, operating system, Internet service provider, mobile
            carrier, hardware ID, Facebook ID, and some other unique identifiers
            (such as IDFA and AAID). We need this data to provide our services,
            analyze how our customers use the app, to serve ads.
          </ParagraphText>
          <ParagraphText>
            For improving the app and attracting users, we use third party
            solutions. As a result, we may process data using solutions
            developed by Amplitude, Facebook, Firebase, Google, Apple,
            Appsflyer, Crashlytics. Therefore, some of the data is stored and
            processed on servers of such third parties. This enables us to (1)
            analyze different interactions (how often users make subscriptions,
            the average weight and height of our users, how many users chose a
            particular area for improvement); (2) serve ads (and are able to
            show them only to a particular group of users, for example, to
            subscribers). Consequently, we, in particular, better understand in
            what of our features and content you see the most value and are able
            to focus on them to enhance your experience and increase the quality
            of our products.
          </ParagraphText>
          <ParagraphText>
            Please read our Privacy Policy below to know more about what we do
            with data (<span className="text-primary">Section 2</span>), what
            data privacy rights are available to you (Section 5) and who will be
            the data controller (
            <span className="text-primary">Section 12</span>). If any questions
            will remain unanswered or you would like to exercise your privacy
            rights, please contact us at
            <span className="text-primary">support@fitcliq.world.</span>
          </ParagraphText>
          <div>
            <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
              Privacy Policy
            </p>
            <ParagraphText>
              This Privacy Policy explains what personal data is collected when
              you use the our mobile applications, websites and the services
              provided through them (together “App” or “Service”), how such
              personal data will be processed.
            </ParagraphText>
            <ParagraphText>
              <span className="text-fcDark font-GilroySemiBold">
                By using the service, you promise us that (i) you have read,
                understand and agree to this privacy policy, and (ii) you are
                over 16 years of age (or have had your parent or guardian read
                and agree to this privacy policy for you).
              </span>{' '}
              If you do not agree, or are unable to make this promise, you must
              not use the Service. In such case, you must (a) delete your
              account and contact us and request deletion of your data; (b)
              cancel any subscriptions using the functionality provided by Apple
              (if you are using iOS) or Google (if you are using Android), any
              other app stores that may be available from time to time, or by us
              if you purchased it directly from our websites; and (c) delete the
              App from your devices.
            </ParagraphText>
            <ParagraphText>
              Any translation from English version is provided for your
              convenience only. In the event of any difference in meaning or
              interpretation between the English language version of this
              Privacy Policy available at{' '}
              <span className="text-primary">
                https://fitcliq.world/privacy-policy
              </span>
              , and any translation, the English language version will prevail.
              The original English text shall be the sole legally binding
              version.
            </ParagraphText>
            <ParagraphText>
              <span className="font-GilroySemiBold">“GDPR”</span> means the
              General Data Protection Regulation (EU) 2016/679 of the European
              Parliament and of the Council of 27 April 2016 on the protection
              of natural persons with regard to the processing of personal data
              and on the free movement of such data.
            </ParagraphText>
            <ParagraphText>
              <span className="font-GilroySemiBold">“EEA”</span>
              includes all current member states to the European Union and the
              European Economic Area.
            </ParagraphText>
            <ParagraphText>
              <span className="font-GilroySemiBold">“Process”</span>, in respect
              of personal data, includes to collect, store, and disclose to
              others.
            </ParagraphText>
          </div>
          <div>
            <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
              Content
            </p>
            {content.map((e, i) => {
              return (
                <div
                  key={i}
                  className=" my-4 font-GilroyMedium text-lg underline text-primary"
                >
                  <p>{e.description}</p>
                </div>
              );
            })}
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                1. Categories of personal data we collect
              </p>
              <ParagraphText>
                We collect data you give us voluntarily (for example, when you
                choose your areas for improvement or send us an email). We also
                may receive data about you from third parties (for example, when
                you sign in via Apple). Finally, we collect data automatically
                (for example, your IP address).
              </ParagraphText>
              <div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.1. Data you give us
                  </p>
                  <ParagraphText>
                    You provide us information about yourself when you register
                    for and/or use the Service. For example: name, age, gender,
                    data on physical characteristics (including height, weight,
                    areas for improvement), fitness level, food preferences
                    (including diet preferences; preferred number of meals,
                    foods you do not like), meditation preferences, food and
                    exercises you log, email address.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.3. Data we collect automatically:
                  </p>
                  <div className="ml-10 my-4">
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        a. Data about how you found us.
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We collect data about your referring app or URL (that
                        is, the app or place on the Web where you were when you
                        tapped on our ad).
                      </p>
                    </div>
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        b. Device and Location data
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We collect data from your mobile device. Examples of
                        such data include: language settings, IP address, time
                        zone, type and model of a device, device settings,
                        operating system, Internet service provider, mobile
                        carrier, hardware ID, and Facebook ID.
                      </p>
                    </div>
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        c. Usage data
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We record how you interact with our Service. For
                        example, we log your taps on certain areas of the
                        interface, the features, and content you interact with,
                        workouts you do, the time and duration of your workouts,
                        how often you use the App, how long you are in the app,
                        your training program progress, and your subscription
                        orders. We also record the ads in our App with which you
                        interact (and the Internet links to which those adds
                        lead).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                2. For what purposes we process your personal data
              </p>
              <ParagraphText>We process your personal data:</ParagraphText>
              <div>
                <div className=" my-6">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.1. To provide our Service
                  </p>
                  <ParagraphText>
                    This includes enabling you to use the Service in a seamless
                    manner and preventing or addressing Service errors or
                    technical issues.
                  </ParagraphText>
                  <ParagraphText>
                    To host personal data and enable our App to operate and be
                    distributed we use Amazon Web Services, which is a hosting
                    and backend service provided by Amazon.
                  </ParagraphText>
                  <ParagraphText>
                    To monitor infrastructure and the App’s performance, we use
                    Crashlytics, which is a monitoring service provided by
                    Google. Here’s its{' '}
                    <span className="text-primary">Data Collection Policy</span>
                    .
                  </ParagraphText>
                  <ParagraphText>
                    We use Firebase Performance Monitoring and Firebase Crash
                    Reporting, which are monitoring services provided by Google.
                    To learn more,{' '}
                    <span className="text-primary">
                      please visit Google’s Privacy policy{' '}
                    </span>
                    .{' '}
                    <span className="text-primary">
                      {' '}
                      and Privacy and Security in Firebase.{' '}
                    </span>
                    .
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.2. To customize your experience
                  </p>
                  <ParagraphText>
                    We process your personal data, in particular, such as your
                    characteristics and preferences, to adjust the content of
                    the Service and provide content tailored to your personal
                    preferences. As a result of such processing, you get access,
                    for example, to a nutrition plan containing only vegetarian
                    products or a customized meal plan offer (in the in-App
                    chat) tailored for achieving your goals.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.3. To manage your account and provide you with customer
                    support
                  </p>
                  <ParagraphText>
                    We process your personal data to respond to your requests
                    for technical support, Service information or to any other
                    communication you initiate. This includes accessing your
                    account to address technical support requests. For this
                    purpose, we may send you, for example, notifications or
                    emails about the performance of our Service, security,
                    payment transactions, notices regarding our PrivacyPolicy
                    and Conditions of Use or this Privacy Policy.
                  </ParagraphText>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                3. With whom we share your personal data
              </p>
              <ParagraphText>
                We share information with third parties that help us operate,
                provide, improve, integrate, customize, support, and market our
                Service. We may share some sets of personal data, in particular,
                for purposes indicated in Section 2 of this Privacy Policy. The
                types of third parties we share information with include, in
                particular:
              </ParagraphText>
              <div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    3.1. Service providers
                  </p>
                  <ParagraphText>
                    We share personal data with third parties that we hire to
                    provide services or perform business functions on our
                    behalf, based on our instructions. We share your personal
                    information with the following types of service providers:
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <div className="ml-10 my-6">
                    <div>
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          cloud storage providers (Amazon)
                        </p>
                      </div>

                      <div className="flex items-center gap-2 py-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          data analytics providers (Facebook, Google, Appsflyer,
                          Firebase, Crashlytics, Amplitude, Fabric)
                        </p>
                      </div>

                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          marketing partners (in particular, social media
                          networks, marketing agencies, email delivery services,
                          Facebook, Google, Snapchat)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    3.2. Apple Health Kit (and Apple Motion & Fitness API) or
                    Google Fit (together the “Health App”)
                  </p>
                  <ParagraphText>
                    Provided you give express permission on your device, we may
                    receive (read) or/and write (share) data about your activity
                    with/from Health App. In some of our Apps you may decide to
                    allow us to read (receive) the following information from
                    the Health App: the number of steps traveled and weight.
                  </ParagraphText>
                  <ParagraphText>
                    If you grant access for us to write data to (share with)
                    Health App, we will transfer to Health App information on
                    your workouts, weight, dietary energy (calorie intake
                    according to information on how you follow your meal plan).
                    Before you decide to share your data with the Health App, we
                    encourage you to review their privacy policy, as your data
                    will be subject to those policies. For more information on
                    the Apple HealthKit, please see
                    <span className="text-primary">
                      http://www.apple.com/ios/health/
                    </span>
                    , and on the Google Fit, please see{' '}
                    <span className="text-primary">
                      https://www.google.com/fit/
                    </span>
                    .
                  </ParagraphText>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                13. Contact us
              </p>
              <ParagraphText>
                You may contact us at any time for details regarding this
                Privacy Policy and its previous versions. For any questions
                concerning your account or your personal data please contact us
                at <span className="text-primary">support@fitcliq.com.</span>
              </ParagraphText>
            </div>
          </div>
          <p className="font-GilroySemiBold text-2xl text-fcNavy pt-4">
            Effective as of: 17 December 2020
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
