import React from 'react';

const FriendsIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_profile-2user"
          data-name="vuesax/bold/profile-2user"
          transform="translate(-172 -252)"
        >
          <g id="profile-2user" transform="translate(172 252)">
            <path
              id="Vector"
              d="M5.146,0a5.141,5.141,0,0,0-.13,10.281.874.874,0,0,1,.238,0H5.33A5.142,5.142,0,0,0,5.146,0Z"
              transform="translate(4.604 2.167)"
              fill="#ff603e"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M13.13,1.511a10.756,10.756,0,0,0-11,0A4.276,4.276,0,0,0,0,5.01,4.24,4.24,0,0,0,2.123,8.488a10.009,10.009,0,0,0,5.5,1.528,10.009,10.009,0,0,0,5.5-1.528,4.274,4.274,0,0,0,2.123-3.5A4.266,4.266,0,0,0,13.13,1.511Z"
              transform="translate(2.123 13.818)"
              fill="#ff603e"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M6.37,3.557A3.877,3.877,0,0,1,2.979,7.75H2.925a.507.507,0,0,0-.184.022A3.887,3.887,0,0,1,0,6.872,4.957,4.957,0,0,0,1.625,2.755,5.028,5.028,0,0,0,.791.394,3.89,3.89,0,0,1,6.37,3.557Z"
              transform="translate(15.286 4.395)"
              fill="#ff603e"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M8.667,4.431A3.3,3.3,0,0,1,6.782,7.01a7.647,7.647,0,0,1-3.8.845A3.867,3.867,0,0,0,4.3,5.341,4.268,4.268,0,0,0,2.492,1.68,8.943,8.943,0,0,0,0,.315,8.467,8.467,0,0,1,7.258,1.582,3.292,3.292,0,0,1,8.667,4.431Z"
              transform="translate(15.156 13.541)"
              fill="#ff603e"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              transform="translate(26 26) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default FriendsIcon;
