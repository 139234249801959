import React from "react";

const WeightMeterIcon = ({ className }) => {
  return (
    <>
      <svg
        id="Group_52556"
        data-name="Group 52556"
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <path
          id="Path_39351"
          data-name="Path 39351"
          d="M0,0H26V26H0Z"
          fill="none"
        />
        <path
          id="Path_39353"
          data-name="Path 39353"
          d="M4.333,0H15.167A4.333,4.333,0,0,1,19.5,4.333V15.167A4.333,4.333,0,0,1,15.167,19.5H4.333A4.333,4.333,0,0,1,0,15.167V4.333A4.333,4.333,0,0,1,4.333,0Z"
          transform="translate(3.25 3.25)"
          fill="none"
          stroke="#2a2e43"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Path_39352"
          data-name="Path 39352"
          d="M12.416,7a7.559,7.559,0,0,1,5.41,2.27l-3.2,3.146a3.25,3.25,0,0,0-4.416,0L7.006,9.27A7.561,7.561,0,0,1,12.416,7Z"
          transform="translate(0.584 0.583)"
          fill="#2a2e43"
        />
      </svg>
    </>
  );
};

export default WeightMeterIcon;
