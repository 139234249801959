import React, { useState } from "react";
import ArrowAngleRight from "../../icons/ArrowAngleRight";
import { Link } from "react-router-dom";


import AccountIconWhite from "../../../assets/AccountIconWhite.svg";
import UserCard from "../UserCard";
import { HiOutlineX } from "react-icons/hi";
import InviteFriends from "./InviteFriends";
import EmptyMember from "./EmptyMember";
import SearchIcon from "../../icons/SearchIcon";




const WhoToFollow = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [switchMemberList, setSwitchMemberList] = useState(false);
  return (
    <div>
      <div className="w-60">
        <div className="bg-white px-4 py-6 rounded-2xl mb-6">
          <div className="flex items-center justify-between">
            <p className="text-xl leading-10 tracking-wide font-GilroySemiBold">
              Who to follow
            </p>
            <ArrowAngleRight />
          </div>
          <div className="space-y-5">
            <UserCard />
            <UserCard />
            <UserCard />
            <UserCard />

          </div>
        </div>
        <button className="flex w-full border border-[#B9B9B9] rounded-full items-center pl-4 gap-16 py-4  mt-8 bg-[#F7F7F7] " onClick={() => setShowModal(true)}>
          <img src={AccountIconWhite} alt="accounticon" className="w-[24px]" />
          <p className="font-GilroySemiBold text-[16px] text-[#2A2E43]">
            Invite
          </p>
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden w-full overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              {/*content*/}
              <div className="">
                <div className="relative w-[600px]   bg-white rounded-lg shadow dark:bg-gray-700 ">
                  <div className="">
                    <div className=" flex items-center justify-between p-5  py-8  rounded-t dark:border-gray-600">
                      <p className="font-bold text-2xl">
                        Invite friends to join..
                      </p>
                      <HiOutlineX
                        onClick={() => setShowModal(false)}
                        className="text-2xl cursor-pointer"
                      />
                    </div>
                    <div className="flex justify-around ">
                      <p
                        className=" text-fcGray5  text-base hover:underline font-GilroySemiBold underline-offset-[20px] decoration-primary decoration-4 cursor-pointer"
                        onClick={() => setSwitchMemberList(true)}
                      >
                        Member(0)
                      </p>
                      <p
                        className=" text-fcGray5  text-base hover:underline font-GilroySemiBold underline-offset-[20px] decoration-primary decoration-4 cursor-pointer"
                        onClick={() => setSwitchMemberList(false)}
                      >
                        {" "}
                        Friends
                      </p>
                    </div>
                    {switchMemberList ? (
                      <EmptyMember />
                    ) : (
                      <div>
                        <div className="relative my-6 mx-4 ">
                          <input
                            className="w-full bg-fcGray3 rounded-full py-2 pl-7 "
                            placeholder="Search friends"
                          />
                          <SearchIcon className="absolute top-3 left-2 " />
                        </div>
                        <InviteFriends />
                      </div>
                    )}

                    <div className="flex justify-between rounded-b-md bg-fcGray3  py-2 ">
                      <Link to="">
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className="text-[15px] bg-transparent border text-back rounded-full mr-2 px-4 py-1  my-2"
                        >
                          Back
                        </button>
                      </Link>
                      <Link to="/dashboard/challenge">
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className="text-[15px] bg-black text-white rounded-full mr-2 px-4 py-1  my-2"
                        >
                          Done
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
        </>
      ) : null}

    </div>
  );
};

export default WhoToFollow;
