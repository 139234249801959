import React from "react";
import CloseIcon from "../../../components/icons/CloseIcon";
import profileImage from "../../../assets/profile-img.png";
import TextAreaField from "../../../components/common/TextAreaField";
import LocationIcon from "../../../components/icons/LocationIcon";
import TagUserIcon from "../../../components/icons/TagUserIcon";
import memoryIconImage from "../../../assets/img/galleryicon-img.png";
import Button from "../../../components/common/Button";
import userIconImage from "../../../assets/img/userIcon-img.png";
import OrangeGallery from "../../../components/icons/OrangeGallery";

const CreatedPost = ({
  setShowCreatePostPopUp,
  setCurrentPostStep,
  selectFile,
  setSelectFile,
}) => {
  const handleselectedFile = (e) => {
    const selectedFile = e.target.files;
    const selectedFileArray = Array.from(selectedFile);
    const fileArray = selectedFileArray.map((file) => {
      return URL.createObjectURL(file);
    });
    console.log(selectedFile);
    // console.log(fileArray);
    setSelectFile([...selectFile, fileArray]);
    console.log(selectFile);
  };
  return (
    <div>
      <nav className="flex items-center justify-between py-3 mb-5">
        <p className="text-2xl font-GilroyBold">Create Post</p>
        <CloseIcon
          onClick={() =>setShowCreatePostPopUp(false)}
          // onClick={() =>console.log('click')}
          className="h-5 w-5 cursor-pointer"
        />
      </nav>
      <main>
        <form>
          <div className="flex items-center space-x-4 mb-5">
            <img src={profileImage} alt="" className="h-12 w-12" />
            <p className="text-base font-GilroyMedium">Dansteve Adekanbi</p>
          </div>
          <TextAreaField
            className="border-0 outline-none text-xl text-fcGray font-GilroyMedium w-full"
            name="post"
            id="post"
            rows="4"
            placeholder="What’s happening?"
          />
          <div className={`grid mb-4 ${selectFile.length > 1 ? 'grid-cols-2 gap-5' : 'grid-cols-1'}`}>
            {selectFile &&
              selectFile.map((file, uid) => (
                <div key={uid} className="relative">
                  <img src={file} alt="" className={`rounded-xl h-60 ${selectFile.length === 1 ? 'w-full' : 'w-72'}`} />
                  <div className="absolute top-5 left-5 bg-fcGray4 p-1 rounded-full cursor-pointer">
                    <CloseIcon onClick={() =>setSelectFile(selectFile.filter((e) => e !== file))} className=" h-5 w-5" />
                  </div>
                </div>
              ))}
          </div>
          <div className={`flex items-center space-x-4 mb-10 ${selectFile.length <= 0 ? 'hidden' : 'block'}`}>
            <img src={userIconImage} alt="" className="h-4 w-4" />
            <p
              onClick={() => setCurrentPostStep(2)}
              className="block underline text-base text-fcGray cursor-pointer"
            >
              Tag people
            </p>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-10">
              <label htmlFor="file" className="">
                <OrangeGallery className="h-5 w-5 cursor-pointer" />
                <input
                  onChange={handleselectedFile}
                  id="file"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="hidden"
                  multiple
                />
              </label>
              <img
                src={memoryIconImage}
                alt=""
                className="w-5 h-5 cursor-pointer"
              />
              <LocationIcon className="w-5 h-5 cursor-pointer" />
              <TagUserIcon className="w-5 h-5 cursor-pointer" />
            </div>
            <div>
              <Button className="bg-fcPrimary py-3 px-8 text-white rounded-full text-base font-semibold">
                Post
              </Button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreatedPost;
