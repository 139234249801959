import React, { useState } from 'react';
import profileImage from '../../../assets/profile-img.png';
import threedots from '../../../assets/img/threedots.png';
import AddUserIcon from '../../../components/icons/AddUserIcon';
import Button from '../../../components/common/Button';
import { Link } from 'react-router-dom';
import Threedot from '../../../components/icons/Threedot';
import Modal from './Modal';

const AllFriends = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="p-4 relative mx-2 ">
        <h1 className="text-xl font-GilroySemiBold mb-4">200 friends</h1>
        <div className="flex items-center justify-between mb-4">
          <Link to="/dashboard/profile">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </Link>

          <div>
            <img
              src={threedots}
              alt="threedots"
              onClick={() => {
                setShowModal(true);
              }}
            />
            {showModal && <Modal setOpenModal={setShowModal} />}
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <Link to="/dashboard/profile">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </Link>
          <div>
            <img
              src={threedots}
              alt="threedots"
              onClick={() => {
                setShowModal(true);
              }}
            />
            {showModal && <Modal setOpenModal={setShowModal} />}
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <Link to="/dashboard/profile">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </Link>
          <div>
            <img
              src={threedots}
              alt="threedots"
              onClick={() => {
                setShowModal(true);
              }}
            />
            {showModal && <Modal setOpenModal={setShowModal} />}
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <Link to="/dashboard/profile">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </Link>
          <div>
            <img
              src={threedots}
              alt="threedots"
              onClick={() => {
                setShowModal(true);
              }}
            />
            {showModal && <Modal setOpenModal={setShowModal} />}
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <Link to="/dashboard/profile">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </Link>
          <div>
            <img
              src={threedots}
              alt="threedots"
              onClick={() => {
                setShowModal(true);
              }}
            />
            {showModal && <Modal setOpenModal={setShowModal} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFriends;
