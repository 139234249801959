import React from "react";
import Button from '../../../../components/common/Button';

const CropImage = ({ uploadFile, setCurrentStep }) => {
  return (
    <>
      <main>
        <div className="h-64 flex items-center justify-center bg-fcGray7 mb-10">
          <img src={uploadFile} alt="NoImage" className="w-1/2 h-full" />
        </div>
        <div className="mb-10 border border-fcGray4 rounded-md">
            <div className="p-1 bg-fcPrimary w-1/2"></div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            onClick={() => setCurrentStep(1)}
            className="bg-fcDark text-white w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
          >
            Back
          </Button>
          <Button
            onClick={() => setCurrentStep(3)}
            className="bg-fcDark text-white w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
          >
            Apply
          </Button>
        </div>
      </main>
    </>
  );
};

export default CropImage;
