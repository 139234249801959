import React from 'react'
import PeoplesIcon from '../../icons/PeoplesIcon'

const EmptyMember = () => {
  return (
    <div>
       <div className="my-44">
                                <div className="mx-auto items-center justify-center flex mt-8">
                                  <PeoplesIcon />
                                </div>
                                <div>
                                  <p className="text-center text-fcDark text-4xl font-GilroyBold ">
                                    This List is empty
                                  </p>
                                  <p className="text-center text-fcGray2 text-base font-GilroyMedium">
                                    People added to this List will show up here.
                                  </p>
                                </div>
                              </div>
    </div>
  )
}

export default EmptyMember
