import React from "react";
import { Link } from "react-router-dom";
import ArrowAngleRightFilled from '../components/icons/ArrowAngleRightFilled';
import Connect from '../assets/img/connect.png';
import Facebook from '../assets/img/facebook-logo.png';
import Twitter from '../assets/img/twitter-logo.png';
import IG from '../assets/img/instagram-logo.png';
import Logo from '../assets/img/logo-white.png';
import FooterImg from '../assets/img/sarah-cervantes-H8WTALuL7KM-unsplash.png';

const Footer = () => {
  return (
    <footer className=" relative bg-black mt-12 ">
      <div className="flex flex-col items-center justify-center">
        <div>
          <img src={FooterImg} alt="" />
        </div>
        <div className="absolute top-44  w-[790px] flex flex-col items-center">
          <h1 className="font-GilroyBold text-[80px] text-white text-center">
            Take Your Fitness to the Next Level with FitCliq
          </h1>

          <Link
            to="/communitylogin"
            className=" bg-fcPrimary text-white rounded-full px-6 py-4 w-32 text-center"
          >
            <span className="">Join Now</span>
          </Link>
        </div>
      </div>
      <div className="px-20">
        <div>
          <img src={Logo} alt="FitCliq Logo" className="w-28 h-8" />
        </div>
        <div className="flex justify-between mt-10">
          <div>
            <div className="links flex flex-col space-y-6 text-white font-GilroyMedium text-4xl">
              <Link to="/about" className="flex justify-between items-center">
                About <ArrowAngleRightFilled dimension={[24, 24]} />{' '}
              </Link>
              <Link to="/contact" className="flex justify-between items-center">
                Contact <ArrowAngleRightFilled dimension={[24, 24]} />{' '}
              </Link>
              <Link to="/store" className="flex justify-between items-center">
                Store <ArrowAngleRightFilled dimension={[24, 24]} />{' '}
              </Link>
              <Link to="/blog" className="flex justify-between items-center">
                Blog <ArrowAngleRightFilled dimension={[24, 24]} />{' '}
              </Link>
            </div>
          </div>
          <div>
            <img
              src={Connect}
              alt="Connect with us"
              className="w-[95px] h-[93px]"
            />
            <div className="flex justify-between mt-6">
              <img src={Facebook} alt="Connect with us" className="" />
              <img src={Twitter} alt="Connect with us" className="" />
              <img src={IG} alt="Connect with us" className="" />
            </div>
          </div>
        </div>
        <div className="mt-16">
          <hr className="border-fcGray3 opacity-50 border-1" />
          <div>
            <p className="text-white text-sm mt-6 font-GilroyRegular">
              &copy; FitCliq. All Right reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
