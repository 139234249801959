import React from "react";
import redlogo from "../../assets/redlogo.png";
import OptionButton from "../../components/common/OptionButton";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding1 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center mx-auto w-1/3">
        <div>
          <div>
            <div className="w-full bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>

          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">What’s your main goal?</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              Pick what your main goal on FitCliq is
            </p>
          </div>
          <div className="py-4 space-y-4 w-[460px]">
            <OptionButton title="Lose Weight" smallText="Drop extra pounds with ease" />
            <OptionButton title="Be more active" smallText="Drop extra pounds with ease" />
            <OptionButton title="Build Muscle" smallText="Get lean and strong" />
            <OptionButton title="Keep Fit" smallText="Stay in shape and feel great" />
            <OptionButton title="Reduce stress" smallText="Stay in shape and feel great" />
          </div>
          <a href="/onboard/step2">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding1;
