import React from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding9 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  ">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step8">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />            </a>
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">Your current weight</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              We will use it to personalize your fitness plan
            </p>
          </div>
          <div className="flex justify-center items-center pt-4 text-sm w-[460px] ">
            <div className="bg-[#fce5dc] flex rounded-md py-[2px]">
              <p className="px-2 py-[1px] rounded-md ">LBS</p>
              <p className=" bg-primary text-white px-1 rounded-md ">KG</p>
            </div>

          </div>
          <div className="py-4 mx-auto items-center justify-center gap-4  flex ">
            <div className="">
              <div className="flex items-center justify-between space-x-2">
                <div>
                  <input className="mx-auto w-16  border-none text-center text-3xl focus:outline-0" placeholder="0" />
                  <hr className="w-16  opacity-1 h-1 text-fcGray4" />
                </div>
                <div>
                  <p className="text-2xl">KG</p>
                </div>
              </div>
            </div>

          </div>
          <p className="text-xs text-center">
            Please, enter a value from <span className="font-bold">25kg</span>{" "}
            to <span className="font-bold">300kg</span>
          </p>
          <a href="/onboard/step11">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding9;
