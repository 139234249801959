import React from "react";

const PeopleIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_people"
          data-name="vuesax/bold/people"
          transform="translate(-620 -252)"
        >
          <g id="people" transform="translate(620 252)">
            <path id="Vector" d="M0,0H26V26H0Z" fill="none" opacity="0" />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M3.239,6.251a.8.8,0,0,0-.228,0,3.136,3.136,0,1,1,.228,0Z"
              transform="translate(15.752 2.167)"
              fill="#ee5014"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M4.539,5.084a6.727,6.727,0,0,1-4.485.91A7.075,7.075,0,0,0,.693,3.079,6.657,6.657,0,0,0,0,.068a6.757,6.757,0,0,1,4.528.9A2.3,2.3,0,0,1,4.539,5.084Z"
              transform="translate(17.983 10.841)"
              fill="#ee5014"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M3.012,6.251a.8.8,0,0,1,.227,0,3.125,3.125,0,1,0-.227,0Z"
              transform="translate(3.965 2.167)"
              fill="#ee5014"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M4.935,3.08a7.055,7.055,0,0,0,.639,2.947,6.694,6.694,0,0,1-4.29-.932,2.3,2.3,0,0,1,0-4.128A6.653,6.653,0,0,1,5.617.046,6.888,6.888,0,0,0,4.935,3.08Z"
              transform="translate(2.161 10.841)"
              fill="#ee5014"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M3.867,7.443a1.224,1.224,0,0,0-.282,0,3.738,3.738,0,1,1,.282,0Z"
              transform="translate(9.262 9.75)"
              fill="#ee5014"
            />
            <path
              id="Vector-7"
              data-name="Vector"
              d="M1.227.934a2.224,2.224,0,0,0,0,3.976,6.62,6.62,0,0,0,6.782,0,2.224,2.224,0,0,0,0-3.976A6.62,6.62,0,0,0,1.227.934Z"
              transform="translate(8.382 18.501)"
              fill="#ee5014"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default PeopleIcon;
