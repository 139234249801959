import React from "react";

const FieldInput = ({
  label,
  type,
  name,
  id,
  placeholder,
  className,
  onChange,
  multiple,
}) => {
  return (
    <div>
      <label htmlFor="">{label}</label>
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        multiple={multiple}
      />
    </div>
  );
};

export default FieldInput;
