import React, { useState } from "react";
import CloseIcon from "../../../components/icons/CloseIcon";
import profileImage from "../../../assets/profile-img.png";
import LocationIcon from "../../../components/icons/LocationIcon";
import TagUserIcon from "../../../components/icons/TagUserIcon";
import galleryIconImage from "../../../assets/img/galleryicon-img.png";
import memoryIconImage from "../../../assets/img/galleryicon-img.png";
import Button from "../../../components/common/Button";
import SearchIcon from "../../../components/icons/SearchIcon";
import FieldInput from "../../../components/common/FieldInput";
import { Link } from "react-router-dom";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import MarkIcon from "../../../components/icons/MarkIcon";
import OrangeCloseIcon from "../../../components/icons/OrangeCloseIcon";

const TagPeople = ({ setShowCreatePostPopUp }) => {
  const [showPeopleTag, setShowPeopleTag] = useState(false);
  return (
    <div>
      <nav className="flex items-center justify-between py-3 mb-5">
        <p className="text-2xl font-GilroyBold">Tag People</p>
        <CloseIcon
          // onClick={() => setShowCreatePostPopUp(false)}
          className="h-5 w-5 cursor-pointer"
        />
      </nav>
      <main>
        <div>
          <div className=" px-2 space-x-2 flex items-center border-0 rounded-full bg-fcGray6 mb-10 outline-none">
            <SearchIcon className="w-10" />
            <FieldInput
              type="text"
              name="name"
              id="name"
              className=" w-[900px] bg-transparent p-3 outline-none"
            />
          </div>
          <div className="flex items-center gap-4 mb-5">
             <div className="flex items-center gap-5 border border-fcGray4 rounded-full p-2">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                  </div>
                </div>
                <div>
                  <Link
                    to=""
                    className="flex items-center justify-center rounded-full"
                  >
                    <OrangeCloseIcon />
                  </Link>
                </div>
              </div>
              <div className="flex items-center gap-5 border border-fcGray4 rounded-full  p-2 ">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                  </div>
                </div>
                <div>
                  <Link
                    to=""
                    className="flex items-center justify-between  rounded-full cursor-pointer"
                  >
                    <OrangeCloseIcon />
                  </Link>
                </div>
              </div>
          </div>
          <div className="h-[44vh] mb-4">
            <div className="h-[44vh] overflow-y-scroll scroll-smooth flex flex-col no-scrollbar">
              <div
                onClick={() => setShowPeopleTag(!showPeopleTag)}
                className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div>
                  <Link
                    to=""
                    className={`flex items-center space-x-2 rounded-full  py-1 px-3`}
                  >
                    <MarkIcon className="" />
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between mb-8 group hover:bg-fcGray6 p-2 cursor-pointer">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={profileImage} alt="" className="w-12 h-12" />
                  </div>
                  <div>
                    <h1 className="font-GilroyMedium text-base">
                      Adeshina Adedapo
                    </h1>
                    <p className="text-xs font-GilroyRegular">Lekki, Nigeria</p>
                  </div>
                </div>
                <div className="hidden group-hover:block">
                  <Link
                    to=""
                    className="flex items-center space-x-2 rounded-full bg-fcGray4 py-1 px-3"
                  >
                    <AddUserIcon /> <span>Invite</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
              <Button className="border border-fcGray4 py-3 px-8  rounded-full text-base font-semibold">
                Back
              </Button>
              <Button className="bg-fcDark py-3 px-8 text-white rounded-full text-base font-semibold">
                Done
              </Button>
            </div>
        </div>
      </main>
    </div>
  );
};

export default TagPeople;
