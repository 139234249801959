import * as React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./views/Home";
import Home2 from "./views/Home2";
import Dashboard from "./views/dashboard";
import Profile from "./views/dashboard/profile/Profile";
import Login from "./views/auth/Login/Login";
import Register from "./views/auth/Register/Register";
import CommunityLogin from "./views/auth/Login/CommunityLogin";
import Onboarding from "./views/onboarding/Onboarding";
import Step1 from "./views/onboarding/Onboarding1";
import Step2 from "./views/onboarding/Onboarding2";
import Step3 from "./views/onboarding/Onboarding3";
import Step4 from "./views/onboarding/Onboarding4";
import Step5 from "./views/onboarding/Onboarding5";
import Step6 from "./views/onboarding/Onboarding6";
// import Step7 from "./views/onboarding/Onboarding7";
// import Step8 from "./views/onboarding/Onboarding8";
// import Step9 from "./views/onboarding/Onboarding9";
// import Step10 from "./views/onboarding/Onboarding10";
import Step11 from "./views/onboarding/Onboarding11";
// import Step12 from "./views/onboarding/Onboarding12";
import Step13 from "./views/onboarding/Onboarding13";
import Step14 from "./views/onboarding/Onboarding14";
import Step15 from "./views/onboarding/Onboarding15";
import Step16 from "./views/onboarding/Onboarding16";
import Step17 from "./views/onboarding/Onboarding17";
import Step18 from "./views/onboarding/Onboarding18";
import NewChallenge from "./views/dashboard/challenge/NewChallenge";
import Challenge from "./views/dashboard/challenge";
import ChallengePage from "./views/dashboard/challenge/Challenge";
import Workout from "./views/dashboard/challenge/Workout";
import Workout2 from "./views/dashboard/challenge/Workout2";
import Cliq from "./views/dashboard/cliq";
import CliqPage from "./views/dashboard/cliq/CliqPage";
import Post from "./views/dashboard/cliq/Post";
import AbBurner from "./views/dashboard/newChallenge/AbBurner";
import AbburnerVideo from "./views/dashboard/newChallenge/AbburnerVideo";
import MaximizeVideo from "./views/dashboard/newChallenge/MaximizeVideo";
import LiveVideo from "./views/dashboard/newChallenge/LiveVideo";
import Discover from "./views/dashboard/Discover";
import Notification from "./views/dashboard/notification/Notification";
import Messages from "./views/dashboard/messages";
import Friends from "./views/dashboard/friends";
import Coach from "./views/dashboard/coach";
import CoachDetails from "./views/dashboard/coach/CoachDetails";
import ExternalChallage from "./views/dashboard/challenge/ExternalChallenge";
import JoinChallenge from "./views/dashboard/challenge/JoinChallenge";
import ChallengeLeaderBoard from "./views/dashboard/challenge/ChallengeLeaderBoard";
import Statistic from "./views/dashboard/mystatistic/Statistic";
import Settings from "./views/dashboard/Settings/Settings";
import Terms from "./views/dashboard/Settings/Terms";
import PrivacyPolicy from "./views/dashboard/Settings/PrivacyPolicy";
import SettingsPrivacy from "./views/dashboard/Settings/SettingsPrivacy";
import EditProfile from "./views/dashboard/Settings/EditProfile";
import SettingsNotification from "./views/dashboard/Settings/SettingsNotification";
import WorkoutPreference from "./views/dashboard/Settings/WorkoutPreference";
import About from "./views/About";
import Contact from "./views/Contact";
import Blog from "./views/blog";
import BlogPost from "./views/blog/Post";
// import Cliqs from "./views/dashboard/cliq/Cliqs";
// import Discover2 from "./views/dashboard/Discover/CliqSearch";
// import VideoTimer from "./views/dashboard/newChallenge/VideoTimer";

export function Routes() {
  let element = useRoutes([
    {
      path: "/",
      children: [
        {
          index: true,
          element: <Home2 />,
        },
        {
          path: "register",
          element: <Register />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "blog",
          element: <Blog />,
        },
        {
          path: "post",
          element: <BlogPost />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "communitylogin",
          element: <CommunityLogin />,
        },
        {
          path: "external",
          element: <ExternalChallage />,
        },
      ],
    },
    {
      path: "/onboard",
      children: [
        {
          index: true,
          element: <Onboarding />,
        },
        {
          path: "step1",
          element: <Step1 />,
        },
        {
          path: "step2",
          element: <Step2 />,
        },
        {
          path: "step3",
          element: <Step3 />,
        },
        {
          path: "step4",
          element: <Step4 />,
        },
        {
          path: "step5",
          element: <Step5 />,
        },
        {
          path: "step6",
          element: <Step6 />,
        },
        // {
        //   path: "step7",
        //   element: <Step7 />,
        // },
        // {
        //   path: "step8",
        //   element: <Step8 />,
        // },
        // {
        //   path: "step9",
        //   element: <Step9 />,
        // },
        // {
        //   path: "step10",
        //   element: <Step10 />,
        // },
        {
          path: "step11",
          element: <Step11 />,
        },
        // {
        //   path: "step12",
        //   element: <Step12 />,
        // },
        {
          path: "step13",
          element: <Step13 />,
        },
        {
          path: "step14",
          element: <Step14 />,
        },
        {
          path: "step15",
          element: <Step15 />,
        },
        {
          path: "step16",
          element: <Step16 />,
        },
        {
          path: "step17",
          element: <Step17 />,
        },
        {
          path: "step18",
          element: <Step18 />,
        },
      ],
    },
    {
      path: "/dashboard",
      children: [
        {
          index: true,
          element: <Dashboard />,
        },

        {
          path: "statistics",
          element: <Statistic />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "discover",
          element: <Discover />,
        },
        {
          path: "friends",
          element: <Friends />,
        },
        {
          path: "notification",
          element: <Notification />,
        },
        {
          path: "messages",
          element: <Messages />,
        },
        {
          path: "settings",
          children: [
            {
              index: true,
              element: <Settings />,
            },
            {
              path: "terms",
              element: <Terms />,
            },
            {
              path: "policy",
              element: <PrivacyPolicy />,
            },
            {
              path: "privacy",
              element: <SettingsPrivacy />,
            },
            {
              path: "edit",
              element: <EditProfile />,
            },
            {
              path: "notification",
              element: <SettingsNotification />,
            },
            {
              path: "reference",
              element: <WorkoutPreference />,
            },
          ],
        },
        {
          path: "coach",
          children: [
            {
              index: true,
              element: <Coach />,
            },
            {
              path: "details",
              element: <CoachDetails />,
            },
          ],
          // element: <Coach />
        },
        {
          path: "challenge",
          children: [
            {
              index: true,
              element: <Challenge />,
            },
            {
              path: ":challengeId",
              element: <ChallengePage />,
            },
            {
              path: "new",
              element: <NewChallenge />,
            },
            {
              path: "joinchallenge",
              element: <JoinChallenge />,
            },
            {
              path: "leaderboard",
              element: <ChallengeLeaderBoard />,
            },
            {
              path: "workout",
              element: <Workout />
            },
            {
              path: "workout2",
              element: <Workout2 />
            },
          ],
        },
        {
          path: "cliq",
          children: [
            {
              index: true,
              element: <Cliq />,
            },
            {
              path: "post",
              element: <Post />
            },

            {
              path: ":cliqId",
              element: <CliqPage />,
            },
            {
              path: "new",
              element: <NewChallenge />,
            },
          ],
        },
      ],
    },
  ]);

  return element;
}
