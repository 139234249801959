import React from 'react';
import profileImage from "../../assets/profile-img.png";
import AddUserIcon from "../icons/AddUserIcon";
import Button from "./Button";
import yogaImage from "../../assets/img/yogga.png";

function CliqCardMax() {
    return (
        <div className="flex items-center justify-between mb-5">
            <div className="flex space-x-3">
                <div>
                    <img src={yogaImage} alt="" className="h-24 w-24" />
                </div>
                <div>
                    <h2 className="text-fcNavy text-base font-GilroySemiBold">Yoga Lovers</h2>
                    <p className="text-base text-fcDark font-GilroyRegular">
                        A communities of Fitness freaks. Taking <br /> fitness to a whole new level. We use yoga…
                    </p>
                    <div className="flex items-center">
                        <img src={profileImage} alt="" className="w-8 h-8 " />
                        <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                        <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                        <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                            {" "}
                            <p>+2.4k</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button className="flex items-center justify-center bg-fcGray4 py-1 px-3 rounded-full space-x-1">
                    <AddUserIcon /> Join
                </Button>
            </div>
        </div>
    );
}

export default CliqCardMax;