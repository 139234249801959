import React, { useState } from 'react';
import SideBar from '../sidebar/SideBar';
import { Link } from 'react-router-dom';
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg';
import ArrowDownIcon from '../../../components/icons/ArrowDownIcon';
import Button from '../../../components/common/Button';
import Oval from '../../../assets/Oval.png';
import BoldCamera from '../../../components/icons/BoldCamera';
import { Modals } from '../../../components/common/Modals';
import CloseIcon from '../../../components/icons/CloseIcon';
import { HiOutlineX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showHeightModal, setShowHeightModal] = useState(false);
  const [open, setOpen] = useState(false);

  function handleClick() {
    // navigate('/dashboard');
  }
  return (
    <div className="bg-fcGray6">
      <div className="bg-fcGray6">
        <SideBar />
        <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
          <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
            <div className="mt-6 ">
              <div className="w-[70%] z-10 mx-auto">
                <nav className="py-4 z-15 bg-fcGray6 sticky top-0 flex justify-between">
                  <div className="flex items-center gap-2">
                    <Link to="/dashboard/settings">
                      <img
                        src={ArrowLeft}
                        className="bg-white p-2 rounded-full"
                      />
                    </Link>

                    <p className="font-GilroySemiBold text-xl text-fcNavy ">
                      Edit Profile
                    </p>
                  </div>
                </nav>
                <div className="shadow-sm  overflow-x-hidden">
                  <div className="bg-[#D1D5DB]  rounded-t-md h-24 "></div>
                  <div className="bg-white   rounded-t-md pb-6 rounded-xl relative  ">
                    <div className="absolute -top-14 left-60">
                      <img src={Oval} alt="oval" className="mx-auto relative" />
                      <BoldCamera className="bg-primary text-white rounded-full absolute bottom-1 left-16" />
                    </div>

                    <div className="pt-14 px-4">
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroyRegular  text-sm">
                          Name
                        </p>
                        <input
                          className="border border-fcGray4 h-12 rounded-xl w-80 pl-4 font-GilroyMedium text-sm "
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Email
                        </p>
                        <input
                          className="border border-fcGray4 h-12 rounded-xl w-80 pl-4 font-GilroyMedium text-sm "
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Phone
                        </p>
                        <input
                          className="border border-fcGray4 h-12 rounded-xl w-80 pl-4 font-GilroyMedium text-sm "
                          placeholder="Add Phone"
                          type="number"
                        />
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Location
                        </p>
                        <div className="">
                          <select
                            id="location"
                            name="location"
                            className="w-80 bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500"
                          >
                            <option selected className="text-fcGray">
                              Location
                            </option>
                            <option value="Oyo">Oyo, NG</option>
                            <option value="Ogun">Ogun, NG</option>
                            <option value="Lagos">Lagos, NG</option>
                          </select>
                          {/* <ArrowDownIcon className="" /> */}
                        </div>
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Date of Birth
                        </p>
                        <div>
                          <div className=" w-80 items-center justify-center gap-2 flex ">
                            <div className="text-[14px] text-fcGray2 grid">
                              <label>Day</label>
                              <select className="bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500">
                                <option value="Advance">1</option>
                                <option value="Advance">2</option>
                                <option value="Advance">3</option>
                                <option value="Advance">4</option>
                                <option value="Advance">5</option>
                                <option value="Advance">6</option>
                                <option value="Advance">7</option>
                                <option value="Advance">8</option>
                                <option value="Advance">9</option>
                                <option value="Advance">10</option>
                                <option value="Advance">11</option>
                                <option value="Advance">12</option>
                              </select>
                            </div>
                            <div className="text-[14px] text-fcGray2 grid">
                              <label>Month</label>
                              <div>
                                <select className="bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500">
                                  <option value="Advance">January</option>
                                  <option value="Advance">February</option>
                                  <option value="Advance">March</option>
                                  <option value="Advance">April</option>
                                  <option value="Advance">May</option>
                                  <option value="Advance">June</option>
                                  <option value="Advance">July</option>
                                  <option value="Advance">August</option>
                                  <option value="Advance">September</option>
                                  <option value="Advance">October</option>
                                  <option value="Advance">November</option>
                                  <option value="Advance">December</option>
                                </select>
                              </div>
                            </div>
                            <div className="text-[14px] text-fcGray2 grid ">
                              <label>Year</label>
                              <div>
                                <select className="bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500">
                                  <option value="Advance">1992</option>
                                  <option value="Advance">1993</option>
                                  <option value="Advance">1994</option>
                                  <option value="Advance">1995</option>
                                  <option value="Advance">1996</option>
                                  <option value="Advance">1997</option>
                                  <option value="Advance">1998</option>
                                  <option value="Advance">1999</option>
                                  <option value="Advance">2000</option>
                                  <option value="Advance">2001</option>
                                  <option value="Advance">2002</option>
                                  <option value="Advance">2003</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="font-GilroyRegular text-xs mt-2 ">
                              You’ll be <span className="font-bold">25yrs</span>{' '}
                              on the 24th of Nov 2023
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Fitness Level
                        </p>
                        <div className="">
                          <select
                            id="location"
                            name="location"
                            className="w-80 bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500"
                          >
                            <option value="Advance">Advanced</option>
                            <option value="Advance">Advanced</option>
                            <option value="Advance">Advanced</option>
                            <option value="Advance">Advanced</option>
                          </select>
                          {/* <ArrowDownIcon className="" /> */}
                        </div>
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Gender
                        </p>
                        <div className="">
                          <select
                            id="location"
                            name="location"
                            className="w-80 bg-gray-50 border border-fcGray4 text-gray-900 text-sm font-GilroyMedium text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-4 py-2 dark:bg-gray-700 dark:border-gray-600   dark:focus:border-blue-500"
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Others</option>
                          </select>
                          {/* <ArrowDownIcon className="" /> */}
                        </div>
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Starting Weight
                        </p>
                        <div
                          className=" w-80 items-center border border-fcGray4 py-3 px-4 rounded-xl "
                          onClick={() => setOpen(!open)}
                        >
                          <p
                            className="font-GilroyMedium text-sm text-fcDark"
                            onClick={() => setOpen(!open)}
                          >
                            110.5 <span className="text-fcGray2">kg</span>
                          </p>
                        </div>
                        {open ? (
                          <Modals>
                            <div className="flex items-center min-h-screen rounded-lg ">
                              <div className="relative w-full max-w-3xl pt-4  mx-auto bg-white rounded-lg shadow-lg">
                                <div className="mt-3 ">
                                  <div className="mt-2    text-left">
                                    <div className="flex justify-between gap-96 items-center m-4">
                                      <p className="text-2xl font-GilroyBold text-fcDark">
                                        Your Starting Weight
                                      </p>
                                      <HiOutlineX
                                        className="text-xl"
                                        onClick={() => setOpen(!open)}
                                      />
                                    </div>

                                    <div className="flex justify-center items-center pt-4 text-[13px] my-8  ">
                                      <div className="bg-[#fce5dc] flex rounded-md py-[2px]">
                                        <p className="px-4 py-[2px] rounded-md font-GilroySemiBold text-sm ">
                                          LBS
                                        </p>
                                        <p className=" bg-primary text-white rounded-md font-GilroySemiBold text-sm px-4 py-2  ">
                                          KB
                                        </p>
                                      </div>
                                    </div>
                                    <div className="py-4 mx-auto items-center justify-center gap-4  flex ">
                                      <div className="">
                                        <input
                                          className="mx-auto w-10  border-none text-center text-4xl focus:outline-0"
                                          placeholder="0"
                                        />
                                        <div className="flex  gap-2 relative">
                                          <hr className="w-12 mx-auto opacity-1 h-1 text-fcGray4" />
                                          <p className="text-2xl absolute bottom-1 left-[55px] font-GilroyMedium ">
                                            kg
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-sm text-center">
                                      Please, enter a value from{' '}
                                      <span className="font-bold">25kg</span> to{' '}
                                      <span className="font-bold">300kg</span>
                                    </p>
                                    <div className="items-center gap-2 mt-8 bg-fcGray3 w-full flex py-3 justify-end ">
                                      <button
                                        className=" mt-2 mx-4 px-6 py-2  text-sm text-white bg-black   rounded-full outline-none ring-offset-2"
                                        onClick={() =>
                                          setShowDeleteModal(false)
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modals>
                        ) : null}
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Target Weight
                        </p>
                        <div className=" w-80 items-center border border-fcGray4 py-3 px-4 rounded-xl ">
                          <p className="font-GilroyMedium text-sm text-fcDark">
                            90 <span className="text-fcGray2">kg</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Height
                        </p>
                        <div
                          className=" w-80 items-center border border-fcGray4 py-3 px-4 rounded-xl "
                          onClick={() => setShowHeightModal(!showHeightModal)}
                        >
                          <p className="font-GilroyMedium text-sm text-fcDark">
                            186 <span className="text-fcGray2">cm</span>
                          </p>
                        </div>
                        {showHeightModal ? (
                          <Modals>
                            <div className="flex justify-center items-center pt-4 text-[13px] my-8  ">
                              <div className="relative w-full max-w-3xl pt-4  mx-auto bg-white rounded-lg shadow-lg">
                                <div className="mt-3 ">
                                  <div className="mt-2    text-left">
                                    <div className="flex justify-between gap-96 items-center m-4">
                                      <div className="flex justify-between gap-14">
                                        <p className="text-2xl font-GilroyBold text-fcDark">
                                          Height
                                        </p>
                                        <div></div>
                                        <div></div>
                                      </div>

                                      <HiOutlineX
                                        className="text-xl cursor-pointer"
                                        onClick={() => setOpen(false)}
                                      />
                                    </div>

                                    <div className="flex justify-center items-center pt-4 text-sm my-8 ">
                                      <div className="bg-[#fce5dc] flex rounded-md ">
                                        <p className="bg-primary text-white rounded-md text-sm px-3 py-1 text-center my-auto ">
                                          FT
                                        </p>
                                        <p className="text-sm px-3 py-2 ">
                                          CM
                                        </p>
                                      </div>
                                    </div>
                                    <div className="pt-4 mx-auto items-center justify-center gap-4  flex ">
                                      <div className="">
                                        <input
                                          className="mx-auto w-10  border-none text-center focus:outline-0 text-4xl "
                                          type="number"
                                        />
                                        <div className="flex  gap-2 relative">
                                          <hr className="w-12 mx-auto opacity-1 h-1 text-fcGray4" />
                                          <p className="text-2xl absolute bottom-1 left-[55px] ">
                                            ft
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <input
                                          className="mx-auto w-10 text-xl  border-none text-center  focus:outline-0"
                                          type="number"
                                        />
                                        <div className="flex  gap-2 relative">
                                          <hr className="w-12 mx-auto opacity-1 h-1 text-fcGray4" />
                                          <p className="text-2xl absolute bottom-1 left-[55px] ">
                                            in
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-sm text-center">
                                      Please, enter a value from{' '}
                                      <span className="font-bold">3ft</span> to{' '}
                                      <span className="font-bold">
                                        7 ft 11 in
                                      </span>
                                    </p>
                                    <div className="items-center gap-2 mt-8 bg-fcGray3 w-full flex py-3 justify-end ">
                                      <button
                                        className=" mt-2 mx-4 px-6 py-2  text-sm text-white bg-black   rounded-full outline-none ring-offset-2"
                                        onClick={() =>
                                          setShowDeleteModal(false)
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modals>
                        ) : null}
                      </div>
                      <div className="flex gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Daily Steps Goal
                        </p>
                        <div className=" w-80 items-center border border-fcGray4 py-3 px-4 rounded-xl ">
                          <p className="font-GilroyMedium text-sm text-fcDark">
                            11,000 <span className="text-fcGray2">steps</span>
                          </p>
                        </div>
                      </div>
                      <div className=" gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Password
                        </p>
                        <div className="flex justify-between items-center py-4">
                          <input
                            name="password"
                            type="password"
                            placeholder="***********"
                            className="border-none bg-transparent"
                          />
                          <p className="underline">Change</p>
                        </div>
                      </div>
                      <hr className="w-full text-fcGray4" />
                      <div className=" gap-8 items-center justify-between w-full mt-6">
                        <p className="text-fcDark font-GilroySemiBold text-sm">
                          Delete Account
                        </p>
                        <div className="flex justify-between items-center py-2">
                          <p className="text-fcGray text-sm font-GilroyRegular">
                            NOTE: Account will NOT BE RECOVERABLE
                            <br /> once deleted.
                          </p>
                          <button
                            className="rounded-full border border-fcGray4 px-4 py-2 text-sm font-GilroyRegular hover:bg-fcPrimary hover:text-white"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            Delete
                          </button>
                        </div>
                        <div>
                          {showDeleteModal ? (
                            <>
                              <div className="fixed inset-0 z-25 overflow-y-auto ">
                                <div
                                  className="fixed inset-0 w-full h-full bg-fcGray opacity-40"
                                  onClick={() => setShowDeleteModal(false)}
                                ></div>
                                <div className="flex items-center min-h-screen ">
                                  <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-lg shadow-lg">
                                    <div className="mt-3 ">
                                      <div className="mt-2   text-left">
                                        <p className="text-2xl font-GilroyBold text-fcDark">
                                          Are you sure you want to delete all
                                          data?
                                        </p>
                                        <p className="mt-2 text-sm leading-relaxed text-fcGray font-GilroyMedium">
                                          This action cannot be undone and you
                                          will be logged out. Your subscription
                                          will stay active.
                                        </p>
                                        <div className="items-center gap-2 mt-3 ">
                                          <button
                                            className="w-full mt-2 p-2.5 flex-1 text-sm text-white bg-primary rounded-full outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                            onClick={() =>
                                              setShowDeleteModal(false)
                                            }
                                          >
                                            Yes, Delete all my data
                                          </button>
                                          <button
                                            className="w-full mt-2 p-2.5 flex-1 text-sm  rounded-full outline-none border border-fcGray4 ring-offset-2 ring-indigo-600 focus:ring-2"
                                            onClick={() =>
                                              setShowDeleteModal(false)
                                            }
                                          >
                                            No, Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <hr className="w-full text-fcGray4 mt-4" />
                    </div>
                    <div className="flex items-end justify-end mt-16 mx-4 ">
                      <Button className="bg-fcGray4 text-sm px-8 py-2 font-GilroySemiBold rounded-full ">
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditProfile;
