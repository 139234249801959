import React, { useState } from "react";
import ArrowAngleRight from "../../components/icons/ArrowAngleRight";
import SideBar from "./sidebar/SideBar";
import profileImageOne from "../../assets/img/profile-1.png";
import profileImageTwo from "../../assets/img/profile-2.png";
import profileImageThree from "../../assets/img/profile-3.png";
import SmallAddIcon from "../../components/icons/SmallAddIcon";
import runningImg from "../../assets/img/Runningicon.png";
import timerImage from "../../assets/img/timer.png";
import yoggaloversImg from "../../assets/img/yogga.png";
import walkersImg from "../../assets/img/walkers.png";
import vegetablesImg from "../../assets/img/vegetables.png";
import streakImage from "../../assets/img/streaksimg.png";
import workOutImage from "../../assets/img/workoutimg.png";
import timmerimage from "../../assets/img/timmerimg.png";
import caloriesImage from "../../assets/img/caloriesimg.png";
import whiteUserIcon from "../../assets/img/whiteuserIcon.png";
import Button from "../../components/common/Button";
import AddUserIcon from "../../components/icons/AddUserIcon";
import SmallHeartIcon from "../../components/icons/SmallHeartIcon";
import FlowerIcon from "../../components/icons/FlowerIcon";
import InviteIcon from "../../components/icons/InviteIcon";
import Modal from "../../components/common/Modal";
import CloseIcon from "../../components/icons/CloseIcon";
import CopyLinkIcon from "../../components/icons/CopyLinkIcon";
import WhatsappIcon from "../../components/icons/WhatsappIcon";
import TwitterIcon from "../../components/icons/TwitterIcon";
import Messenger from "../../components/icons/Messenger";
import FacebookIcon from "../../components/icons/FacebookIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import WhiteEmail from "../../components/icons/WhiteEmail";
import MessageIcon from "../../components/icons/MessageIcon";
import FacebookIconWithBg from "../../components/icons/FacebookIconWithBg";
import SmsIcon from "../../components/icons/SmsIcon";
import Loader from "../../components/common/Loader";
import ChallengeCard from "../../components/common/ChallengeCard";
import CliqCardMin from "../../components/common/CliqCardMin";
import DealCard from "../../components/common/DealCard";

const Dashboard = () => {
  const [invite, setInvite] = useState(false);
  return (
    <div className="bg-fcGray6 flex justify-between gap-10 space-x-44">
      <SideBar />
      <main className="w-[820px]  px-44 py-5">
        <div className="w-[820px] ">
          <nav className="border-b border-fcGray4 p-5 z-10 w-full bg-fcGray6 sticky top-0">
            <p className="text-2xl font-GilroySemiBold">For You</p>
          </nav>
          <div className="relative flex gap-8 justify-between mt-5">
            <div className="w-[555px] z-5">
              <div className="bg-white p-4 mb-10 rounded-xl h-[330px">
                <div className="flex items-center justify-between mb-5">
                  <p className="text-xl font-GilroySemiBold">Challenges</p>
                  <ArrowAngleRight />
                </div>
                <div className="flex gap-4 items-center flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar cursor-pointer"> 
                  <ChallengeCard />
                  <ChallengeCard />
                  <ChallengeCard />
                  <ChallengeCard />
                </div>
              </div>
              <div className="bg-white p-4 mb-10 rounded-xl h-44">
                <div className="flex justify-between">
                  <div>
                    <p className="text-xl font-GilroySemiBold">Weight</p>
                    <p className="text-sm font-GilroyRegular text-fcGray5">
                      Aug 11 - Nov 2
                    </p>
                  </div>
                  <div className="flex items-center gap-5">
                    <SmallAddIcon />
                    <ArrowAngleRight />
                  </div>
                </div>
                <div>
                  <h1 className=" font-GilroyBold text-fcPrimary">
                    <span className="text-4xl">110</span>
                    <span className="text-xs">kg</span>
                  </h1>
                  <div className="flex items-center justify-between text-xs font-GilroyMedium">
                    <div>
                      <p className="text-fcPrimary font-GilroyMedium text-xs">
                        18 hr. ago
                      </p>
                    </div>
                    <div className="flex items-center gap-10 text-xs font-GilroyRegular text-fcGray5">
                      <p>Sep</p>
                      <p>Oct</p>
                      <p>Nov</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 mb-10 rounded-xl">
                <div className="flex justify-between mb-20">
                  <div>
                    <p className="text-xl font-GilroySemiBold">
                      Energy expended
                    </p>
                    <p className="text-sm font-GilroyMedium">Last 7 days</p>
                  </div>
                  <div className="flex items-center gap-5">
                    <SmallAddIcon />
                    <ArrowAngleRight />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-fcPrimary text-5xl font-GilroyBold">
                      <span className="text-4xl">1,343</span>
                      <span className="text-xs"> Cal</span>
                    </h1>
                    <p className="text-fcPrimary text-xs font-GilroyMedium">
                      Today
                    </p>
                  </div>
                  <div>
                    <p className="text-xs font-GilroyMedium border border-fcGray4 py-2 px-5 rounded-full shadow-2xl mb-2">
                      <span className="text-fcPrimary">2,234 Cal</span> on
                      October 30
                    </p>
                    <div className="flex items-baseline gap-5 text-xs">
                      <div>
                        <div className="bg-fcPrimary w-6 h-12 rounded-lg p-3"></div>
                        <p>T</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-12 rounded-lg p-3"></div>
                        <p>F</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-12 rounded-lg p-3"></div>
                        <p>S</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-12 rounded-lg p-3"></div>
                        <p>S</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-16 rounded-lg p-3"></div>
                        <p>M</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-6 rounded-lg p-3"></div>
                        <p>T</p>
                      </div>
                      <div>
                        <div className="bg-fcPrimary w-6 h-20 rounded-lg p-3"></div>
                        <p>W</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 mb-10 rounded-xl">
                <div className="flex justify-between mb-4">
                  <div>
                    <p className="text-xl font-GilroySemiBold">
                      Last workout
                    </p>
                    <p className="text-sm font-GilroyMedium">
                      Nov 4 . 12:24 PM . 30 min
                    </p>
                  </div>
                  <div className="flex items-center gap-5">
                    <SmallAddIcon />
                    <ArrowAngleRight />
                  </div>
                </div>
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-base font-GilroySemiBold mb-4">
                      Running
                    </p>
                    <div className="flex items-center">
                      <div className="flex items-center gap-2 border-r border-fcGray4 pr-3">
                        <img src={timerImage} alt="" className="w-6 h-6" />
                        <p className="text-base">
                          30 <span className="text-xs">min</span>
                        </p>
                      </div>
                      <div className="flex items-center gap-2 border-r border-fcGray4 px-3 ">
                        <SmallHeartIcon className="h-4 w-5" />
                        <p className="text-base">4</p>
                      </div>
                      <div className="flex items-center gap-2 pl-3">
                        <FlowerIcon className="h-4 w-5" />
                        <p className="text-base">224</p>
                      </div>
                    </div>
                  </div>
                  <dir>
                    <img src={runningImg} alt="" className="w-20 h-20" />
                  </dir>
                </div>
              </div>
              <div className="bg-white p-4 mb-10 rounded-xl">
                <div className="flex justify-between mb-10">
                  <p className="text-xl font-GilroySemiBold">Cliqs</p>
                  <div className="flex items-center gap-5">
                    <SmallAddIcon />
                    <ArrowAngleRight />
                  </div>
                </div>
                <div className="flex items-center overflow-x-scroll scroll-smooth flex-nowrap gap-5">
                 <CliqCardMin />
                 <CliqCardMin />
                 <CliqCardMin />
                 <CliqCardMin />
                 <CliqCardMin />
                </div>
              </div>
              <div className="bg-white p-4 mb-10 rounded-xl">
                <div className="flex justify-between mb-10">
                  <p className="text-xl font-GilroySemiBold">Deals</p>
                  <div className="flex items-center gap-5">
                    <ArrowAngleRight />
                  </div>
                </div>
                <div className="flex items-center flex-nowrap overflow-x-scroll scroll-smooth gap-5">
                  <DealCard />
                  <DealCard />
                  <DealCard />
                  <DealCard />
                  <DealCard />
                </div>
              </div>
              <Loader />
            </div>

            <div className="w-56.5 right-0 absolute">
              <div className="bg-white p-4 mb-5 rounded-xl">
                <div className="flex items-center justify-between mb-5">
                  <p className="text-xl leading-10 tracking-wide font-GilroySemiBold">
                    Weekly Progress
                  </p>
                  <ArrowAngleRight />
                </div>
                <div className="space-y-8">
                  <div className="flex items-center space-x-6 mb-1">
                    <div>
                      <img src={streakImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <p className="text-xs font-GilroyMedium">Streaks</p>
                      <p className="text-3xl font-GilroyBold">1</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-6 mb-1">
                    <div>
                      <img src={workOutImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <p className="text-xs font-GilroyMedium">Workout</p>
                      <p className="text-3xl font-GilroyBold">1/5</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-6 mb-1">
                    <div>
                      <img src={timmerimage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <p className="text-xs font-GilroyMedium">Minutes</p>
                      <p className="text-3xl font-GilroyBold">150</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-6 mb-1">
                    <div>
                      <img src={caloriesImage} alt="" className="w-12 h-12" />
                    </div>
                    <div>
                      <p className="text-xs font-GilroyMedium">Calories</p>
                      <p className="text-3xl font-GilroyBold">3536</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border-fcGray4 mb-5" />
              <div className="bg-white p-4 rounded-xl mb-5">
                <div className="flex justify-between mb-5">
                  <div>
                    <p className="text-xl leading-10 tracking-wide font-GilroySemiBold">
                      My Progress
                    </p>
                    <p className="text-sm text-fcGray5 font-GilroyMedium">
                      Oct 31 - Nov 6
                    </p>
                  </div>
                  <ArrowAngleRight />
                </div>
                <div>
                  <div className="flex space-x-6 mb-2">
                    <div className="flex items-center justify-center">
                      <div className="p-2 bg-fcPrimary rounded-full">
                        <p className="text-base bg-white px-2 py-3 rounded-full font-GilroyBold ">
                          76%
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-base font-GilroySemiBold text-fcGray5">
                        Discipline
                      </p>
                      <p className="text-sm  font-GilroyMedium text-fcGray5">
                        1/4 tasks
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-6">
                    <div className="flex items-center justify-center">
                      <div className="p-2 bg-fcPrimary rounded-full">
                        <p className="text-base bg-white px-2 py-3 rounded-full font-GilroyBold ">
                          76%
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-base font-GilroySemiBold text-fcGray5">Goal</p>
                      <p className="text-sm text-fcGray5 font-GilroyMedium">
                        10/15 kg lost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <Button
                  onClick={() => setInvite(true)}
                  className=" w-full h-12 border border-fcGray4 flex items-center p-4 rounded-full hover:bg-fcGray4"
                >
                  <InviteIcon className="" />
                  <span className="text-base font-GilroySemiBold ml-10 text-fcGray5">
                    Invite
                  </span>
                </Button>
                <Modal show={invite}>
                  <main>
                    <nav className="flex items-center justify-between mb-6">
                      <p className="text-xl font-GilroyBold">Share this…</p>
                      <CloseIcon
                        onClick={() => setInvite(false)}
                        className="cursor-pointer"
                      />
                    </nav>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <CopyLinkIcon />
                        <span className="text-base font-GilroySemiBold">
                          Copy Link
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <WhiteEmail />
                        <span className="text-base font-GilroySemiBold">
                          Email
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <SmsIcon />
                        <span className="text-base font-GilroySemiBold">
                          messages
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <WhatsappIcon />
                        <span className="text-base font-GilroySemiBold">
                          Email
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <Messenger />
                        <span className="text-base font-GilroySemiBold">
                          Copy Link
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <FacebookIconWithBg />
                        <span className="text-base font-GilroySemiBold">
                          Copy Link
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 border border-fcGray4 p-3 rounded-xl">
                        <TwitterIcon />
                        <span className="text-base font-GilroySemiBold">
                          Copy Link
                        </span>
                      </div>
                    </div>
                  </main>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

  );
};

export default Dashboard;
