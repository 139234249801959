import React from "react";
import Button from "../../../components/common/Button";
import profileImage from "../../../assets/profile-img.png";

const FriendRequest = () => {
  return (
    <div>
      <div className="p-4">
        <h1 className="text-xl font-GilroySemiBold mb-4">2 friend Requests</h1>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <div className="flex gap-2 ">
            <Button className="bg-primary text-white px-6 py-2 rounded-full">
              Confirm
            </Button>
            <Button className="bg-[#EE501426] text-black px-6 py-2 rounded-full">
              Delete
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <div className="flex gap-2 ">
            <Button className="bg-primary text-white px-6 py-2 rounded-full">
              Confirm
            </Button>
            <Button className="bg-[#EE501426] text-black px-6 py-2 rounded-full">
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriendRequest;
