import React from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";



const Onboarding5 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step4">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">Your height</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              We will use it to personalize your fitness plan
            </p>
          </div>
          <div className="flex justify-center items-center pt-4 text-[13px] w-[460px]">
            <div className="bg-[#fce5dc] flex rounded-md py-[2px] px-1">
            <p className="bg-primary text-white px-1 rounded-md ">FT</p>
            <p className=" mr-4 ">CM</p>
            </div>
          
          </div>
          <div className="py-4 mx-auto items-center justify-center gap-8  flex ">
            <div className="">
              <input className="mx-auto w-10 text-3xl  border-none text-center focus:outline-0 " />
              <div className="flex  gap-2 relative">
              <hr className="w-14 mx-auto opacity-1 h-1 text-fcGray4" />
              <p className="text-xl absolute bottom-1 left-[55px] ">ft</p>
              </div>
            </div>
            <div>
              <input
                className="mx-auto w-10 text-3xl  border-none text-center  focus:outline-0"
                placeholder="0"
              />
               <div className="flex  gap-2 relative">
              <hr className="w-14 mx-auto opacity-1 h-1 text-fcGray4" />
              <p className="text-xl absolute bottom-1 left-[55px] ml-2">in</p>
              </div>
             
            </div>
          </div>
          <p className="text-xs font-GilroyRegular text-center">
            Please, enter a value from <span className="font-bold">3 ft</span>{" "}
            to <span className="font-bold">7 ft 11</span> in
          </p>
          <a href="/onboard/step6">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding5;
