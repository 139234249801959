import React from "react";
import CloseIcon from "../../../components/icons/CloseIcon";

const AttachFile = ({ selectFile, setSelectFile }) => {
  return (
    <div className="bg-fcGray6 mt-4">
      <div className="grid grid-cols-3 gap-4">
        {selectFile &&
          selectFile.map((file, uid) => (
            <div key={uid} className="relative">
              <img src={file} alt="" className="w-72 h-60 rounded-xl" />
              <div className="absolute top-4 left-8 bg-fcGray6 p-1 rounded-full cursor-pointer">
                <CloseIcon onClick={() =>setSelectFile(selectFile.filter((e) => e !== file))} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AttachFile;
