import React, {useState} from 'react'
import AddUserIcon from "../../components/icons/AddUserIcon";
import Button from "../../components/common/Button";
import profileImage from "../../assets/profile-img.png";

function UserCardMax() {
      const [addToCliq, setAddToCliq] = useState(false);

  return (
      <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
          <div className="flex items-center space-x-3">
              <div>
                  <img
                      src={profileImage}
                      alt=""
                      className="h-12 w-12"
                  />
              </div>
              <div>
                  <p className="text-base font-GilroyMedium">
                      Adeshina Adedapo
                  </p>
                  <p className="text-sm font-GilroyRegular text-fcGray">
                      Lekki, Nigeria
                  </p>
              </div>
          </div>
          <Button  className={`flex items-center justify-center py-1 px-3 rounded-full ${addToCliq ? 'bg-fcPrimary text-white' : 'bg-fcGray4'}`}>
              <AddUserIcon />
              <span className={`${addToCliq ? 'hidden' : 'block'}`}>Add</span><span className={`${addToCliq ? 'block' : 'hidden'}`}>Invited</span>
          </Button>
      </div>
  )
}

export default UserCardMax