export function Modals({children}) {
    return (
        <div className="relative z-40 ">
            <div className="fixed inset-0 bg-fcGray" aria-hidden="true"/>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="flex min-h-full items-center justify-center">
                    {children}
                </div>
            </div>
        </div>
    );
}
