import React from "react";

const SmallGlobalIcon = (className) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        className={className}
      >
        <g
          id="vuesax_bold_global"
          data-name="vuesax/bold/global"
          transform="translate(-172 -252)"
        >
          <path
            id="Vector"
            d="M3.825,3.808c-.025,0-.058.017-.083.017A8.338,8.338,0,0,1,0,.083C0,.058.017.025.017,0A26.577,26.577,0,0,0,3.125.7,27.957,27.957,0,0,0,3.825,3.808Z"
            transform="translate(174.55 265.617)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M3.875.092A8.411,8.411,0,0,1,0,3.9,25.469,25.469,0,0,0,.758.7a26.067,26.067,0,0,0,3.1-.7C3.85.033,3.875.067,3.875.092Z"
            transform="translate(185.575 265.617)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M3.942,3.942A26.369,26.369,0,0,0,.758,3.183,23.262,23.262,0,0,0,0,0,8.377,8.377,0,0,1,3.942,3.942Z"
            transform="translate(185.575 254.483)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M3.892.025a26.04,26.04,0,0,0-.692,3.1,24.058,24.058,0,0,0-3.2.75A8.411,8.411,0,0,1,3.808,0C3.833,0,3.867.025,3.892.025Z"
            transform="translate(174.483 254.55)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M5.817,3.825A26.107,26.107,0,0,0,0,3.825,23.644,23.644,0,0,1,.85.442,1.363,1.363,0,0,0,.867.258,8.671,8.671,0,0,1,2.908,0,8.6,8.6,0,0,1,4.942.258a1.384,1.384,0,0,0,.025.183A23.926,23.926,0,0,1,5.817,3.825Z"
            transform="translate(179.092 253.667)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M3.825,5.817a23.369,23.369,0,0,1-3.383-.85A1.363,1.363,0,0,0,.258,4.95,8.671,8.671,0,0,1,0,2.908,8.6,8.6,0,0,1,.258.875,1.384,1.384,0,0,0,.442.85,24.809,24.809,0,0,1,3.825,0,27.145,27.145,0,0,0,3.825,5.817Z"
            transform="translate(173.667 259.092)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-7"
            data-name="Vector"
            d="M3.825,2.908A8.671,8.671,0,0,1,3.567,4.95a1.363,1.363,0,0,0-.183.017A25.119,25.119,0,0,1,0,5.817,26.107,26.107,0,0,0,0,0,23.644,23.644,0,0,1,3.383.85a.694.694,0,0,0,.183.025A8.657,8.657,0,0,1,3.825,2.908Z"
            transform="translate(186.508 259.092)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-8"
            data-name="Vector"
            d="M5.817,0a23.369,23.369,0,0,1-.85,3.383,1.384,1.384,0,0,0-.025.183,8.6,8.6,0,0,1-2.033.258A8.671,8.671,0,0,1,.867,3.567,1.363,1.363,0,0,0,.85,3.383,24.809,24.809,0,0,1,0,0,26.075,26.075,0,0,0,2.908.183,25.95,25.95,0,0,0,5.817,0Z"
            transform="translate(179.092 266.508)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-9"
            data-name="Vector"
            d="M6.469,6.469a25.033,25.033,0,0,1-6.272,0A25.033,25.033,0,0,1,.2.2,25.033,25.033,0,0,1,6.469.2,25.033,25.033,0,0,1,6.469,6.469Z"
            transform="translate(178.667 258.667)"
            fill="rgba(42,46,67,0.8)"
          />
          <path
            id="Vector-10"
            data-name="Vector"
            d="M0,0H20V20H0Z"
            transform="translate(172 252)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-11"
            data-name="Vector"
            d="M0,0H20V20H0Z"
            transform="translate(192 272) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </svg>
    </>
  );
};

export default SmallGlobalIcon;
