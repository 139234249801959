import React from "react";

const ArrowAngleRight = ({ className, onClick, dimension=[] }) => {
  const width = dimension[0];
  const height = dimension[1];
  // console.log(width)
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${width ? width : '16'}`}
        height={`${height ? height : '16'}`}
        viewBox={`0 0 16 16`}
        className={className}
      >
        <g
          id="vuesax_linear_arrow-right"
          data-name="vuesax/linear/arrow-right"
          transform="translate(-300 -252)"
        >
          <g id="arrow-right" transform="translate(300 252)">
            <path
              id="Vector"
              d="M0,10.56,4.347,6.213a1.324,1.324,0,0,0,0-1.867L0,0"
              transform="translate(5.94 2.72)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H16V16H0Z"
              transform="translate(16 16) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default ArrowAngleRight;
