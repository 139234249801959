import React, {useState} from "react";
import { HiOutlineX } from "react-icons/hi";
import uploadcover from "../../../assets/uploadcover.svg";
import Modal2 from "./Modal2";

export default function Modal({ setOpenModal }) {
  const [showModal, setShowModal] = useState(false);
  

  
  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-50"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg mx-auto bg-white rounded-md shadow-lg ">
            <div className="">
              <div className=" bg-white shadow-sm  items-center justify-between  flex p-2">
                <p className="font-bold text-sm">Add Workout</p>
                <HiOutlineX
                  onClick={() => setOpenModal(false)}
                  className="text-sm"
                />
              </div>

              <div className="bg-[#E4E4E4] items-center justify-center w-full py-12  cursor-pointer">
                <img
                  src={uploadcover}
                  alt="uploadcover"
                  className="items-center mx-auto"
                />
                <p className="text-center">Upload Cover</p>
              </div>
              <div className="mx-2">
                <input
                  name="name"
                  type="name"
                  placeholder="Name"
                  className="form-control mt-4  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <textarea
                  name="Description"
                  type="Description"
                  placeholder="Description"
                  className="form-control mt-2  w-full  h-20 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <input
                  name="Time"
                  type="text"
                  placeholder="Time"
                  className="form-control mt-2  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <input
                  name="name"
                  type="name"
                  placeholder="Intensity"
                  className="form-control mt-2  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <input
                  name="name"
                  type="name"
                  placeholder="Target Areas"
                  className="form-control mt-2  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <input
                  name="name"
                  type="name"
                  placeholder="Categories"
                  className="form-control mt-2  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
                <input
                  name="name"
                  type="name"
                  placeholder="Equipment"
                  className="form-control mt-2  w-full  h-10 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                />
              </div>
              <div className="flex justify-between">
                <div></div>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="text-[15px] bg-black text-white rounded-full mr-2 px-3  my-2"
                >
                  Next
                </button>
                {showModal && <Modal2 setOpenModal={setShowModal} />}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
