import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoIcon from '../components/icons/LogoIcon';
import ArrowAngleRightFilled from '../components/icons/ArrowAngleRightFilled';
import ArrowRightFilled from '../components/icons/ArrowRightFilled';
import homeImageOne from "../assets/home-img-1.png";
import homeImageTwo from "../assets/home-img-2.png";
import homeImageThree from "../assets/home-img-3.png";
import homeImageFour from "../assets/home-img-4.png";
import homeImageFive from "../assets/home-img-5.png";
import homeImageSix from "../assets/home-img-6.png";
import homeImageSeven from "../assets/home-img-7.png";
import homeImageEight from "../assets/home-img-8.png";
import homeImageNine from "../assets/home-img-9.png";
import homeImageTen from "../assets/img/6.png";
import App from "../assets/img/app.png";
import PlayStore from "../assets/img/play_store.png";
import AppStore from "../assets/img/app_store.png";
import Connect from "../assets/img/connect.png";
import Facebook from "../assets/img/facebook-logo.png";
import Twitter from "../assets/img/twitter-logo.png";
import IG from "../assets/img/instagram-logo.png";
import igorange from "../assets/img/instagram-logo-orange.png";
import Logo from "../assets/img/logo-white.png";
import FooterImg from "../assets/img/sarah-cervantes-H8WTALuL7KM-unsplash.png";
import homeImageTen2 from "../assets/home-img-10.png";
import homeImageEleven from "../assets/home-img-11.png";
import homeImageTwelve from "../assets/home-img-12.png";
import homeImageThirteen from "../assets/home-img-13.png";
import LongWhiteArrowDown from "../components/icons/LongWhiteArrowDown";
import homeImageSectionThree from "../assets/img/pexels-mart-production-8032762@2x.png";
import DownloadApp from '../components/common/DownloadApp';

import Layout from "../layout";




function Home() {
    const [cardOne, setCardOne] = useState(false);
    const [trackCard, setTrackCard] = useState(true);
    let [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        const cardTracker = () => {
            if (!cardOne)
                setTrackCard(true);

        };
        cardTracker();
    }, [cardOne, trackCard]);

    useEffect(() => {
        setIsOpen(true);
    }, [])


    return (
        <Layout>
            <DownloadApp open={isOpen}  />
            <section className=" flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar ">
                <div className="relative flex items-center">
                    <div className="w-[420px] px-20 mt-20">
                        <h2 className="font-GilroyBold text-3xl mt-32 ">WELCOME TO THE HEALTHIEST, HAPPIEST YOU</h2>
                        <img src={homeImageOne} alt="" className="rounded-2xl w-[420px] h-[350px]" />
                        <div className="mt-12">
                            <p className='text-xs font-GilroyRegular mb-4'>SCROLL DOWN</p>
                            <div className='bg-fcPrimary rounded-full flex items-center justify-center w-24 h-24 '>
                                <LongWhiteArrowDown />
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={homeImageTwo} alt="" className='w-[620px] h-[420px] rounded-2xl' />
                        <div className='flex -ml-32 mt-10 space-x-10'>
                            <div className='w-[420px] h-[92px]'>
                                <p className="font-GilroyRegular text-xl">Join a Community, Discover Mutual Interests, Set fitness goals and shop your favorite fitness kits.</p>
                            </div>
                            <img src={homeImageNine} alt="" className='w-[202px] h-[160px] rounded-2xl' />
                        </div>
                    </div>
                    <div className="p-10">
                        <img src={homeImageSix} alt="" className='w-[242px] h-[160px] rounded-2xl ' />
                        <img src={homeImageSeven} alt="" className='w-[360px] h-[420px] rounded-2xl mt-20' />
                    </div>
                    <div>
                        <img src={homeImageEight} alt="" className='w-[350px] h-[420px] rounded-2xl' />
                    </div>
                    <div className='absolute top-50'>
                        <h1 className="font-GilroyBold text-[160px] text-fcPrimary">Fitness. Community. Shop.</h1>
                    </div>
                </div>
            </section>
            <section className="px-20">
                <div className="w-[832px] mt-36">
                    <h2 className="font-GilroyBold text-6xl">
                        Get FitCliq. Get Results. Achieve the healthiest, happiest you with FitCliq.
                    </h2>
                </div>
                <div className="flex space-x-4 mt-16">
                    <div className={`relative flex flex-col justify-end items-center hover:${!cardOne ? setCardOne(true) : ''}`}>
                        <img src={homeImageThree} alt="" className='h-[698px] w-[417px] rounded-2xl ' />
                        <div className="absolute flex flex-col items-center justify-center pb-12 text-white text-center">
                            <p className="font-GilroyBold text-6xl">Body Data Insights</p>
                            <p className={`${trackCard ? 'hidden' : ''} text-base font-GilroyRegular px-12`}>All-in-one health & fitness tracker: steps, activity, heart rate, sleep, quality, calories burned and more!</p>
                        </div>
                    </div>
                    <img src={homeImageFour} alt="" className='h-[698px] w-[417px] rounded-2xl' />
                    <img src={homeImageFive} alt="" className='h-[698px] w-[417px] rounded-2xl ' />

                </div>
            </section>
            <section className="mt-[240px] relative">
                <div className="">
                    <img src={homeImageTen} alt="" className="bg-black opacity-86" />
                </div>
                <div className="bg-black opacity-50 absolute w-full h-full -mt-[900px]"></div>
                <div className="tabs absolute flex justify-between -mt-16 font-GilroyRegular text-base bg-black opacity-30 h-16 z-10">
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer z-15 opacity-none ">
                        <Link to="">HIIT </Link>
                        <ArrowAngleRightFilled />
                    </div>
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer  ">
                        <Link to="">Cardio </Link>
                        <ArrowAngleRightFilled />
                    </div>
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer  ">
                        <Link to="">Strength </Link>
                        <ArrowAngleRightFilled />
                    </div>
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer  ">
                        <Link to="">Yoga </Link>
                        <ArrowAngleRightFilled />
                    </div>
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer  ">
                        <Link to="">Barre </Link>
                        <ArrowAngleRightFilled />
                    </div>
                    <div className="flex justify-between items-center text-white  w-60 px-8 py-2 hover:border-l-2 hover:border-fcPrimary hover:cursor-pointer ">
                        <Link to="">Calisthenics </Link>
                        <ArrowAngleRightFilled />
                    </div>

                </div>

            </section>
            <section className="px-20 mt-[210px] flex items-center justify-between">
                <div className="">
                    <img src={homeImageSectionThree} alt="" className=" rounded-2xl" />
                </div>
                <div className="ml-[120px]">
                    <h1 className="text-6xl font-GilroyBold mt-24">
                        Body Data Insights
                    </h1>
                    <div className="mt-[48px]">
                        <h3 className="text-2xl font-GilroySemiBold">
                            Knowledge is power, which is why we’re obsessed with numbers and data. From how well you sleep to how many steps per day you get, your BetterMe band will track it all.
                        </h3>
                        <ul className="list-disc mt-8 font-GilroyRegular text-lg space-y-6">
                            <li>
                                All-in-one health & fitness tracker: steps, activity, heart rate, sleep, quality, calories burned and more!
                            </li>
                            <li>
                                Takes personalization to a whole new level by syncing up your body data with the app.
                            </li>
                            <li>
                                Ensures spot-on recommendations according to your continually updated personal health & fitness profile.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="px-20 mt-[120px] flex flex-row-reverse items-center justify-between">
                <div className="">
                    <img src={homeImageSectionThree} alt="" className=" rounded-2xl" />
                </div>
                <div className="mr-[120px]">
                    <h1 className="text-6xl font-GilroyBold mt-24">
                        Body Data Insights
                    </h1>
                    <div className="mt-[48px]">
                        <h3 className="text-2xl font-GilroySemiBold">
                            Knowledge is power, which is why we’re obsessed with numbers and data. From how well you sleep to how many steps per day you get, your BetterMe band will track it all.
                        </h3>
                        <ul className="list-disc mt-8 font-GilroyRegular text-lg space-y-6 ">
                            <li>
                                All-in-one health & fitness tracker: steps, activity, heart rate, sleep, quality, calories burned and more!
                            </li>
                            <li>
                                Takes personalization to a whole new level by syncing up your body data with the app.
                            </li>
                            <li>
                                Ensures spot-on recommendations according to your continually updated personal health & fitness profile.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="px-20 mt-[120px] flex items-center justify-between">
                <div className="">
                    <img src={homeImageSectionThree} alt="" className=" rounded-2xl" />
                </div>
                <div className="ml-[120px]">
                    <h1 className="text-6xl font-GilroyBold mt-24">
                        Body Data Insights
                    </h1>
                    <div className="mt-[48px]">
                        <h3 className="text-2xl font-GilroySemiBold">
                            Knowledge is power, which is why we’re obsessed with numbers and data. From how well you sleep to how many steps per day you get, your BetterMe band will track it all.
                        </h3>
                        <ul className="list-disc mt-8 font-GilroyRegular text-lg space-y-6">
                            <li>
                                All-in-one health & fitness tracker: steps, activity, heart rate, sleep, quality, calories burned and more!
                            </li>
                            <li>
                                Takes personalization to a whole new level by syncing up your body data with the app.
                            </li>
                            <li>
                                Ensures spot-on recommendations according to your continually updated personal health & fitness profile.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative mt-[240px]  flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar">
                <div>
                    <div className="flex items-center justify-between space-x-24">
                        <img src={homeImageTen2} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                        <img src={homeImageEleven} alt="" className='w-[242px] h-[260px] rounded-2xl ' />
                        <img src={homeImageTwelve} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                        <img src={homeImageThirteen} alt="" className='w-[242px] h-[260px] rounded-2xl ' />
                        <img src={homeImageSix} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                    </div>
                    <div className="absolute top-10 flex flex-col ml-32">
                        <h1 className="text-[260px] text-fcPrimary font-GilroyHeavy">
                            <span className="text-white">
                                #
                            </span>FITCLQ
                        </h1>
                        <div className="flex space-x-3 -mt-24 text-white">
                            <img src={igorange} alt="Connect with us" className="" />
                            <p >
                                FITCLIQ
                            </p>
                        </div>
                    </div>
                </div>

            </section>
            <section className="flex flex-col justify-center items-center mt-[240px] mx-auto container">

                <div className="w-[350px] h-[156px]">
                    <h3 className="text-[40px] font-GilroyBold text-center">
                        Immediate and far-reaching fitness benefits
                    </h3>
                </div>
                <img src={App} alt="" className='w-[765px] h-693px] rounded-2xl mt-[96px]' />
                <h3 className="text-6xl font-GilroySemiBold text-center mt-[107px]">
                    Download for iOS/Android.
                </h3>
                <div className="flex space-x-5 mt-12">
                    <div className='flex items-center justify-between bg-primary text-white rounded-full px-6 py-3 space-x-2'>
                        <span><img src={PlayStore} alt="PlayStore" className="text-white w-[113px] h-[30px]" /></span>
                    </div>
                    <div className='flex items-center justify-between bg-fcPrimary text-white rounded-full px-6 py-3 space-x-2'>
                        <span><img src={AppStore} alt="PlayStore" className="text-white w-[113px] h-[30px]" /></span>
                    </div>
                </div>

            </section>
            <section className="flex  mt-[148px]  flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar">
                <div className="flex justify-between space-x-4 font-GilroyBold text-[160px] text-fcPrimary">
                    <span>Discover.</span>
                    <span> Workout</span>
                    <span> Plans.</span>
                    <span>Challenges.</span>
                    <span> Cliqs. </span>
                    <span>Fitness</span>
                    <span>Journey.</span>
                </div>
            </section>
            
        </Layout>
    );
}

export default Home;