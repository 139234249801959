import React, { useState } from "react";
import profileImage from "../../../assets/profile-img.png";
import FieldInput from "../../../components/common/FieldInput";
import HorrizontalDottedIcon from "../../../components/icons/HorrizontalDottedIcon";
import OrangeGallery from "../../../components/icons/OrangeGallery";
import SendIcon from "../../../components/icons/SendIcon";
import StorageIcon from "../../../components/icons/StorageIcon";
import MessageRecieved from "./MessageRecieved";
import MessageSent from "./MessageSent";
import DropdownModal from "../../../components/dropdown/DropdownModal";
import PinMessageIcon from "../../../components/icons/PinMessageIcon";
import SlashVolumeIcon from "../../../components/icons/SlashVolumeIcon";
import TrashIcon from "../../../components/icons/TrashIcon";
import AttachFile from "./AttachFile";

const NewMessage = ({ className }) => {
  const [showDropDownModal, setShowDropDownModal] = useState(false);
  const [selectFile, setSelectFile] = useState([])

  const handleselectedFile = (e) => {
      const selectedFile = e.target.files;
      const selectedFileArray = Array.from(selectedFile)
      const fileArray = selectedFileArray.map((file) => {
        return URL.createObjectURL(file)
      })
      // console.log(selectedFileArray);
      // console.log(fileArray);
      setSelectFile([...selectFile, fileArray])
      console.log(selectFile)
  }
  return (
    <div className={className}>
      <nav className=" flex items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <img src={profileImage} alt="" className="w-9 h-9 rounded-full" />
          <h1 className="text-xl font-GilroySemiBold">Tom Hardy</h1>
        </div>
        <div className=" hover:bg-fcGray6 p-2 rounded-full">
          <HorrizontalDottedIcon
            onClick={() => setShowDropDownModal(!showDropDownModal)}
            className="cursor-pointer"
          />
          <DropdownModal
            show={showDropDownModal}
            className="absolute right-4 top-12 z-50 bg-white rounded-xl shadow-md"
          >
            <main className="">
              <div className="flex items-center space-x-3 mb-3 cursor-pointer">
                <PinMessageIcon />
                <p className="text-base font-GilroyMedium">Pin conversation</p>
              </div>
              <div className="flex items-center space-x-3 mb-3 cursor-pointer">
                <SlashVolumeIcon />
                <p className="text-base font-GilroyMedium">
                  Snooze conversation
                </p>
              </div>
              <div className="flex items-center space-x-3 mb-3 text-fcPrimary cursor-pointer">
                <TrashIcon />
                <p className="text-base font-GilroyMedium">
                  Delete conversation
                </p>
              </div>
            </main>
          </DropdownModal>
        </div>
      </nav>
      <main className="px-4 pt-20 h-[74vh] overflow-y-scroll scroll-smooth no-scrollbar">
        <div className="">
          <MessageSent />
          <MessageRecieved />
          <AttachFile selectFile={selectFile} setSelectFile={setSelectFile} />
        </div>
      </main>
      <footer className=" px-4 py-2 bottom-1">
        <div className="flex items-center space-x-2 rounded-full p-2 bg-fcGray6">
          <div className=" flex items-center space-x-2">
            <label htmlFor="file" className="">
              <OrangeGallery className="h-5 w-5 cursor-pointer" />
              <input
                onChange={handleselectedFile}
                id="file"
                type="file"
                accept=".jpg, .jpeg, .png"
                className="hidden"
                multiple
              />
            </label>
            <StorageIcon className="h-5 w-5 cursor-pointer" />
          </div>
          <div className="w-11/12">
            <FieldInput
              placeholder="Start a new message"
              className="border-0 outline-none w-full p-2 bg-transparent"
            />
          </div>
          <div>
            <SendIcon className="h-5 w-5 cursor-pointer" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NewMessage;
