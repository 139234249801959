import React from "react";
import SideBar from "../sidebar/SideBar";
import { BiRightArrow, BiShareAlt } from "react-icons/bi";
import LongArrowLeftIcon from "../../../components/icons/LongArrowLeftIcon";
import HorrizontalDottedIcon from "../../../components/icons/HorrizontalDottedIcon";
import cliqfreakheroImage from "../../../assets/home-img-1.png";
import { Link } from "react-router-dom";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import profileImage from "../../../assets/profile-img.png";
import FieldInput from "../../../components/common/FieldInput";
import PeopleIcon from "../../../components/icons/PeopleIcon";
import LikeIcon from "../../../components/icons/LikeIcon";
import MessageIcon from "../../../components/icons/MessageIcon";
import SmallGlobalIcon from "../../../components/icons/SmallGlobalIcon";
import EyeIcon from "../../../components/icons/EyeIcon";
import { useState } from "react";
import onebar from "../../../assets/onebar.svg";
import twobar from "../../../assets/twobar.svg";
import fullbar from "../../../assets/fullbar.svg";
import hotbody from "../../../assets/hotbody.png";
import Abburner from "../../../assets/Ab-burner.png";
import fullbody from "../../../assets/fullbody.png";
import Quickcore from "../../../assets/Quick-core.png";
import PlayIcon from "../../../assets/icons/PlayIcon";
import BoldArrowUpRed from "../../../components/icons/BoldArrowUpRed";
import BoldArrowDownGreen from "../../../components/icons/BoldArrowDownGreen";

const ChallengeLeaderBoard = () => {
    const leaderBoardUsers = [
        {
            name: 'You',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
        {
            name: 'Adeshina Adedapo',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowDownGreen />
        },
      
        {
            name: 'Mary Johnson',
            profile: profileImage,
            location: 'Lagos',
            duration: '50s',
            total: '856',
            icon: <BoldArrowUpRed />
        },
    ]
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85% ml-[15%] h-screen bg-fcGray6 px-60 py-20">
          <div>
            <nav className="bg-fcGray6 mb-10 py-5  z-50">
              <div className="flex items-center space-x-3">
                <div className="bg-white p-2 rounded-full">
                  <LongArrowLeftIcon className=" cursor-pointer" />
                </div>
                <h1 className="text-2xl font-GilroySemiBold">Leaderboard</h1>
              </div>
            </nav>
            <div className="bg-fcWhite rounded-xl h-[calc(100vh-200px)]">
                <div className="overflow-y-scroll h-[77vh] scroll-smooth whitespace-nowrap no-scrollbar p-4">
                    {
                        leaderBoardUsers.map((user, uid) => (
                            <div key={uid} className="grid grid-cols-4  py-3">
                                <div className="flex items-start space-x-2">
                                    <p>{uid + 1}</p>
                                    <div>{user.icon}</div>
                                </div>
                                <div className="flex items-center justify-start  space-x-2">
                                    <img src={user.profile} alt="" className="w-9 h-9 rounded-full" />
                                    <p className="text-base font-GilroyMedium">{user.name}</p>
                                </div>
                                <p className="text-xs font-GilroyMedium text-center"><span>{user.location}</span><span> . {user.duration}</span></p>
                                <p className="text-base font-GilroySemiBold text-end">{user.total}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChallengeLeaderBoard;
