import React from "react";
import Button from "../../../components/common/Button";
import emailbgImage from "../../../assets/img/emailbg-3.png";
import RatingFilledIcon from "../../../components/icons/RatingFilledIcon";
import RatingEmptyIcon from "../../../components/icons/RatingEmptyIcon";
import CallingIcon from "../../../components/icons/CallingIcon";
import MapIcon from "../../../components/icons/MapIcon";

const GymSearch = () => {
  return (
    <div className="p-4">
      <div>
        <div className="first-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">
            Fitness Central Lekki
          </h2>
          <p className="text-base font-GilroyRegular text-fcGray4">
            Adekola Balogun Street, Prechstone & Graeys Cl, lekki
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <MapIcon className='mr-1' />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <CallingIcon className='mr-1' />
              Call
            </Button>
          </div>
        </div>
        <div className="second-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">Skyfit Lagos</h2>
          <p className="text-base font-GilroyRegular text-fcGray4">
            4th Floor, Lagos Oriental Hotel, 3 Lekki - Epe Wxpy, Victoria Island
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center p-2 rounded-full border border-fcPrimary">
              <MapIcon className='mr-1' />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center p-2 rounded-full border border-fcPrimary">
              <CallingIcon className='mr-1' />
              Call
            </Button>
          </div>
        </div>
        <div className="first-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">
            Fitness Central Lekki
          </h2>
          <p className="text-base font-GilroyRegular text-fcGray4">
            Adekola Balogun Street, Prechstone & Graeys Cl, lekki
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <MapIcon className='mr-1' />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <CallingIcon className='mr-1' />
              Call
            </Button>
          </div>
        </div>
        <div className="second-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">Skyfit Lagos</h2>
          <p className="text-base font-GilroyRegular text-fcGray4">
            4th Floor, Lagos Oriental Hotel, 3 Lekki - Epe Wxpy, Victoria Island
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <MapIcon className='mr-1' />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <CallingIcon className='mr-1' />
              Call
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GymSearch;
