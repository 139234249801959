import React from 'react';
import { Link } from 'react-router-dom';
import LogoIcon from '../components/icons/LogoIcon';
import ArrowRightFilled from '../components/icons/ArrowRightFilled';

const NavBar = () => {
  return (
    <nav className="w-full flex items-center justify-between px-20 py-7 font-GilroyRegular text-base fixed z-50 bg-white mb-40">
      <div className="">
        <LogoIcon />
      </div>
      <div className="flex items-center justify-between ">
        <div className="links flex space-x-14">
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/store">Store</Link>
          <Link to="/blog">Blog</Link>
        </div>
        <Link
          to="/communitylogin"
          className="flex items-center justify-between bg-fcPrimary text-white ml-14 rounded-full px-6 py-4 space-x-2"
        >
          <span className=" ">Join Now</span>
          <span>
            <ArrowRightFilled className="text-white" />
          </span>
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;
