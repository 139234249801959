import React, { useState } from "react";
import profileImage from "../../../assets/profile-img.png";
import messageReadDMarkIcon from "../../../assets/img/messagereadmark.png";
import EmptyBoxMessage from "./EmptyBoxMessage";
import HorrizontalDottedIcon from "../../../components/icons/HorrizontalDottedIcon";
import DropdownModal from "../../../components/dropdown/DropdownModal";
import PinMessageIcon from "../../../components/icons/PinMessageIcon";
import SlashVolumeIcon from "../../../components/icons/SlashVolumeIcon";
import TrashIcon from "../../../components/icons/TrashIcon";

const MessagesList = ({ setSendMessage, sendMessage }) => {
  const [messagesList, setMessagesList] = useState([""]);
  const [showDropDownModal, setShowDropDownModal] = useState(false);
  const [showPinMessages, setShowPinMessages] = useState(false);

  return (
    <div className="py-2">
      <EmptyBoxMessage
        className={` ${messagesList.length <= 0 ? "block" : "hidden"}`}
      />
      <div className={`${showPinMessages ? 'block' : 'hidden'}`}>
        <h1 className="text-xl font-GilroySemiBold p-4">
          Pinned conversations
        </h1>
        <div
          onClick={() => setSendMessage(true)}
          className={`flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4 ${
            sendMessage ? "bg-[#FFF7F5] border-r-[3px] border-fcPrimary" : ""
          }`}
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
      </div>
      <h1  className={`text-xl font-GilroySemiBold p-4 ${showPinMessages ? 'block' : 'hidden'}`}>All conversations</h1>
      <div
        className={`cursor-pointer h-[77vh] flex flex-col whitespace-nowrap overflow-y-scroll scroll-smooth no-scrollbar ${
          messagesList.length > 0 ? "bllock" : "hidden"
        }`}
      >
        <div
          onClick={() => setSendMessage(true)}
          className={`flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4 ${
            sendMessage ? "bg-[#FFF7F5] border-r-[3px] border-fcPrimary" : ""
          }`}
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
            <DropdownModal
              show={showDropDownModal}
              className="absolute right-0 top-5 z-50 bg-white "
            >
              <main className="">
                <div onClick={() =>setShowPinMessages(true)} className="flex items-center space-x-3 mb-3">
                  <PinMessageIcon />
                  <p className="text-base font-GilroyMedium">
                    Pin conversation
                  </p>
                </div>
                <div className="flex items-center space-x-3 mb-3">
                  <SlashVolumeIcon />
                  <p className="text-base font-GilroyMedium">
                    Snooze conversation
                  </p>
                </div>
                <div className="flex items-center space-x-3 mb-3 text-fcPrimary">
                  <TrashIcon />
                  <p className="text-base font-GilroyMedium">
                    Delete conversation
                  </p>
                </div>
              </main>
            </DropdownModal>
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
        <div
          onClick={() => setSendMessage(true)}
          className="flex items-center justify-between mb-4 relative hover:bg-fcGray6 p-4"
        >
          <div className="flex items-center space-x-2">
            <img src={profileImage} alt="" className="w-12 h-12 rounded-full" />
            <div>
              <h1>Tom Hardy</h1>
              <p>Perfect! I’ll check it out</p>
            </div>
          </div>
          <div className="flex items-center space-x-1 relative">
            <img src={messageReadDMarkIcon} alt="" className="w-4 h-4" />
            <p>8 hr</p>
            <HorrizontalDottedIcon
              onClick={() => setShowDropDownModal(!showDropDownModal)}
              className="cursor-pointer"
            />
          </div>
          <div className="absolute h-3 w-3 bg-fcyellow2 rounded-full left-14 bottom-6"></div>
        </div>
      </div>
    </div>
  );
};

export default MessagesList;
