import React, { useState } from "react";
import SideBar from "../sidebar/SideBar";
import addMessageImageIcon from "../../../assets/img/addmessageicon-img.png";
import FieldInput from "../../../components/common/FieldInput";
import SearchIcon from "../../../components/icons/SearchIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import EmptyBoxMessage from "./EmptyBoxMessage";
import EmptyNewMessage from "./EmptyNewMessage";
import MessagesList from "./MessagesList";
import NewMessage from "./NewMessage";

function Messages() {
  const [showSearchMessage, setShowSearchMessage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sendMessage, setSendMessage] = useState(false)
  const [currentMessageStep, setCurrentMessageStep] = useState(1);

  const handleMessageSearch = () => {};
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85%] ml-[15%] h-screen overflow-hidden bg-fcGray6 px-40 py-5">
          <div>
            <nav className="bg-fcGray6 sticky top-0 mb-2 py-5 z-50">
              <div className="w-1/3 flex items-center justify-between pr-14">
                <h1 className="text-2xl font-GilroySemiBold">Messages</h1>
                <div className="bg-white p-2 rounded-full"><img src={addMessageImageIcon} alt="" className="w-5 h-5" /></div>
              </div>
            </nav>
            <div className="flex space-x-1 h-[calc(100vh-110px)]">
              <div className="w-1/3 bg-white rounded-tl-xl">
                <div className="p-4 border-b border-fcGray4 bg-white rounded-t-xl sticky top-0">
                  <div
                    className={` rounded-full bg-fcGray6 flex items-center space-x-2 w-full p-2 ${
                      searchValue === ""
                        ? "border-0"
                        : "border border-fcPrimary"
                    }`}
                  >
                    <div className="">
                      <SearchIcon />
                    </div>
                    <div className="w-full">
                      <FieldInput
                        value={searchValue}
                        onChange={handleMessageSearch}
                        placeholder="Search fitcliq"
                        className="border-0 outline-none bg-transparent w-11/12"
                      />
                    </div>
                    <CloseIcon
                      onClick={() => setSearchValue("")}
                      className={`cursor-pointer ${
                        searchValue === "" ? "hidden" : "block"
                      }`}
                    />
                  </div>
                </div>
                <div className="w-full  mt-1">
                  <MessagesList setSendMessage={setSendMessage} sendMessage={sendMessage} />
                </div>
              </div>
              <div className="w-4/5 bg-white rounded-tr-lg relative">
                <div className="w-full">
                  <div>
                    <EmptyNewMessage setSendMessage={setSendMessage} className={`${sendMessage ? 'hidden' : 'block'}`} />
                    <NewMessage className={`${sendMessage ? 'block' : 'hidden'}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Messages;
