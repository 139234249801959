import React from 'react';

const MessageIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_message-2"
          data-name="vuesax/bold/message-2"
          transform="translate(-492 -316)"
        >
          <g id="message-2" transform="translate(492 316)">
            <path id="Vector" d="M0,0H26V26H0Z" fill="none" opacity="0" />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M16.25,0H5.417A5.118,5.118,0,0,0,0,5.417v6.5a5.118,5.118,0,0,0,5.417,5.417H9.75l4.821,3.207a1.08,1.08,0,0,0,1.679-.9V17.333a5.118,5.118,0,0,0,5.417-5.417v-6.5A5.118,5.118,0,0,0,16.25,0ZM14.625,9.555H7.042a.813.813,0,0,1,0-1.625h7.583a.813.813,0,0,1,0,1.625Z"
              transform="translate(2.167 2.632)"
              fill="#ff603e"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default MessageIcon;
