import React from "react";
import BoldAngleArrowRight from "../../../components/icons/BoldAngleArrowRight";
import profileImageOne from "../../../assets/img/profile-1.png";
import profileImageTwo from "../../../assets/img/profile-2.png";
import profileImageThree from "../../../assets/img/profile-3.png";
import whiteUserIcon from "../../../assets/img/whiteuserIcon.png";
import ChallengeCard from "./ChallengeCard";

const MyChallengeFilled = ({ className }) => {
  return (
    <div className={className}>
      <div className="flex gap-4 items-center flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar cursor-pointer">
        <ChallengeCard />
        <ChallengeCard />
        <ChallengeCard />
        <ChallengeCard />
        <ChallengeCard />
      </div>
    </div>
  );
};

export default MyChallengeFilled;
