import React from "react";
import redlogo from "../../assets/redlogo.png";
import { FcGoogle } from "react-icons/fc";
import OptionButton from "../../components/common/OptionButton";
import ArrowLeft from "../../components/icons/ArrowLeft";
const Onboarding = () => {
  return (
    <div className="w-full">
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center mx-auto w-1/3">
        <div>
          <div>
            <div className="w-full bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">What's your gender?</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              Pick which best describes you
            </p>
          </div>
          <div className="py-6 space-y-4 w-[460px]">
            <OptionButton title=" I am a Man" />
            <OptionButton title=" I am a Woman" />
            <OptionButton title=" I am Non-binary" />
            
          </div>
          <div className="text-center gap-2 flex justify-center items-center">
            <FcGoogle className="text-base" />
            <p className="text-primary text-sm font-GilroyMedium ">
              SYNC WITH GOOGLE FIT
            </p>
          </div>
          <a href="onboard/step1">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding;
