import React from 'react';
import ArrowAngleRight from '../components/icons/ArrowAngleRightFilled';
import LayOut from '../layout';

function Contact() {
    return (
        <LayOut>
            <div className="py-40 ">
                <div className=" px-20 ">
                    <h1 className="text-[127px] text-fcNavy4 font-GilroyBold">
                        Contacts
                    </h1>
                    <div>
                        <div className="flex justify-between items-center border-t border-fcNavy3 py-12  ">
                            <div><p className="text-2xl">Customer Support</p></div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <h3 className="text-[44px] font-GilRoySemiBold ">support@fitcliq.co</h3>
                                </div>
                                <div>
                                    <ArrowAngleRight className="text-black" />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center border-t border-fcNavy3 py-12 ">
                            <div><p className="text-2xl">Customer Support</p></div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <h3 className="text-[44px] font-GilRoySemiBold ">support@fitcliq.co</h3>
                                </div>
                                <div>
                                    <ArrowAngleRight className="text-black" />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center border-t border-fcNavy3 py-12 border-b">
                            <div><p className="text-2xl">Customer Support</p></div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <h3 className="text-[44px] font-GilRoySemiBold ">support@fitcliq.co</h3>
                                </div>
                                <div>
                                    <ArrowAngleRight className="text-black" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </LayOut>
    );
}

export default Contact;