import React from "react";

const ArrowRightFilled = ({className, onClick}) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="vuesax_linear_arrow-right" data-name="vuesax/linear/arrow-right" transform="translate(-620 -188)">
          <g id="arrow-right" transform="translate(620 188)">
            <path id="Vector" d="M0,0,4.047,4.047,0,8.093" transform="translate(9.62 3.953)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            <path id="Vector-2" data-name="Vector" d="M0,0H11.22" transform="translate(2.333 8)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            <path id="Vector-3" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
          </g>
        </g>
      </svg>

    </>
  );
};

export default ArrowRightFilled;
