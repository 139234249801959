import React from 'react';
import LayOut from '../../layout';
import blogImg from "../../assets/img/blog-img-big.png";
import blogImg2 from "../../assets/img/blog-img-big-2.png";
import blogImg3 from "../../assets/img/blog-img-big-3.png";
import Eloho from "../../assets/Eloho.png";

function Post() {
    return (
        <LayOut>
            <div className="py-40 ">
                <div className=" px-20 ">
                    <div className="flex items-center flex-col">
                        <h1 className="text-[72px] text-fcNavy4 font-GilroyBold text-center">
                            Juice It Up! A Guide to Reaping the Health Benefits of Mango Juice
                        </h1>
                        <div className="mt-6 flex space-x-3">
                            <div><img src={Eloho} alt="Blog Img" className="rounded-full " /></div>
                            <div>
                                <p className="text-fcNavy2">Dapo Popsi</p>
                                <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                            </div>
                        </div>
                    </div>
                    <img src={blogImg} alt="" className="mt-16" />
                    <div className="w-[1040px] mx-auto mt-16 font-GilroyRegular text-xl">
                        <p className="mb-6">
                            We all know that the secret to sticking to your exercise routine to attain your fitness goals is motivation. However, sometimes, we may lose the motivation to keep going. This happens when you are feeling tired and sore from your workouts.
                        </p>
                        <p className="mb-6">
                            In a similar way you may lose motivation when you are confused about the right exercises or the correct form. These are among the many reasons you may feel like giving up on your fitness journey.
                        </p>
                        <p className="mb-6">
                            Even so, a significant number have reported a lack of motivation to exercise due to fatigue. As a result they have considered quitting despite not attaining their goals. With this in mind, how do you get the motivation to exercise when tired? Find out here!
                        </p>
                        <h2 className="font-GilroySemiBold text-4xl">
                            How To Get Motivation To Exercise When Tired
                        </h2>
                        <img src={blogImg2} alt="" className="mt-16 w-full" />
                        <img src={blogImg3} alt="" className="mt-12 mb-6" />
                        <p className="mb-6">
                            We all know that the secret to sticking to your exercise routine to attain your fitness goals is motivation. However, sometimes, we may lose the motivation to keep going. This happens when you are feeling tired and sore from your workouts.
                        </p>

                        <h2 className="font-GilroySemiBold text-2xl">
                            READ MORE
                        </h2>
                        <p className="mb-3 text-fcPrimary">
                            Easy Workouts To Lose Weight: 11 Effective Routines To Help You Reach Your Fitness Goals
                        </p>
                        <p className="mb-3 text-fcPrimary">
                            Easy Workouts To Lose Weight: 11 Effective Routines To Help You Reach Your Fitness Goals
                        </p>
                        <p className="mb-3 text-fcPrimary">
                            Easy Workouts To Lose Weight: 11 Effective Routines To Help You Reach Your Fitness Goals
                        </p>
                        <p className="mb-3 text-fcPrimary">
                            Easy Workouts To Lose Weight: 11 Effective Routines To Help You Reach Your Fitness Goals
                        </p>

                        <div className="border-b border-fcGray4 flex justify-between mt-16">
                            <div>
                                <h2 className="font-GilroySemiBold text-2xl">
                                    SHARE ARTICLE
                                </h2>
                            </div>
                            <div>
                                <p> F | In | IG </p>
                            </div>
                        </div>

                    </div>
                    <div className="mt-16">
                        <div>
                            <h2 className="font-GilroySemiBold text-2xl">
                                RELATED ARTICLE
                            </h2>
                        </div>
                        <div className="mt-12 flex flex-wrap gap-5">
                            <div className="w-[410px]">
                                <div>
                                    <img src={blogImg} alt="Blog Img" />
                                </div>
                                <div className="mt-6 flex space-x-2">
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                    <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                    <div className="mt-6 flex space-x-3">
                                        <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                        <div>
                                            <p className="text-fcNavy2">adedapo Popsi</p>
                                            <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="w-[410px]">
                                <div>
                                    <img src={blogImg} alt="Blog Img" />
                                </div>
                                <div className="mt-6 flex space-x-2">
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                    <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                    <div className="mt-6 flex space-x-3">
                                        <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                        <div>
                                            <p className="text-fcNavy2">Dapo Popsi</p>
                                            <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="w-[410px]">
                                <div>
                                    <img src={blogImg} alt="Blog Img" />
                                </div>
                                <div className="mt-6 flex space-x-2">
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                    <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                    <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                    <div className="mt-6 flex space-x-3">
                                        <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                        <div>
                                            <p className="text-fcNavy2">Dapo Popsi</p>
                                            <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayOut>
    );
}

export default Post;