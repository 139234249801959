import { Formik, Form } from "formik";
import React from "react";
// import leftside from "../../../assets/leftside.png";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import logoimg from "../../../assets/logo.svg";
import "../../../App.css";
import EmailIcon from "../../../components/icons/EmailIcon";
import FacebookIcon from "../../../components/icons/FacebookIcon";

const CommunityLogin = () => {
  return (
    <Formik>
      {(formik) => (
        <main className="  flex flex-row gap-4  ">
          {/* <section className="left-wrapper md:w-2/3 w-full bg-primary h-screen ">
            <img
              src={leftside}
              alt="leftside"
              className="h-screen w-full  opacity-25 items-center justify-center flex  mx-auto "
            />
          </section> */}
          <section className="left-wrapper md:w-2/3 w-full bg-primary h-screen ">
            <div id="hero" className="h-screen w-full"></div>

            <div id="leftsideimg">
              <img
                src={logoimg}
                alt="logo"
                className="w-[120px] absolute top-6 left-[100px]"
              />
            </div>
          </section>
          <section className="right  border-lightGray w-1/4  items-center justify-center mx-[80px] my-auto ">
            <div className="">
              <h1 className="text-center text-[#2A2E43] text-[32px] font-GilroyBold py-2">
                Welcome to FitCliq
              </h1>
              <p className="text-center text-[15px] text-[#2A2E43] font-GilroyMedium">
                Join a Community, Discover Mutual Interests,
                <br /> Set fitness goals and shop your favorite
                <br /> fitness kits.
              </p>
            </div>
            <div className="mt-6">
              <Form>
                <div className="mb-2">
                  <div className="my-2">
                    <Link
                      to="/login"
                      className="flex mb-2 opacity-100 border border-fcGray4 mt-4  w-full rounded-full py-2 px-2  cursor-pointer"
                    >
                      <EmailIcon />

                      <p className="text-[16px] mx-auto text-center my-auto ">
                        CONTINUE WITH EMAIL
                      </p>
                    </Link>

                    <button className="flex text-white opacity-100 border border-fcGray4 w-full rounded-full py-2 px-2 mx-auto  cursor-pointer bg-blue-700">
                      <FacebookIcon />
                      <p className="text-[16px] mx-auto my-auto">
                        CONTINUE WITH FACEBOOK
                      </p>
                    </button>
                    <button className="flex opacity-100 border border-fcGray4 mt-2  w-full rounded-full py-2 px-2  cursor-pointer">
                      <FcGoogle className="text-[25px] cursor-pointer" />
                      <p className="text-[16px] text-center mx-auto my-auto ">
                        CONTINUE WITH GOOGLE
                      </p>
                    </button>
                  </div>
                  <div className="py-8">
                    <p className=" text-sm opacity-100 items-center flex justify-center text-gray">
                      Already have an account?{" "}
                      <Link
                        className="text-primary underline text-sm  pl-2"
                        to="/login"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </div>

                <div>
                  <p className="items-center text-center  justify-center mt-6 text-[12px] text-[#2A2E43CC] ">
                    By continuing you accept our
                    <br />
                    <span className="underline px-1 cursor-pointer">
                      {" "}
                      Privacy Policy
                    </span>{" "}
                    and
                    <span className="underline px-1 cursor-pointer">
                      {" "}
                      Terms of use{" "}
                    </span>
                  </p>
                </div>
              </Form>
            </div>
          </section>
        </main>
      )}
    </Formik>
  );
};

export default CommunityLogin;
