import React, { useState } from "react";
import profileImage from "../../../assets/profile-img.png";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import Button from "../../../components/common/Button";
import yogaImage from "../../../assets/img/yogga.png";
import { GrFormCheckmark } from "react-icons/gr";
import CliqCardMax from "../../../components/common/CliqCardMax";

const CliqSearch = () => {
  const [joinCliq, setJoinCliq] = useState(false);
  return (
    <div className="p-4">
      <div>
        <CliqCardMax />
        {/* <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CliqSearch;
