import React from "react";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import SideBar from "../sidebar/SideBar";
import Jane from "../../../assets/Jane.png";
import AccountIcon from "../../../assets/AccountIcon.svg";
import AccountIconWhite from "../../../assets/AccountIconWhite.svg";
import redball from "../../../assets/redball.png";
import Tom from "../../../assets/Tom.png";
import AllCoaches from "./AllCoaches";
import CoachDetails from "./CoachDetails";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <div>
      <SideBar />
      <main className="w-[85%] ml-[15%] bg-fcGray6 px-40 py-5 ">
        <div className="bg-fcGray6 w-full h-screen rounded-3xl">
          <nav className=" p-5 z-50 bg-fcGray6 sticky top-0">
            <p className="text-2xl font-GilroySemiBold text-fcDark">Coaches</p>
          </nav>
          <div className="flex gap-8 mt-2">
            <div className="w-[70%] z-40">
              <Link to="/dashboard/coach/details">
              <div className="bg-white p-4  rounded-2xl">
                {Array(7)
                  .fill(true)
                  .map((_, i) => (
                    <AllCoaches key={i} />
                  ))}
              </div>
              </Link>
              
            </div>

            <div className="w-[18%] h-9/10 fixed ml-[49%]">
              <div className="bg-white p-4 mb-16 rounded-2xl">
                <div className="flex items-center justify-between py-4">
                  <p className="text-sm leading-10 tracking-wide font-GilroyBold">
                    Who to follow
                  </p>
                  <ArrowAngleRight />
                </div>
                <div>
                  <div>
                    <div className="flex gap-4 items-center">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
                      <img
                        src={AccountIcon}
                        alt="accounticon"
                        className="w-[20px]"
                      />
                      <p className="font-GilroyMedium text-[14px]">Add</p>
                    </button>
                  </div>
                </div>
              </div>
              <button className="flex w-full border border-[#B9B9B9] rounded-full items-center pl-4 gap-16 py-4  mt-8 bg-[#F7F7F7] ">
                <img
                  src={AccountIconWhite}
                  alt="accounticon"
                  className="w-[24px]"
                />
                <p className="font-GilroySemiBold text-[16px] text-[#2A2E43]">
                  Invite
                </p>
              </button>
            </div>
          </div>
        </div>
      </main>
     
    </div>
  );
};

export default Index;
