import React from 'react';
import Layout from "../layout";
import Jade from "../assets/img/jared-rice-NTyBbu66_SI-unsplash.png";
import Alex from "../assets/img/alexander-redl-d3bYmnZ0ank-unsplash.png";
import App from "../assets/img/app.png";
import PlayStore from "../assets/img/play_store.png";
import AppStore from "../assets/img/app_store.png";
import igorange from "../assets/img/instagram-logo-orange.png";
import homeImageTen2 from "../assets/home-img-10.png";
import homeImageEleven from "../assets/home-img-11.png";
import homeImageTwelve from "../assets/home-img-12.png";
import homeImageThirteen from "../assets/home-img-13.png";
import homeImageSix from "../assets/home-img-6.png";


function About() {
    return (
        <Layout>
            <div className="py-40 ">
                <div className=" px-20 ">
                    <p className="text-xs text-fcNavy4 font-GilroyBold w-72">EXPERIENCE THE ENERGY AND RESULTS OF REAL TRAINING.</p>
                    <h1 className="text-[127px] text-fcNavy4 font-GilroyBold">
                        About us
                    </h1>
                </div>
                <div>
                    <div className=" px-20">
                        <div className="w-[620px] mb-[80px] GilroyMedium">
                            <p>
                                FitCliq is a wellness ecosystem that takes a bio-individual approach to every single user. On a mission to create a healthier world since 2023, we know how to meet you exactly where you are and take you where you want to be.
                            </p>
                        </div>
                        <img src={Jade} alt="" />
                    </div>
                    <div className="flex justify-between mt-[120px] space-x-24 px-20">
                        <div className=" w-2/5">
                            <h2 className="text-5xl text-fcNavy4 font-GilroyBold">Our Story</h2>
                        </div>
                        <div className="font-GilroyRegular text-xl">
                            <p>
                                Our story is just very simple. We didn’t start fitcliq to become some fancy boutique fitness product. We simply want to give enthusiastic athletes the right tools and guidance to achieve their own goals. That’s all it is.
                            </p>
                            <p>
                                That’s why we don’t tell the standard story about losing weight, stronger muscles and more condition. Feeling stronger, happier, more resilient and more alive. Because yes, you can achieve that. You just have to work for it. It’s not coming up.
                            </p>


                        </div>
                    </div>
                    <section className="flex  mt-[100px]  flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar">
                        <div className="flex justify-between space-x-4 font-GilroyBold text-[160px] text-fcPrimary">
                            <span>Discover.</span>
                            <span> Workout</span>
                            <span> Plans.</span>
                            <span>Challenges.</span>
                            <span> Cliqs. </span>
                            <span>Fitness</span>
                            <span>Journey.</span>
                        </div>
                    </section>
                    <div className="px-20">
                        <div className="w-[600px]">
                            <p className="pb-16 text-3xl">
                                <span className="font-GilroySemiBold text-fcNavy4">Fitcliq promotes a healthy and active lifestyle - no fanatical weight loss or perfect body ideals.</span> <span className="font-GilroyBold text-fcNavy3">Our goal is to teach users to create happiness within.</span>
                            </p>
                        </div>
                        <div className="border-b-2 border-b-fcPrimary">
                            <p className="text-2xl text-fcNavy4 font-GilroyBold">01</p>
                            <div className="flex justify-between items-center pb-20">
                                <div>
                                    <h1 className="text-8xl text-fcNavy4 font-GilroyBold">Regular Exercise</h1>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="rounded-full w-24 h-24 bg-fcPrimary p-5 text-white flex justify-center items-center mr-[73px]">More</span>
                                    <img src={Alex} alt="lace up Alex" />
                                </div>
                            </div>
                        </div>
                        <div className="border-b-2 border-b-fcPrimary mt-[27px]">
                            <p className="text-2xl text-fcNavy4 font-GilroyBold">02</p>
                            <div className="flex justify-between items-center pb-20">
                                <div>
                                    <h1 className="text-8xl text-fcNavy4 font-GilroyBold">PERSONAL TRAINING</h1>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="rounded-full w-24 h-24 bg-fcPrimary p-5 text-white flex justify-center items-center mr-[73px]">More</span>
                                    <img src={Alex} alt="lace up Alex" />
                                </div>
                            </div>
                        </div>
                        <div className="border-b-2 border-b-fcPrimary mt-[27px]">
                            <p className="text-2xl text-fcNavy4 font-GilroyBold">03</p>
                            <div className="flex justify-between items-center pb-20">
                                <div>
                                    <h1 className="text-8xl text-fcNavy4 font-GilroyBold">SPINING</h1>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="rounded-full w-24 h-24 bg-fcPrimary p-5 text-white flex justify-center items-center mr-[73px]">More</span>
                                    <img src={Alex} alt="lace up Alex" />
                                </div>
                            </div>
                        </div>
                        <div className="border-b-2 border-b-fcPrimary mt-[27px]">
                            <p className="text-2xl text-fcNavy4 font-GilroyBold">04</p>
                            <div className="flex justify-between items-center pb-20">
                                <div>
                                    <h1 className="text-8xl text-fcNavy4 font-GilroyBold">GROUP LESSONS</h1>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="rounded-full w-24 h-24 bg-fcPrimary p-5 text-white flex justify-center items-center mr-[73px]">More</span>
                                    <img src={Alex} alt="lace up Alex" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="relative mt-[240px]  flex-nowrap overflow-x-scroll scroll-smooth no-scrollbar">
                    <div>
                        <div className="flex items-center justify-between space-x-24">
                            <img src={homeImageTen2} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                            <img src={homeImageEleven} alt="" className='w-[242px] h-[260px] rounded-2xl ' />
                            <img src={homeImageTwelve} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                            <img src={homeImageThirteen} alt="" className='w-[242px] h-[260px] rounded-2xl ' />
                            <img src={homeImageSix} alt="" className='w-[342px] h-[420px] rounded-2xl ' />
                        </div>
                        <div className="absolute top-10 flex flex-col ml-32">
                            <h1 className="text-[260px] text-fcPrimary font-GilroyHeavy">
                                <span className="text-white">
                                    #
                                </span>FITCLQ
                            </h1>
                            <div className="flex space-x-3 -mt-24 text-white">
                                <img src={igorange} alt="Connect with us" className="" />
                                <p >
                                    FITCLIQ
                                </p>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="flex flex-col justify-center items-center mt-[240px] mx-auto container">

                    <div className="w-[350px] h-[156px]">
                        <h3 className="text-[40px] font-GilroyBold text-center">
                            Immediate and far-reaching fitness benefits
                        </h3>
                    </div>
                    <img src={App} alt="" className='w-[765px] h-693px] rounded-2xl mt-[96px]' />
                    <h3 className="text-6xl font-GilroySemiBold text-center mt-[107px]">
                        Download for iOS/Android.
                    </h3>
                    <div className="flex space-x-5 mt-12">
                        <div className='flex items-center justify-between bg-primary text-white rounded-full px-6 py-3 space-x-2'>
                            <span><img src={PlayStore} alt="PlayStore" className="text-white w-[113px] h-[30px]" /></span>
                        </div>
                        <div className='flex items-center justify-between bg-fcPrimary text-white rounded-full px-6 py-3 space-x-2'>
                            <span><img src={AppStore} alt="PlayStore" className="text-white w-[113px] h-[30px]" /></span>
                        </div>
                    </div>

                </section>
            </div>
        </Layout>
    );
}

export default About;