import React from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding14 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step13">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>
          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">
            How many times per week do you<br/> plan to work out?
            </p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
            Select any of the plan that works for you
            </p>
          </div>
          <div className="py-4 space-y-4 w-[460px]">            
          <p className="text-center opacity-100 border py-2 my-2 border-fcGray4 text-gray-200 text-[14px] w-full rounded-full  px-2 cursor-pointer ">
              2 - 3 times
            </p>
            <p className="text-center opacity-100 border py-2 my-2 border-fcGray4 text-gray-200 text-[14px]  w-full rounded-full  px-2 cursor-pointer ">
              3 - 4 times
            </p>
            <p className="text-center opacity-100 border py-2 my-2 border-fcGray4 text-gray-200 text-[14px]  w-full rounded-full  px-2 cursor-pointer ">
              4 - 5 times
            </p>
          </div>
          <a href="/onboard/step15">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding14;
