import React from 'react';
import Header from '../../../components/common/Header';
import ParagraphText from '../../../components/common/ParagraphText';

const Terms = () => {
  const content = [
    {
      description: '1. Categories of personal data we collect',
    },
    {
      description: '2. For what purposes we process your personal data',
    },
    {
      description:
        '3. Under what legal bases we process your personal data (Applies only to EEA-based users)',
    },
    {
      description: '4. With whom we share your personal data',
    },
    {
      description: '5. How you can exercise your privacy rights',
    },
    {
      description: '6. Age limitation',
    },
    {
      description: '7. International data transfers',
    },
    {
      description: '8. Changes to this privacy policy',
    },
    {
      description: '9. Data retention',
    },
    {
      description: '10. How “do not track” requests are handled',
    },
    {
      description: '11. Personal data controller',
    },
    {
      description: '12. Contact us',
    },
  ];
  return (
    <div>
      <Header />
      <section className="mx-auto w-[1040px]">
        <div>
          <p className="text-8xl font-GilroyBold  text-fcNavy pt-24">
            Terms and conditions of use
          </p>
          <p className="font-GilroySemiBold text-2xl text-fcNavy pt-8">
            Effective date: March 14, 2023
          </p>
        </div>
        <div className="py-24">
          <ParagraphText>
            FitCliq Inc. (“us”, “we”, or “our”) operates the FitCliq web
            application (hereinafter referred to as the “Service”).
            <br />
            <br /> This page informs you of our policies regarding the
            collection, use, and disclosure of personal data when you use our
            Service and the choices you have associated with that data. The
            Privacy Policy for FitCliq is based on theTermsFeed’s Privacy Policy
            Template.
            <br />
            <br /> We use your data to provide and improve the Service. By using
            the Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </ParagraphText>

          <div>
            <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
              Content
            </p>
            {content.map((e, i) => {
              return (
                <div
                  key={i}
                  className=" my-4 font-GilroyMedium text-lg underline text-primary"
                >
                  <p>{e.description}</p>
                </div>
              );
            })}
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                1. Categories of personal data we collect
              </p>
              <ParagraphText>
                We collect data you give us voluntarily (for example, when you
                choose your areas for improvement or send us an email). We also
                may receive data about you from third parties (for example, when
                you sign in via Apple). Finally, we collect data automatically
                (for example, your IP address).
              </ParagraphText>
              <div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.1. Data you give us
                  </p>
                  <ParagraphText>
                    You provide us information about yourself when you register
                    for and/or use the Service. For example: name, age, gender,
                    data on physical characteristics (including height, weight,
                    areas for improvement), fitness level, food preferences
                    (including diet preferences; preferred number of meals,
                    foods you do not like), meditation preferences, food and
                    exercises you log, email address.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.3. Data we collect automatically:
                  </p>
                  <div className="ml-10 my-4">
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        a. Data about how you found us.
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We collect data about your referring app or URL (that
                        is, the app or place on the Web where you were when you
                        tapped on our ad).
                      </p>
                    </div>
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        b. Device and Location data
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We collect data from your mobile device. Examples of
                        such data include: language settings, IP address, time
                        zone, type and model of a device, device settings,
                        operating system, Internet service provider, mobile
                        carrier, hardware ID, and Facebook ID.
                      </p>
                    </div>
                    <div>
                      <p className="text-2xl text-fcNavy2 font-GilroySemiBold ">
                        c. Usage data
                      </p>
                      <p className="text-fcDark text-2xl font-GilroyRegular my-3 ">
                        We record how you interact with our Service. For
                        example, we log your taps on certain areas of the
                        interface, the features, and content you interact with,
                        workouts you do, the time and duration of your workouts,
                        how often you use the App, how long you are in the app,
                        your training program progress, and your subscription
                        orders. We also record the ads in our App with which you
                        interact (and the Internet links to which those adds
                        lead).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                2. For what purposes we process your personal data
              </p>
              <ParagraphText>We process your personal data:</ParagraphText>
              <div>
                <div className=" my-6">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.1. To provide our Service
                  </p>
                  <ParagraphText>
                    This includes enabling you to use the Service in a seamless
                    manner and preventing or addressing Service errors or
                    technical issues.
                  </ParagraphText>
                  <ParagraphText>
                    To host personal data and enable our App to operate and be
                    distributed we use Amazon Web Services, which is a hosting
                    and backend service provided by Amazon.
                  </ParagraphText>
                  <ParagraphText>
                    To monitor infrastructure and the App’s performance, we use
                    Crashlytics, which is a monitoring service provided by
                    Google. Here’s its{' '}
                    <span className="text-primary">Data Collection Policy</span>
                    .
                  </ParagraphText>
                  <ParagraphText>
                    We use Firebase Performance Monitoring and Firebase Crash
                    Reporting, which are monitoring services provided by Google.
                    To learn more,{' '}
                    <span className="text-primary">
                      please visit Google’s Privacy policy{' '}
                    </span>
                    .{' '}
                    <span className="text-primary">
                      {' '}
                      and Privacy and Security in Firebase.{' '}
                    </span>
                    .
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.2. To customize your experience
                  </p>
                  <ParagraphText>
                    We process your personal data, in particular, such as your
                    characteristics and preferences, to adjust the content of
                    the Service and provide content tailored to your personal
                    preferences. As a result of such processing, you get access,
                    for example, to a nutrition plan containing only vegetarian
                    products or a customized meal plan offer (in the in-App
                    chat) tailored for achieving your goals.
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    2.3. To manage your account and provide you with customer
                    support
                  </p>
                  <ParagraphText>
                    We process your personal data to respond to your requests
                    for technical support, Service information or to any other
                    communication you initiate. This includes accessing your
                    account to address technical support requests. For this
                    purpose, we may send you, for example, notifications or
                    emails about the performance of our Service, security,
                    payment transactions, notices regarding our Terms and
                    Conditions of Use or this Privacy Policy.
                  </ParagraphText>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                3. With whom we share your personal data
              </p>
              <ParagraphText>
                We share information with third parties that help us operate,
                provide, improve, integrate, customize, support, and market our
                Service. We may share some sets of personal data, in particular,
                for purposes indicated in Section 2 of this Privacy Policy. The
                types of third parties we share information with include, in
                particular:
              </ParagraphText>
              <div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    3.1. Service providers
                  </p>
                  <ParagraphText>
                    We share personal data with third parties that we hire to
                    provide services or perform business functions on our
                    behalf, based on our instructions. We share your personal
                    information with the following types of service providers:
                  </ParagraphText>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    1.2. Data provided by third parties
                  </p>
                  <ParagraphText>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </ParagraphText>
                </div>
                <div className="">
                  <div className="ml-10 my-6">
                    <div>
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          cloud storage providers (Amazon)
                        </p>
                      </div>

                      <div className="flex items-center gap-2 py-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          data analytics providers (Facebook, Google, Appsflyer,
                          Firebase, Crashlytics, Amplitude, Fabric)
                        </p>
                      </div>

                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <p className="text-2xl text-fcNavy2 font-GilroyRegular items-center ">
                          marketing partners (in particular, social media
                          networks, marketing agencies, email delivery services,
                          Facebook, Google, Snapchat)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-2xl text-fcNavy2 font-GilroySemiBold py-6 ">
                    3.2. Apple Health Kit (and Apple Motion & Fitness API) or
                    Google Fit (together the “Health App”)
                  </p>
                  <ParagraphText>
                    Provided you give express permission on your device, we may
                    receive (read) or/and write (share) data about your activity
                    with/from Health App. In some of our Apps you may decide to
                    allow us to read (receive) the following information from
                    the Health App: the number of steps traveled and weight.
                  </ParagraphText>
                  <ParagraphText>
                    If you grant access for us to write data to (share with)
                    Health App, we will transfer to Health App information on
                    your workouts, weight, dietary energy (calorie intake
                    according to information on how you follow your meal plan).
                    Before you decide to share your data with the Health App, we
                    encourage you to review their privacy policy, as your data
                    will be subject to those policies. For more information on
                    the Apple HealthKit, please see
                    <span className="text-primary">
                      http://www.apple.com/ios/health/
                    </span>
                    , and on the Google Fit, please see{' '}
                    <span className="text-primary">
                      https://www.google.com/fit/
                    </span>
                    .
                  </ParagraphText>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p className="text-4xl text-fcNavy2 font-GilroySemiBold pt-8">
                13. Contact us
              </p>
              <ParagraphText>
                You may contact us at any time for details regarding this
                Privacy Policy and its previous versions. For any questions
                concerning your account or your personal data please contact us
                at <span className="text-primary">support@fitcliq.com.</span>
              </ParagraphText>
            </div>
          </div>
          <p className="font-GilroySemiBold text-2xl text-fcNavy pt-4">
            Effective as of: 17 December 2020
          </p>
        </div>
      </section>
    </div>
  );
};

export default Terms;
