import React from "react";
import Toggle from "../../../components/common/Toggle";

const PushNotification = () => {
  return (
    <div className="">
      <div className="bg-white   rounded-t-md pb-6 rounded-xl  ">
        <div className="px-4 flex justify-between ">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Pause all
          </p>
          <Toggle />
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-6 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">Likes</p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From People I Follow
              </p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From Everyone
              </p>
            </div>
          </div>
          <p className="text-fcGray text-sm">johnappleseed liked your photo.</p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-6 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Likes and Comments on Photos of You
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From People I Follow
              </p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From Everyone
              </p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed commented on a post you’re tagged in.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-6 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Comments
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From People I Follow
              </p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">
                From Everyone
              </p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed commented: “Nice shot!”
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Comment Likes
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed liked your comment: Nice shot!
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            New Friends
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            John Appleseed (johnappleseed) started following you.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Accepted Friends Requests
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            John Appleseed (johnappleseed) accepted your follow request.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Account Suggestions
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed, who you might know, is on Instagram, and other
            similar notifications.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Messages
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed sent you a message.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Message Reminders
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed sent you a message (1d ago).
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Cliq Requests
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            johnappleseed wants to add janeappleseed to your cliq.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Reminders
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            You have unseen notifications, and other similar notifications.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PushNotification;
