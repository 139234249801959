import React, { useState } from "react";
import ArrowLeft from "../../../assets/icons/ArrowLeft.svg";
import Dansteve from "../../../assets/Dansteve.png";
import { Link } from "react-router-dom";
import { BiShareAlt } from 'react-icons/bi';
import SideBar from "../sidebar/SideBar";
import MapIcon from "../../../components/icons/MapIcon";
import MessageIcon from "../../../components/icons/MessageIcon";
import CallingIcon from "../../../components/icons/CallingIcon";
import ActiveClients from "./ActiveClients";
import Modal from "./Modal";

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <SideBar />
      <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
        <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
          <div className="mt-6">
            <div className="w-[100%] z-40">
              <nav className="py-4 z-50 bg-fcGray6 sticky top-0 flex justify-between">
                <div className="flex items-center gap-2">
                  <Link to="/dashboard/coach">
                    <img
                      src={ArrowLeft}
                      className="bg-white p-2 rounded-full"
                    />
                  </Link>

                  <p className="font-GilroySemiBold text-xl text-fcNavy ">
                    Dan Steve
                  </p>
                </div>
                <div className="bg-white p-2 rounded-full" onClick={() => {
                    setShowModal(true);
                  }}>
                  {' '}
                  <BiShareAlt className="cursor-pointer" />
                </div>
                {showModal && <Modal setOpenModal={setShowModal} />}
              </nav>
              <div className="shadow-sm relative overflow-x-hidden">
                <div className="bg-[#D1D5DB]  rounded-t-md h-28 "></div>
                <div className="bg-white   rounded-t-md pb-6 rounded-b-md ">
                  <img
                    src={Dansteve}
                    alt="dan"
                    className="flex mx-auto absolute top-16 right-[370px]"
                  />
                  <div className="pt-10 ">
                    <p className="text-center text-fcNavy2 text-2xl font-GilroyBold">
                      Dan Steve
                    </p>
                    <div className="flex items-center mx-auto justify-center ">
                      <p className="text-center text-base text-fcNavy font-GilroySemiBold">
                        Crossfitters
                      </p>
                      <MapIcon className="text-primary" />
                    </div>
                    <div className="flex items-center justify-center gap-2 py-2 text-sm text-fcGray font-GilroyMedium">
                      <p>Lagos,NG</p>
                      <p>Male</p>
                      <p>6 ft 2 inches</p>
                    </div>
                    <div className="flex items-center justify-center gap-2 my-2">
                      <div className="flex gap-1 border border-primary rounded-full px-2 py-1">
                        <MessageIcon />
                        <p>Message</p>
                      </div>
                      <div className="flex gap-1 border border-primary rounded-full px-2 py-1">
                        <CallingIcon />
                        <p>Calls</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-6 w-full flex justify-between gap-8">
                <div className="">
                  <div className="bg-white w-full rounded-md shadow-sm pb-4">
                    <div>
                      <p className="px-4 pt-4 text-fcDark font-GilroyBold text-xl">
                        About
                      </p>
                      <p className="px-4 pt-4 text-fcNavy2 font-GilroyRegular ">
                        Hello! I’m coach Dan Steve and i’m so excited to help
                        you with your fitness journey. With 20 years of
                        experience as a certified personal trainer, i have the
                        knowledge and experience to get you where you want to
                        be! And as a competitive bodybuilder, i am still active
                        in everything i teach.
                      </p>
                    </div>
                  </div>
                  <div className="bg-white w-full rounded-md shadow-sm mt-6 pb-4">
                    <div>
                      <p className="px-4 pt-4 text-fcDark font-GilroyBold text-xl">
                        Certifications
                      </p>
                      <div className="flex gap-4 pl-4 my-4">
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          NASM CPT
                        </p>
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          EQF Level 3&4 Certification
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white w-full rounded-md shadow-sm mt-6 pb-4">
                    <div>
                      <p className="px-4 pt-4 text-fcDark font-GilroyBold text-xl">
                        Specializations
                      </p>
                      <div className="flex flex-wrap gap-4 pl-4 my-4">
                        <p className="rounded-full font-GilroySemiBold border-fcGray4 border bg-fcGray3 px-4 py-1 text-base text-fcNavy2">
                          General Fitness
                        </p>
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          Body Building
                        </p>
                        <p className="rounded-full font-GilroySemiBold border-fcGray4 border bg-fcGray3 px-4 py-1 text-base text-fcNavy2">
                          Gymnastics/Cheer
                        </p>
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          Flexibility/Mobility
                        </p>
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          Weight Loss
                        </p>
                        <p className="rounded-full font-GilroySemiBold bg-fcGray3 border-fcGray4 border px-4 py-1 text-base text-fcNavy2">
                          Conditioning
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white w-full pl-4 pb-10 rounded-md  h-52 shadow-lg">
                  <p className="pt-4 text-fcDark font-GilroyBold text-xl">
                    Active clients
                  </p>
                  {Array(2)
                    .fill(true)
                    .map((_, i) => (
                      <ActiveClients key={i} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Index;
