import React from 'react';

const PlayIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="vuesax_bold_play-circle" data-name="vuesax/bold/play-circle" transform="translate(-364 -380)">
            <g id="play-circle" transform="translate(364 380)">
                <path id="Vector" d="M6.667,0a6.667,6.667,0,1,0,6.667,6.667A6.669,6.669,0,0,0,6.667,0Zm2,8.153L6.733,9.267a1.523,1.523,0,0,1-.767.207A1.505,1.505,0,0,1,5.2,9.267a1.523,1.523,0,0,1-.767-1.333V5.7A1.537,1.537,0,0,1,6.74,4.367L8.673,5.48a1.545,1.545,0,0,1-.007,2.673Z" transform="translate(1.313 1.333)" fill="#fff" />
                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
            </g>
        </g>
    </svg>

);


export default PlayIcon;