import React from "react";
import profileImage from "../../../assets/profile-img.png";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import Button from "../../../components/common/Button";

const PeopleSearch = () => {
  return (
    <div className="p-4">
      <div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <Button className="flex items-center justify-center bg-fcGray4 py-1 px-3 rounded-full">
            <AddUserIcon />
            <p>Add</p>
          </Button>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <Button className="flex items-center justify-center bg-fcGray4 py-1 px-3 rounded-full">
            <AddUserIcon />
            <p>Add</p>
          </Button>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <Button className="flex items-center justify-center bg-fcGray4 py-1 px-3 rounded-full">
            <AddUserIcon />
            <p>Add</p>
          </Button>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
          <div className="flex items-center space-x-3">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>
          <Button className="flex items-center justify-center bg-fcGray4 py-1 px-3 rounded-full">
            <AddUserIcon />
            <p>Add</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PeopleSearch;
