import React from 'react';
import logoimg from "../assets/logo.svg";


function Logo() {
    return (
        <div>
            <a href="/">
                <img
                    src={logoimg}
                    alt="logo"
                    className="w-[120px] absolute top-6 left-8"
                />
            </a>
        </div>
    );
}

export default Logo;