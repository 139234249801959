import React, { useState } from 'react';
import ArrowAngleRight from '../../../components/icons/ArrowAngleRight';
import SideBar from '../sidebar/SideBar';
import Jane from '../../../assets/Jane.png';
import AccountIcon from '../../../assets/AccountIcon.svg';
import AccountIconWhite from '../../../assets/AccountIconWhite.svg';
import redball from '../../../assets/redball.png';
import Tom from '../../../assets/Tom.png';
import NotificationAlert from './NotificationAlert';
import Button from '../../../components/common/Button';
// import ProfileAdd from '../../../components/icons/ProfileAdd';
import WhoToFollow from '../../../components/common/WhoToFollow';
// import EmptyNotification from "./NotificationAlert"

const Notification = () => {
  const [showNotification, setShowNotification] = useState(false);
  // const [showInvites, setShowInvites] = useState(true);
  const handleClick = () => {
    setShowNotification(!showNotification);
  };

  return (
    <div>
      <SideBar />
      <main className="w-[85%] ml-[15%] bg-fcGray6 px-40 py-5 ">
        {/* <EmptyNotification /> */}
        <div className="bg-fcGray6 w-full h-full rounded-3xl">
          <nav className=" p-5 z-25 bg-fcGray6 sticky top-0">
            <p className="text-[25px] font-GilroySemiBold">Notification</p>
          </nav>
          <div className="flex gap-8 mt-2">
            <div className="w-[70%] z-15">
              <div className="bg-white p-4 mb-10 h-[900px] rounded-2xl">
                {showNotification ? (
                  <div className="">
                    <div className="flex gap-4 items-center">
                      <img src={redball} alt="jane" className="w-[48px]" />
                      <div>
                        <p className="font-GilroyRegular text-[#2A2E43] text-[15px] ">
                          <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                            {' '}
                            Celebrating health.
                          </span>
                          Complete your first workout today!
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Nov 3, 2022
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex gap-4 items-center mt-6">
                        <img src={Tom} alt="tom" className="w-[48px]" />
                        <div>
                          <p className="font-GilroyRegular text-[#2A2E43] text-[15px] ">
                            <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                              {' '}
                              Joshua Nidar
                            </span>
                            follow you
                          </p>
                          <p className="text-[#2A2E43CC] text-[14px]">2h ago</p>
                        </div>
                      </div>
                      <div className=" mt-2 ml-[58px]">
                        <Button className=" rounded-full items-center justify-center gap-2 py-2 px-8 text-[16px]  bg-primary text-white ">
                          Follow back
                        </Button>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex gap-4 items-center mt-6">
                        <img src={Tom} alt="tom" className="w-[48px]" />
                        <div>
                          <p className="font-GilroyRegular text-[#2A2E43] text-[15px] ">
                            <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                              {' '}
                              Tom Hardy
                            </span>
                            sent a request to join your cliq
                            <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                              {' '}
                              Fitness Lagos
                            </span>
                          </p>
                          <p className="text-[#2A2E43CC] text-[14px]">
                            Nov 3, 2022
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-2 ml-[58px] gap-4 ">
                        <button className=" rounded-full items-center justify-center gap-2 py-2 px-8 text-[16px]  bg-primary text-white ">
                          Accept
                        </button>
                        <button className=" rounded-full items-center justify-center gap-2 py-2 px-8 text-[16px]  bg-[#EE501426] text-[#2A2E43]">
                          Decline
                        </button>
                      </div>
                    </div>
                    <div className="flex gap-4 items-center mt-6">
                      <img src={redball} alt="jane" className="w-[48px]" />
                      <div>
                        <p className="font-GilroyRegular text-[#2A2E43] text-[15px] ">
                          <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                            {' '}
                            Hey, Adedapo Adeshina
                          </span>
                          welcome to
                          <span className="font-bold text-[#2A2E43] font-GilroyMedium text-[15px] pr-1">
                            {' '}
                            FitCliq.
                          </span>
                          There’s so much awaiting you. Enjoy the journey to
                          fitness
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">18 w ago</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div onClick={handleClick}>
                    <NotificationAlert />
                  </div>
                )}
              </div>
            </div>

            <WhoToFollow />
            {/* <div className="w-[18%] h-9/10 fixed ml-[49%]">
              <div className="bg-white p-4 mb-16 rounded-2xl">
                <div className="flex items-center justify-between py-4">
                  <p className="text-sm leading-10 tracking-wide font-GilroyBold">
                    Who to follow
                  </p>
                  <ArrowAngleRight />
                </div>
                <div>
                  <div>
                    <div className="flex gap-4 items-center">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <div>
                      {showInvites ? (
                        <button
                          className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1"
                          onClick={() => setShowInvites(false)}
                        >
                          <img
                            src={AccountIcon}
                            alt="accounticon"
                            className="w-[20px]"
                          />
                          <div>
                            <p className="font-GilroyMedium text-sm">Add</p>
                          </div>
                        </button>
                      ) : (
                        <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-primary py-1 text-white">
                          <ProfileAdd className="text-white w-sm" />
                          <div>
                            <p className="font-GilroyMedium text-sm ">
                              Invited
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <div>
                      {showInvites ? (
                        <button
                          className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1"
                          onClick={() => setShowInvites(false)}
                        >
                          <img
                            src={AccountIcon}
                            alt="accounticon"
                            className="w-[20px]"
                          />
                          <div>
                            <p className="font-GilroyMedium text-sm">Add</p>
                          </div>
                        </button>
                      ) : (
                        <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-primary py-1 text-white">
                          <ProfileAdd className="text-white w-sm" />
                          <div>
                            <p className="font-GilroyMedium text-sm ">
                              Invited
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <div>
                      {showInvites ? (
                        <button
                          className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1"
                          onClick={() => setShowInvites(false)}
                        >
                          <img
                            src={AccountIcon}
                            alt="accounticon"
                            className="w-[20px]"
                          />
                          <div>
                            <p className="font-GilroyMedium text-sm">Add</p>
                          </div>
                        </button>
                      ) : (
                        <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-primary py-1 text-white">
                          <ProfileAdd className="text-white w-sm" />
                          <div>
                            <p className="font-GilroyMedium text-sm ">
                              Invited
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-4 items-center mt-4">
                      <img src={Jane} alt="jane" />
                      <div>
                        <p className="font-GilroyMedium text-black text-[16px] font-bold">
                          Jane Brown
                        </p>
                        <p className="text-[#2A2E43CC] text-[14px]">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <div>
                      {showInvites ? (
                        <button
                          className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1"
                          onClick={() => setShowInvites(false)}
                        >
                          <img
                            src={AccountIcon}
                            alt="accounticon"
                            className="w-[20px]"
                          />
                          <div>
                            <p className="font-GilroyMedium text-sm">Add</p>
                          </div>
                        </button>
                      ) : (
                        <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-primary py-1 text-white">
                          <ProfileAdd className="text-white w-sm" />
                          <div>
                            <p className="font-GilroyMedium text-sm ">
                              Invited
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <button className="flex w-full border border-[#B9B9B9] rounded-full items-center pl-4 gap-16 py-4  mt-8 bg-[#F7F7F7] ">
                <img
                  src={AccountIconWhite}
                  alt="accounticon"
                  className="w-[24px]"
                />
                <p className="font-GilroySemiBold text-[16px] text-[#2A2E43]">
                  Invite
                </p>
              </button>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Notification;
