import React from "react";

const WhatsappIcon = ({className}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        className={className}
      >
        <g
          id="icons8-whatsapp_1_"
          data-name="icons8-whatsapp (1)"
          transform="translate(-3.868 -4)"
        >
          <path
            id="Path_2866"
            data-name="Path 2866"
            d="M4.868,35.411,7.016,27.6A15.123,15.123,0,0,1,30.842,9.419a14.947,14.947,0,0,1,4.43,10.661A15.126,15.126,0,0,1,20.134,35.15h-.006a15.172,15.172,0,0,1-7.234-1.835Z"
            transform="translate(-0.203 -0.206)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_2867"
            data-name="Path 2867"
            d="M4.767,35.705a.4.4,0,0,1-.384-.5l2.1-7.651A15.521,15.521,0,0,1,31.023,9.036,15.34,15.34,0,0,1,35.57,19.978,15.551,15.551,0,0,1,12.742,33.636L4.868,35.692A.366.366,0,0,1,4.767,35.705Z"
            transform="translate(-0.102 -0.103)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_2868"
            data-name="Path 2868"
            d="M19.931,4.794a15.075,15.075,0,1,1,0,30.15h-.006a15.172,15.172,0,0,1-7.234-1.835l-8.025,2.1L6.813,27.4a15.065,15.065,0,0,1,13.118-22.6m0,30.15h0m0,0h0M19.931,4h0A15.861,15.861,0,0,0,5.957,27.5L3.9,35a.79.79,0,0,0,.2.768.8.8,0,0,0,.769.21l7.724-2.017A15.951,15.951,0,0,0,35.868,19.875,15.9,15.9,0,0,0,19.931,4Z"
            fill="#cfd8dc"
            fill-rule="evenodd"
          />
          <path
            id="Path_2869"
            data-name="Path 2869"
            d="M29.716,11.879A12.559,12.559,0,0,0,10.156,27.4l.3.474L9.184,32.492l4.763-1.243.46.272a12.6,12.6,0,0,0,6.4,1.746h0A12.571,12.571,0,0,0,33.4,20.74,12.423,12.423,0,0,0,29.716,11.879Z"
            transform="translate(-0.884 -0.867)"
            fill="#40c351"
            fill-rule="evenodd"
          />
          <path
            id="Path_2870"
            data-name="Path 2870"
            d="M18.274,15.874c-.283-.627-.581-.64-.852-.651-.221-.01-.473-.009-.725-.009a1.393,1.393,0,0,0-1.009.472,4.214,4.214,0,0,0-1.324,3.141,7.308,7.308,0,0,0,1.544,3.9c.189.251,2.617,4.175,6.462,5.686,3.195,1.254,3.846,1.005,4.539.942a3.818,3.818,0,0,0,2.553-1.79,3.142,3.142,0,0,0,.221-1.79c-.095-.157-.347-.251-.725-.44s-2.238-1.1-2.585-1.225-.6-.188-.852.189-.977,1.225-1.2,1.476-.442.283-.82.094a10.363,10.363,0,0,1-3.042-1.869,11.355,11.355,0,0,1-2.1-2.608c-.221-.376-.024-.58.166-.769.17-.169.378-.44.568-.66a2.574,2.574,0,0,0,.378-.628.69.69,0,0,0-.032-.66C19.346,18.481,18.611,16.619,18.274,15.874Z"
            transform="translate(-2.127 -2.31)"
            fill="#fff"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export default WhatsappIcon;
