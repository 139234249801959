import React from "react";

const EditIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_linear_edit"
          data-name="vuesax/linear/edit"
          transform="translate(-620 -252)"
        >
          <g id="edit" transform="translate(620 252)">
            <path
              id="Vector"
              d="M9.75,0H7.583C2.167,0,0,2.167,0,7.583v6.5c0,5.417,2.167,7.583,7.583,7.583h6.5c5.417,0,7.583-2.167,7.583-7.583V11.917"
              transform="translate(2.167 2.167)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M9.74,1.377,1.2,9.914a2.938,2.938,0,0,0-.715,1.43L.023,14.6a1.543,1.543,0,0,0,1.842,1.842l3.261-.466a3.034,3.034,0,0,0,1.43-.715l8.537-8.537c1.473-1.473,2.167-3.185,0-5.352S11.214-.1,9.74,1.377Z"
              transform="translate(7.636 1.894)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0A7.74,7.74,0,0,0,5.352,5.352"
              transform="translate(16.152 4.496)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default EditIcon;
