import React from "react";

const SettingsIcon = ({className}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        className={className}
      >
        <g
          id="vuesax_linear_setting-2"
          data-name="vuesax/linear/setting-2"
          transform="translate(-300 -188)"
        >
          <g id="setting-2" transform="translate(300 188)">
            <path
              id="Vector"
              d="M5,2.5A2.5,2.5,0,1,1,2.5,0,2.5,2.5,0,0,1,5,2.5Z"
              transform="translate(7.5 7.5)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,8.6V7.132A1.588,1.588,0,0,1,1.583,5.549c1.508,0,2.125-1.067,1.367-2.375a1.583,1.583,0,0,1,.583-2.158L4.975.191a1.391,1.391,0,0,1,1.9.5l.092.158c.75,1.308,1.983,1.308,2.742,0L9.8.691a1.391,1.391,0,0,1,1.9-.5l1.442.825a1.583,1.583,0,0,1,.583,2.158c-.758,1.308-.142,2.375,1.367,2.375a1.588,1.588,0,0,1,1.583,1.583V8.6a1.588,1.588,0,0,1-1.583,1.583c-1.508,0-2.125,1.067-1.367,2.375a1.581,1.581,0,0,1-.583,2.158l-1.442.825a1.391,1.391,0,0,1-1.9-.5l-.092-.158c-.75-1.308-1.983-1.308-2.742,0l-.092.158a1.391,1.391,0,0,1-1.9.5l-1.442-.825a1.583,1.583,0,0,1-.583-2.158c.758-1.308.142-2.375-1.367-2.375A1.588,1.588,0,0,1,0,8.6Z"
              transform="translate(1.667 2.134)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H20V20H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SettingsIcon;
