import React from "react";
import { useRef } from "react";
import Button from "../../../components/common/Button";

const DeletePost = ({showDeletePostPopUp, setShowDeletePostPopUp, show}) => {
  const menuRef = useRef()

  if (!show) return null;
  return (
    <>
      <div show={showDeletePostPopUp} className="modal-overlay bg-fcBlack fixed top-0 left-0 right-0 bottom-0 ">
        <div
          ref={menuRef}
          className="fixed md:top-1/2 md:left-1/2 bottom-0 md:bottom-auto success-modal z-[1000] px-8 py-4 md:-translate-y-2/4 md:-translate-x-2/4 md:w-80 w-full border-none rounded-t-2xl md:rounded-lg  shadow bg-white"
        >
          <main>
            <h1 className="text-2xl font-GilroyBold mb-2">Discard Post?</h1>
            <p className="text-base font-GilroyRegular text-fcGray mb-10">
              This can’t be undone and you’ll lose your draft.
            </p>
            <Button className="text-base text-white font-semibold text-center bg-fcRed2 w-full py-3 rounded-full mb-2">
              Discard
            </Button>
            <Button
              onClick={() => setShowDeletePostPopUp(showDeletePostPopUp)}
              className="text-base  font-semibold border border-fcGray4 text-center w-full py-3 rounded-full"
            >
              Cancel
            </Button>
          </main>
        </div>
      </div>
    </>
  );
};

export default DeletePost;
