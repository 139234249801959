import React from "react";
import ReactDOM from "react-dom";

const OverLay = ({children, show, className,}) => {
    if (!show) return null;
  return ReactDOM.createPortal(
    (
      <>
        <div className="modal-overlay bg-fcBlack fixed top-0 left-0 right-0 bottom-0 ">
          <main className={className}>{children}</main>
        </div>
      </>
    ),
    document.body
  );
};

export default OverLay;
