import React from "react";
import TextAreaField from "../../../../components/common/TextAreaField";
import GalleryAddIcon from "../../../../components/icons/GalleryAddIcon";
import Button from "../../../../components/common/Button";
import FieldInput from "../../../../components/common/FieldInput";
import { useState } from "react";
import CloseIcon from "../../../../components/icons/CloseIcon";

const CreateCliqInfo = ({
  setUploadFile,
  uploadFile,
  setCurrentStep,
  formData,
  setFormData,
}) => {
  const handleselectedFile = (e) => {
    const selectedFile = e.target.files;
    const selectedFileArray = Array.from(selectedFile);
    const fileArray = selectedFileArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setUploadFile([...uploadFile, fileArray]);
    console.log(uploadFile);
  };

  const handleCreateCliqFormDataSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <main>
        <form onSubmit={handleCreateCliqFormDataSubmit} className="">
          <div className="bg-fcGray7 mb-10">
            <div className={`${uploadFile.length > 0 ? "block" : "hidden"}`}>
              {uploadFile &&
                uploadFile.map((image, uid) => (
                  <div key={uid} className="rounded-xl relative">
                    <img
                      src={image}
                      alt=""
                      className="w-full h-96 rounded-xl"
                    />
                    <div className="absolute top-8 left-8 bg-fcGray6 p-2 rounded-full">
                      <CloseIcon className="cursor-pointer" />
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={`${
                uploadFile.length <= 0
                  ? "h-64 flex items-center justify-center"
                  : "hidden"
              }`}
            >
              <label
                htmlFor="file"
                className="cursor-pointer text-center text-base font-GilroySemiBold"
              >
                <GalleryAddIcon className="h-6 w-6 ml-8" /> <br />
                Upload Cover
                <input
                  onChange={handleselectedFile}
                  id="file"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="hidden"
                  multiple
                />
              </label>
            </div>
          </div>
          <div className="mb-5">
            <FieldInput
              type="text"
              name="name"
              id="name"
              className="border rounded-xl border-fcGray4 font-GilroyRegular text-base w-full p-4 mb-10 outline-none"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <TextAreaField
              className="border border-fcGray4 w-full rounded-xl font-GilroyRegular text-base outline-none p-4"
              cols="30"
              rows="3"
              placeholder="Description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </div>
          <div className="flex items-center justify-between mb-5">
            <div>
              <p className="font-GilroyMedium text-base">Make private</p>
              <p className="font-GilroyRegular text-sm">
                When you make a Cliq private, only you can see it.
              </p>
            </div>
            <div>
              <FieldInput type="checkbox" className="h-5 w-5 cursor-pointer" />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button
              type="submit"
              onClick={() => setCurrentStep(2)}
              className="bg-fcDark text-white w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
            >
              Next
            </Button>
          </div>
        </form>
      </main>
    </>
  );
};

export default CreateCliqInfo;
