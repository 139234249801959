import React from 'react';

const NotificationBell = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_notification"
          data-name="vuesax/bold/notification"
          transform="translate(-172 -188)"
        >
          <g id="notification" transform="translate(172 188)">
            <path
              id="Vector"
              d="M16.282,13.531l-1.083-1.8a4.025,4.025,0,0,1-.433-1.6V7.388a6.4,6.4,0,0,0-3.651-5.774A3.17,3.17,0,0,0,8.319,0,3.208,3.208,0,0,0,5.514,1.647,6.422,6.422,0,0,0,1.939,7.388v2.741a3.928,3.928,0,0,1-.433,1.592L.411,13.531A2.532,2.532,0,0,0,1.83,17.355a20.261,20.261,0,0,0,6.522,1.062,20.55,20.55,0,0,0,6.522-1.051,2.612,2.612,0,0,0,1.408-3.835Z"
              transform="translate(4.67 2.167)"
              fill="#ff603e"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M6.121.011A3.266,3.266,0,0,1,3.055,2.167,3.212,3.212,0,0,1,.758,1.2,2.991,2.991,0,0,1,0,0C.141.022.282.032.433.054.682.087.942.119,1.2.141,1.82.195,2.448.227,3.077.227S4.312.195,4.918.141c.227-.022.455-.032.672-.065Z"
              transform="translate(9.945 21.667)"
              fill="#ff603e"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              transform="translate(26 26) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default NotificationBell;
