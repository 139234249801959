import React from "react";
import SideBar from "../sidebar/SideBar";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../assets/icons/ArrowLeft.svg";
import ArrowDownIcon from "../../../components/icons/ArrowDownIcon";
import Button from "../../../components/common/Button";
import Toggle from "../../../components/common/Toggle";

const SettingsPricacy = () => {
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
          <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
            <div className="mt-6">
              <div className="w-[65%] z-40 mx-auto">
                <nav className="py-4 z-50 bg-fcGray6 sticky top-0 flex justify-between">
                  <div className="flex items-center gap-4">
                    <Link to="/dashboard/settings">
                      <img
                        src={ArrowLeft}
                        className="bg-white p-2 rounded-full"
                      />
                    </Link>

                    <p className="font-GilroySemiBold text-xl text-fcNavy ">
                      Privacy
                    </p>
                  </div>
                </nav>
                <div className="shadow-sm  overflow-x-hidden">
                  <div className="bg-white   rounded-t-md pb-6 rounded-xl  ">
                    <div className="px-4">
                      <div>
                        <p className="font-GilroySemiBold text-xl text-fcDark py-4">
                          Account privacy
                        </p>
                        <div className="flex gap-3     ">
                          <label className="text-base">
                            <input
                              type="checkbox"
                              className="accent-orange h-4 w-4 text-fcGray4 border-fcGray3  rounded-lg"
                              value=" Adapting the plan to your busy schedule"
                            />{" "}
                          </label>
                          <p className="text-base font-GilroyMedium">
                            Private account
                          </p>
                        </div>
                        <div className="text-sm font-GilroyMedium text-fcGray py-2">
                          <p>
                            When your account is public, your profile and posts
                            can be seen by anyone, on or off Fitcliq, even if
                            they don’t have an Instagram account. When your
                            account is private, only the followers you approve
                            can see what you share, including your photos or
                            videos on hashtag and location pages, and your
                            followers and following lists.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white   rounded-t-md pb-6 rounded-xl my-4 ">
                    <div className="px-4">
                      <div>
                        <p className="font-GilroySemiBold text-xl text-fcDark py-4">
                          Activity Status
                        </p>
                        <div className="flex gap-3     ">
                          <label className="text-base">
                            <input
                              type="checkbox"
                              className="accent-orange h-4 w-4 text-fcGray4 border-fcGray3  rounded-lg"
                              value=" Adapting the plan to your busy schedule"
                            />{" "}
                          </label>
                          <p className="text-base font-GilroyMedium">
                            Show Activity Status
                          </p>
                        </div>
                        <div className="text-sm font-GilroyMedium text-fcGray py-2">
                          <p>
                            Allow accounts you follow and anyone you message to
                            see when you were last active or are currently
                            active on Instagram apps. When this is turned off,
                            you won’t be able to see the Activity Status of
                            other accounts.
                            <br /> <br /> You can continue to use our services
                            if active status is off.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white   rounded-t-md pb-6 rounded-xl my-4 ">
                    <div className="px-4">
                      <div>
                        <p className="font-GilroySemiBold text-xl text-fcDark py-4">
                          Activity Status
                        </p>
                        <div>
                          <p className="font-GilroySemiBold text-base text-fcDark py-4">
                            Likes and Views
                          </p>
                          <div className="flex justify-between">
                            <p className="font-GilroySemiBold text-base text-fcDark">
                              Hide likes
                            </p>
                            <div>
                             <Toggle />
                            </div>
                          </div>
                          <div className="text-sm font-GilroyMedium text-fcGray py-4">
                            <p>
                              The number of likes on posts from other accounts
                              will be hidden. You can hide the number of likes
                              on your own posts by going to Advanced Settings
                              before sharing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsPricacy;
