import React from "react";
import Button from "../../../components/common/Button";

const EmptyNewMessage = ({ className, setSendMessage}) => {
  return (
    <div className={className}>
      <div className="flex items-center h-[78vh] justify-center">
        <div className="mt-40">
          <h1 className="text-xl font-GilroySemiBold text-center mb-1">
            Select a message
          </h1>
          <p className="text-sm font-GilroyMedium text-fcGray text-center">
            Choose from your existing conversations, start a new one, or just
            keep swimming.
          </p>
          <div className="mt-6 flex items-center justify-center">
            <Button onClick={() =>setSendMessage(true)} className="bg-fcPrimary text-white text-base font-GilroySemiBold px-7 py-3 rounded-full">
              New Message
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyNewMessage;
