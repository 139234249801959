import React from "react";

const CallingIcon = ({ className, onClick }) => {
  return (
    <div>
      <svg
        id="call-calling"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        className={className}
        onClick={onClick}
      >
        <g
          id="call-calling-2"
          data-name="call-calling"
          transform="translate(1.667 1.667)"
        >
          <path
            id="Vector"
            d="M16.642,13.608a2.121,2.121,0,0,1-.208.908,3.438,3.438,0,0,1-.567.85,3.757,3.757,0,0,1-1.367.983,4.185,4.185,0,0,1-1.625.317,6.945,6.945,0,0,1-2.717-.608,14.637,14.637,0,0,1-2.867-1.65,23.959,23.959,0,0,1-2.733-2.333A23.678,23.678,0,0,1,2.233,9.35,14.857,14.857,0,0,1,.6,6.508,7.019,7.019,0,0,1,0,3.783,4.311,4.311,0,0,1,.3,2.175,3.836,3.836,0,0,1,1.258.783,2.442,2.442,0,0,1,2.992,0a1.566,1.566,0,0,1,.675.15,1.358,1.358,0,0,1,.558.467L6.158,3.342a2.852,2.852,0,0,1,.333.583,1.317,1.317,0,0,1,.117.508,1.131,1.131,0,0,1-.175.592,2.834,2.834,0,0,1-.467.592l-.633.658a.446.446,0,0,0-.133.333.757.757,0,0,0,.025.192c.025.067.05.117.067.167a6.915,6.915,0,0,0,.775,1.067c.375.433.775.875,1.208,1.317.45.442.883.85,1.325,1.225a6.4,6.4,0,0,0,1.075.767c.042.017.092.042.15.067a.575.575,0,0,0,.208.033.458.458,0,0,0,.342-.142l.633-.625a2.557,2.557,0,0,1,.6-.467,1.11,1.11,0,0,1,.592-.175,1.33,1.33,0,0,1,.508.108,3.223,3.223,0,0,1,.583.325l2.758,1.958a1.264,1.264,0,0,1,.458.533A1.71,1.71,0,0,1,16.642,13.608Z"
            fill="none"
            stroke="#292d32"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2.917,2.917a3.3,3.3,0,0,0-.975-1.892A2.846,2.846,0,0,0,0,0"
            transform="translate(10.833 2.917)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M5.833,5.833A5.829,5.829,0,0,0,0,0"
            transform="translate(10.833)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          fill="none"
          opacity="0"
        />
      </svg>
    </div>
  );
};

export default CallingIcon;
