import React from 'react';
import LayOut from '../../layout';
import blogImg from "../../assets/img/blog-img.png";
import Eloho from "../../assets/Eloho.png";


function index() {
    return (
        <LayOut>
            <div className="py-40 px-56">
                <div className="  ">
                    <h1 className="text-[127px] text-fcNavy4 font-GilroyBold">
                        The FitCliq Blog
                    </h1>
                    <p className="mt-6 text-2xl text-center">We write about how to manage your fitness well.</p>
                </div>
                <div className="  mt-16">
                    <p>Read all articles  about all topics</p>
                    <div className="mt-16 flex flex-wrap gap-10">
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[468px]">
                            <div>
                                <img src={blogImg} alt="Blog Img" />
                            </div>
                            <div className="mt-6 flex space-x-2">
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition</span>
                                <span className="bg-fcGray4 text-fcGray2 rounded-full px-2.5 py-1 text-xs">Nutrition Facts</span>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-3xl">Juice It Up! A Guide to Reaping the Health Benefits of Mango Ju…</h3>
                                <p className="text-lg font-GilroyRegular text-fcGray2 mt-4">Today’s most successful B2B sales and marketing teams are using these 7 lead generation tactics to get higher-quality leads, and so can you.</p>
                                <div className="mt-6 flex space-x-3">
                                    <div><img src={Eloho} alt="Blog Img" className="rounded-full" /></div>
                                    <div>
                                        <p className="text-fcNavy2">Dapo Popsi</p>
                                        <p className="text-sm font-GilroyRegular text-fcGray2">Feb 21, 2023 &middot; 5 min read &middot; 20 views </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="mx-auto flex items-center justify-center mt-16 w-32 rounded-full border border-fcGray2 px-5 flex">
                    <p className="text-center w-full font-GilroyMedium text-base text-fcGray2">Load More...</p>
                </div>


            </div>
        </LayOut >
    );
}

export default index;