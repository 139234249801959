import React from "react";

const SmallTimerIcon = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={className}
      >
        <g
          id="vuesax_bold_timer"
          data-name="vuesax/bold/timer"
          transform="translate(-236 -188)"
        >
          <g id="timer">
            <path
              id="Vector"
              d="M8.67,0a8.675,8.675,0,1,0,8.67,8.68A8.681,8.681,0,0,0,8.67,0Zm.75,8.35a.75.75,0,0,1-1.5,0v-5a.75.75,0,0,1,1.5,0Z"
              transform="translate(239.33 192.65)"
              fill="#25c100"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M6.5,1.45H.72A.717.717,0,0,1,0,.73.726.726,0,0,1,.72,0H6.5a.717.717,0,0,1,.72.72A.726.726,0,0,1,6.5,1.45Z"
              transform="translate(244.39 190)"
              fill="#25c100"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(260 212) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SmallTimerIcon;
