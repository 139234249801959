import React from 'react'
import emailbgImage from "../../assets/img/emailbg-3.png";
import RatingFilledIcon from "../icons/RatingFilledIcon"
import RatingEmptyIcon from "../icons/RatingEmptyIcon";
import MapIcon from '../icons/MapIcon';
import Button from './Button';
import CallingIcon from '../icons/CallingIcon';

function GymCard() {
  return (
      <div className="first-gym mb-5">
          <div className="mb-2">
              <img
                  src={emailbgImage}
                  alt=""
                  className="w-full h-64 rounded-xl"
              />
          </div>
          <h2 className="text-base font-GilroySemiBold">
              Fitness Central Lekki
          </h2>
          <p className="text-base font-GilroyRegular text-fcGray">
              Adekola Balogun Street, Prechstone & Graeys Cl, lekki
          </p>
          <div className="flex items-center mb-2">
              <p className="text-xs font-GilroyMedium mr-1">4.4</p>
              <RatingFilledIcon />
              <RatingFilledIcon />
              <RatingFilledIcon />
              <RatingEmptyIcon />
              <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
              <span className="text-[#00AF36]">Open</span> . Closes 11
              pm
          </p>
          <div className="flex items-center space-x-3">
              <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
                  <MapIcon className="mr-1" />
                  Directions
              </Button>
              <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
                  <CallingIcon className="mr-1" />
                  Call
              </Button>
          </div>
      </div>
  )
}

export default GymCard