import React from "react";
import SideBar from "../sidebar/SideBar";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../assets/icons/ArrowLeft.svg";
import ArrowDownIcon from "../../../components/icons/ArrowDownIcon";
import Button from "../../../components/common/Button";

const WorkoutPreference = () => {
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
          <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
            <div className="mt-6">
              <div className="w-[65%] z-40 mx-auto">
                <nav className="py-4 z-50 bg-fcGray6 sticky top-0 flex justify-between">
                  <div className="flex items-center gap-2">
                    <Link to="/dashboard/settings">
                      <img
                        src={ArrowLeft}
                        className="bg-white p-2 rounded-full"
                      />
                    </Link>

                    <p className="font-GilroySemiBold text-xl text-fcNavy ">
                      Workout Reference
                    </p>
                  </div>
                </nav>
                <div className="shadow-sm  overflow-x-hidden">
                  <div className="bg-white   rounded-t-md pb-6 rounded-xl  ">
                    <div className="flex justify-between px-4 pt-6 items-center">
                      <p className="font-GilroySemiBold text-base text-fcDark">
                        Fitness Goal
                      </p>

                      <div className="">
                        <select
                          id="active"
                          name="active"
                          className="w-72 p-4  border border-fcGray4  text-base font-GilroyMedium text-fcDark rounded-lg    dark:bg-gray-700   dark:text-white "
                        >
                          <option value="Advance">Be more Active</option>
                          <option value="Advance">Be more Active</option>
                          <option value="Advance">Be more Active</option>
                        </select>
                        {/* <ArrowDownIcon className="" /> */}
                      </div>
                    </div>
                    <div className="flex justify-between px-4 py-2 my-4">
                      <p className="font-GilroySemiBold text-base text-fcDark items-center">
                        Workouts Per Week
                      </p>
                      <div className="">
                        <select
                          id="active"
                          name="active"
                          className="bg-gray-50 w-72 p-4  border border-fcGray4 text-gray-900 text-base font-GilroySemiBold text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="Advance">1 - 2 times</option>
                          <option value="Advance">2 - 3 times</option>
                          <option value="Advance">3 - 4 times</option>
                        </select>
                        {/* <ArrowDownIcon className="" /> */}
                      </div>
                    </div>
                    <div className="flex justify-between px-4  ">
                      <p className="font-GilroySemiBold text-base text-fcDark items-center">
                        Workouts Length
                      </p>
                      <div className="">
                        <select
                          id="active"
                          name="active"
                          className="bg-gray-50 w-72 p-4  border border-fcGray4 text-gray-900 text-base font-GilroySemiBold text-fcDark rounded-lg focus:ring-blue-500 focus:border-blue-500 block  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="Advance">5 - 10 minutes</option>
                          <option value="Advance">11 - 15 minutes</option>
                          <option value="Advance">16 - 20 minutes</option>
                        </select>
                        {/* <ArrowDownIcon className="" /> */}
                      </div>
                    </div>
                    <div className="flex justify-end mx-4 mt-16">
                      <Button className="bg-fcGray4 px-6 py-2 font-GilroyMedium text-fcDark rounded-full text-base">
                        save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default WorkoutPreference;
