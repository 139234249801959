import React, { useState } from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";
import { HiPlusSm } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { Link } from "react-router-dom";
import OptionButton from "../../components/common/OptionButton";
import PlusIcon from "../../components/icons/PlusIcon";

const Onboarding18 = () => {
  const [open, setOpen] = useState(false);

  // handle toggle
  const toggle = () => {
    setOpen(!open);
  };
  //   const [active, setActive] = useState(false);
  //   const handleClick = () => {
  //     setActive(!active);
  //   };

  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center w-full  ">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-full"></div>
            </div>
            <div className="flex justify-between items-center ">
              <Link to="/onboarding16">
                <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
              </Link>
              <Link to="/dashboard">
                <p className="text-primary">Skip</p>
              </Link>

            </div>
          </div>
          <div className="text-center mb-4">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">Join community groups</p>
            <p className="text-[14px] pt-2 opacity-100">
              Join groups to find your community on FitCliq
            </p>
          </div>
          <div>
            <p className="text-[15px]">Food</p>
            <div className="  py-2 flex flex-wrap w-[460px] gap-2  ">
              <OptionButton title=" Intermittent Fasting Club" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title=" Meal Preppers" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title=" Low Carb/Keto Club" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="Vegetarians" icon={<PlusIcon className="text-sm" />} />
            </div>
          </div>
          <div className="py-2">
            <p className="text-[15px]">JOURNEY</p>
            <div className="  py-2 flex flex-wrap gap-2  ">
              <OptionButton title="Newbies" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="Gain Muscle " icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="The Modifiers" icon={<PlusIcon className="text-sm" />} />

            </div>
          </div>
          <div className="py-2">
            <p className="text-[15px]">LIFESTYLE</p>
            <div className="  py-2 flex flex-wrap gap-2  w-[450px] ">
              <OptionButton title="Single & Crushing it" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="30’s Club" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="Fitbiters" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="Apple Watch Crew" icon={<PlusIcon className="text-sm" />} />

            </div>
          </div>
          <div className="py-2">
            <p className="text-[15px]">ACTIVITY</p>
            <div className="  py-2 flex flex-wrap gap-2  w-[450px] ">
              <OptionButton title=" Walkers United" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title=" Yoga Lovers " icon={<PlusIcon className="text-sm" />} />
              <OptionButton title="Pilates Party" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title=" Run The World" icon={<PlusIcon className="text-sm" />} />
              <OptionButton title=" Meditation Squad" icon={<PlusIcon className="text-sm" />} />

            </div>
          </div>

          <Link to="/dashboard">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Onboarding18;
