import React from "react";

const DiscoverSubcathegory = ({ currentSearchStep, setCurrentSearchStep }) => {
  return (
    <>
      <nav>
        <ul className="flex items-center justify-around text-base font-GilroySemiBold border-b border-fcGray4">
          <li
            onClick={() => setCurrentSearchStep(1)}
            className={`py-3 cursor-pointer ${currentSearchStep === 1 ? 'border-b-[4px] border-fcPrimary text-fcDark' : 'text-fcGray'}`}
          >
            Top
          </li>
          <li
            onClick={() => setCurrentSearchStep(2)}
            className={`py-3 cursor-pointer ${currentSearchStep === 2 ? 'border-b-[4px] border-fcPrimary text-fcDark' : 'text-fcGray'}`}
          >
            People
          </li>
          <li
            onClick={() => setCurrentSearchStep(3)}
            className={`py-3 cursor-pointer ${currentSearchStep === 3 ? 'border-b-[4px] border-fcPrimary text-fcDark' : 'text-fcGray'}`}
          >
            Cliq
          </li>
          <li
            onClick={() => setCurrentSearchStep(4)}
            className={`py-3 cursor-pointer ${currentSearchStep === 4 ? 'border-b-[4px] border-fcPrimary text-fcDark' : 'text-fcGray'}`}
          >
            Gym
          </li>
          <li
            onClick={() => setCurrentSearchStep(5)}
            className={`py-3 cursor-pointer ${currentSearchStep === 5 ? 'border-b-[4px] border-fcPrimary text-fcDark' : 'text-fcGray'}`}
          >
            Coaches
          </li>
        </ul>
      </nav>
    </>
  );
};

export default DiscoverSubcathegory;
