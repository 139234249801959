import React from 'react';

const FlashIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_flash"
          data-name="vuesax/bold/flash"
          transform="translate(-428 -316)"
        >
          <g id="flash" transform="translate(428 316)">
            <path
              id="Vector"
              d="M14.285,9.448H10.937v-7.8c0-1.82-.986-2.188-2.188-.823l-.867.986L.548,10.152c-1.008,1.137-.585,2.069.932,2.069H4.827v7.8c0,1.82.986,2.188,2.188.823l.867-.986,7.334-8.342C16.224,10.379,15.8,9.448,14.285,9.448Z"
              transform="translate(5.118 2.166)"
              fill="#ff603e"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              transform="translate(26 26) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default FlashIcon;
