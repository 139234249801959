import React from "react";
import BoldFlashIcon from "../../../components/icons/BoldFlashIcon";
import ChallengeCard from "./ChallengeCard";

const EmptyChallenge = ({ className, setShowChallenges }) => {
  return (
    <div className={className}>
      <div
        onClick={() => setShowChallenges(true)}
        className="bg-white rounded-xl p-4  h-[77vh] flex items-center justify-center"
      >
        <div className="">
          <div className="flex items-center justify-center">
            <BoldFlashIcon className="w-16 h-16" />
          </div>
          <h1 className="text-xl font-GilroySemiBold text-center">
            Your Challenges
          </h1>
          <p className="text-base font-GilroyRegular text-center">
            The challenges you join and create will show up here
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyChallenge;
