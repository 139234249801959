import React from 'react';

function OptionButton(children) {
    const { title, smallText, icon } = children;
    return (
        <div className={`${icon ? 'text-sm flex justify-between items-center space-x-2 font-GilroyRegular text-fcGray5' : 'w-full font-GilroyMedium text-base'} text-center  opacity-100 border border-fcGray4 text-gray-200   rounded-full ${smallText || icon ? 'p-3' : 'p-5'} cursor-pointer`}>
            <p>{title}</p>
            {
                smallText && (<span className='text-sm text-fcGray5 font-GilroyRegular'>{smallText}</span>)
            }
            {icon}

        </div>
    );
}

export default OptionButton;