import React from "react";
import { useState } from "react";
import TagPeople from "./TagPeople";
import CreatedPost from "./CreatedPost";
import Modal from "../../../components/common/Modal";

const CreatePost = ({ showCreatePostPopUp, setShowCreatePostPopUp, selectFile, setSelectFile }) => {
  const [currentPostStep, setCurrentPostStep] = useState(1);

  const displayPostSteps = (currentPostStep) => {
    switch (currentPostStep) {
      case 1:
        return (
          <CreatedPost
            setShowCreatePostPopUp={setShowCreatePostPopUp}
            setCurrentPostStep={setCurrentPostStep}
            selectFile={selectFile}
            setSelectFile={setSelectFile}
          />
        );
      case 2:
        return (
          <TagPeople
            setShowCreatePostPopUp={setShowCreatePostPopUp}
            setCurrentPostStep={setCurrentPostStep}
            selectFile={selectFile}
            setSelectFile={setSelectFile}
          />
        );

      default:
        break;
    }
  };
  return (
    <>
     <Modal show={showCreatePostPopUp} setShowCreatePostPopUp={setShowCreatePostPopUp}>
      {
        displayPostSteps(currentPostStep)
      }
     </Modal>
    </>
  );
};

export default CreatePost;
