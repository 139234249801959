import React from 'react';
import profileImage from '../../../assets/profile-img.png';
import Threedot from '../../icons/Threedot';
import SearchIcon from '../../icons/SearchIcon';
import AccountIcon from '../../../assets/AccountIcon.svg';

const InviteFriends = () => {
  return (
    <div className="overflow-y-scroll h-96 ">
      <div className="p-4 relative mx-2 ">
        <h1 className="text-xl font-GilroySemiBold mb-4">200 friends</h1>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <div className="flex items-center space-x-3  ">
            <div>
              <img src={profileImage} alt="" className="h-12 w-12" />
            </div>
            <div>
              <p className="text-base px-1 font-GilroyMedium text-black">
                Adeshina Adedapo
              </p>
              <p className="text-sm font-GilroyRegular text-fcGray">
                Lekki, Nigeria
              </p>
            </div>
          </div>

          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1  ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4 hover:bg-fcGray3 p-2">
          <>
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base px-1 font-GilroyMedium text-black">
                  Adeshina Adedapo
                </p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
          </>
          <div>
            <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1 px-2">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-base px-1 ">invite</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
