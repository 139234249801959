import React, { useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import ChatIcon from "../../../components/icons/ChatIcon";
import WhiteEmail from "../../../components/icons/WhiteEmail";
import Messenger from "../../../components/icons/Messenger";
import WhatsappIcon from "../../../components/icons/WhatsappIcon";
import NewFacebook from "../../../components/icons/NewFacebook";
import CopyLinkIcon from "../../../components/icons/CopyLinkIcon";
import TwitterIcon from "../../../components/icons/TwitterIcon";

export default function Modal({ setOpenModal }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="fixed inset-0 z-16 my-auto  mt-40  items-center justify-center">
        <div
          className="fixed inset-0 w-full h-full bg-[#2A2E43CC] opacity-60"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="items-center max-w-xl  mx-auto mt-40 min-h-screen px-4 py-8">
          <div className="relative w-full mx-auto bg-white rounded-lg shadow-lg ">
            <div className="">
              <div className="shadow-sm  items-center justify-between px-4  flex p-2">
                <p className="font-bold text-xl font-GilroyBold">
                  Share this...
                </p>
                <HiOutlineX
                  onClick={() => setOpenModal(false)}
                  className="text-xl"
                />
              </div>
              <div className=" flex flex-wrap gap-4 py-4 mx-8 justify-left items-left ">
                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16 ">
                  <CopyLinkIcon />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Copy Link
                  </p>
                  <div></div>
                </div>
                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <WhiteEmail />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Email
                  </p>
                  <div></div>
                </div>
                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <ChatIcon />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Messages
                  </p>
                  <div></div>
                </div>

                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <WhatsappIcon />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Whatsapp
                  </p>
                  <div></div>
                </div>
                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <Messenger />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Messenger
                  </p>
                  <div></div>
                </div>
                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <NewFacebook />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Facebook
                  </p>
                  <div></div>
                </div>

                <div className="flex gap-4 items-center border border-fcGray4 rounded-lg pl-2 py-4 pr-16">
                  <TwitterIcon />
                  <p className="text-xl font-GilroySemiBold text-fcDark">
                    Twitter
                  </p>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
