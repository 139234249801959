import React, { useEffect, useState } from "react";
import LoaderCircle from "../icons/LoaderCircle";

const Loader = () => {
  const [currentLoaderValue, setCurrentLoaderValue] = useState(0);

  const duration = () => {
    setInterval(() => {
      setCurrentLoaderValue(currentLoaderValue + 1);
    }, 1000);
  };

  useEffect(() => {
    duration()
  })

  return (
    <div>
      <div className="flex items-center justify-center relative">
        <div className="skill">
          <div className="outer">
            <div className="inner">
              <div id="number">{currentLoaderValue} %</div>
            </div>
          </div>
          <LoaderCircle className="absolute top-0" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
