import React, { useState } from "react";
import profileImage from "../../../assets/profile-img.png";
import Button from "../../../components/common/Button";
import OverLay from "../../../components/common/OverLay";
import CallingIcon from "../../../components/icons/CallingIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import MapIcon from "../../../components/icons/MapIcon";
import MessageIcon from "../../../components/icons/MessageIcon";

const CoachesSearch = () => {
  const [showCoachPopUp, setShowCoachPopUp] = useState(false);
  return (
    <div className="p-4">
      <div className="grid grid-cols-4 gap-5">
        <div onClick={() =>setShowCoachPopUp(true)} className="flex flex-col items-center justify-center cursor-pointer">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
        </div>
      </div>
      <OverLay show={showCoachPopUp} className="flex items-center justify-center translate-y-96">
        <main className="bg-white w-80 p-2 rounded-lg">
          <nav className="flex items-center justify-between">
            <h1 className="text-2xl font-GilroyBold">Coach</h1>
            <CloseIcon onClick={() =>setShowCoachPopUp(false)} className="cursor-pointer" />
          </nav>
          <div className="flex items-center justify-center my-5">
            <div className="relative">
              <img
                src={profileImage}
                alt=""
                className="h-24 w-24 rounded-full"
              />
              <div className="w-5 h-5 bg-fcyellow2 rounded-full absolute bottom-1 left-16"></div>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-2">
            <h1 className="text-sm font-GilroySemiBold">Crossfitters</h1>
            <MapIcon className="text-fcPrimary" />
          </div>
          <h1 className="text-base font-GilroyMedium text-center mt-1 mb-3">
            Dan Steve
          </h1>
          <div className="flex items-center justify-center space-x-2 py-2">
            <Button className="flex items-center justify-center space-x-1 border border-fcPrimary rounded-full py-1 px-3">
              <MessageIcon />
              <span>Message</span>
            </Button>
            <Button className="flex items-center justify-center space-x-1 border border-fcPrimary rounded-full py-1 px-3">
              <CallingIcon />
              <span>Call</span>
            </Button>
          </div>
        </main>
      </OverLay>
    </div>
  );
};

export default CoachesSearch;
