import React from 'react'
import Jane from "../../assets/Jane.png";
import AccountIcon from "../../assets/AccountIcon.svg";
import AccountIconWhite from "../../assets/AccountIconWhite.svg";

function UserCard() {
  return (
      <div className="h-[88px] w-[202px]">
          <div className="flex gap-4 items-center  ">
              <img src={Jane} alt="jane" />
              <div>
                  <p className="font-GilroyMedium text-black text-base font-bold">
                      Jane Brown
                  </p>
                  <p className="text-[#2A2E43CC] text-[14px]">Lekki, Nigeria</p>
              </div>
          </div>
          <button className="flex w-full rounded-full items-center justify-center gap-2 mt-2 bg-[#F7F7F7] py-1">
              <img src={AccountIcon} alt="accounticon" className="w-[20px]" />
              <p className="font-GilroyMedium text-[14px]">Add</p>
          </button>
      </div>
  )
}

export default UserCard