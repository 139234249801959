import React, { useState } from "react";
import recievedImage from "../../../assets/img/workoutimg-1.png";

const MessageRecieved = () => {
  const [zoomRecievedImage, setZoomRecievedImage] = useState(false);
  return (
    <div className="flex flex-col items-start justify-start space-y-1">
      <div className="bg-fcGray6 p-3 text-center rounded-tl-3xl rounded-br-3xl rounded-tr-3xl">
        <p className="text-fcDark text-base font-GilroyMedium w-32">
          Hello beauti3xl
        </p>
      </div>
      <div className="bg-fcGray6 p-3 text-center rounded-tl-3xl rounded-br-3xl rounded-tr-3xl">
        <p className="text-fcDark text-base font-GilroyMedium w-32">
          Hello beauti3xl
        </p>
      </div>
      <div className="bg-fcGray6 p-3 text-center rounded-tl-3xl rounded-br-3xl rounded-tr-3xl">
        <p className="text-fcDark text-base font-GilroyMedium w-96">
          Well, I’m new here. Looking for a community for fellow fitness buff to
          help in achieving my fitness goals
        </p>
      </div>
      <div>
        <img
          onClick={() => setZoomRecievedImage(!zoomRecievedImage)}
          src={recievedImage}
          alt=""
          className={`w-36 h-36 cursor-pointer rounded-xl ${
            zoomRecievedImage ? "w-60 h-72" : ""
          }`}
        />
      </div>
      <p className="text-base font-GilroyMedium">12:30pm</p>
    </div>
  );
};

export default MessageRecieved;
