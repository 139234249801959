import React from "react";

const SearchIcon = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className={className}
      >
        <g
          id="vuesax_linear_search-normal"
          data-name="vuesax/linear/search-normal"
          transform="translate(-428 -188)"
        >
          <g id="search-normal" transform="translate(428 188)">
            <path
              id="Vector"
              d="M12.667,6.333A6.333,6.333,0,1,1,6.333,0,6.333,6.333,0,0,1,12.667,6.333Z"
              transform="translate(1.333 1.333)"
              fill="none"
              stroke="rgba(42,46,67,0.6)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M1.333,1.333,0,0"
              transform="translate(13.333 13.333)"
              fill="none"
              stroke="rgba(42,46,67,0.6)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H16V16H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SearchIcon;
