import React, {useState} from "react";
import SideBar from "../sidebar/SideBar";
import { BiShareAlt } from "react-icons/bi";
import LongArrowLeftIcon from "../../../components/icons/LongArrowLeftIcon";
import HorrizontalDottedIcon from "../../../components/icons/HorrizontalDottedIcon";
import cliqfreakheroImage from "../../../assets/home-img-1.png";
import sendIconImage from "../../../assets/img/sendicon-img.png";
import { Link } from "react-router-dom";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import profileImage from "../../../assets/profile-img.png";
import FieldInput from "../../../components/common/FieldInput";
import PeopleIcon from "../../../components/icons/PeopleIcon";
import LikeIcon from "../../../components/icons/LikeIcon";
import MessageIcon from "../../../components/icons/MessageIcon";
import SmallGlobalIcon from "../../../components/icons/SmallGlobalIcon";
import EyeIcon from "../../../components/icons/EyeIcon";
import Button from "../../../components/common/Button";
import DeletePost from "./DeletePost";

const Post = () => {
    const [showDeletePostPopUp, setShowDeletePostPopUp] = useState(false)
  return (
    <div>
      <div>
        <SideBar />
        <div>
          <main className="w-[85% ml-[15%] bg-fcGray6 px-60 py-20">
            <div>
              <nav className="flex items-center justify-between bg-fcGray6 sticky top-0 mb-10 py-5  z-50">
                <div className="flex items-center space-x-3">
                  <div className="bg-white p-2 rounded-full">
                    <LongArrowLeftIcon onclick={() =>setShowDeletePostPopUp(!showDeletePostPopUp)} className=" cursor-pointer" />
                    <DeletePost showDeletePostPopUp={showDeletePostPopUp} setShowDeletePostPopUp={setShowDeletePostPopUp} />
                  </div>
                  <h1 className="text-2xl font-GilroySemiBold">Post</h1>
                </div>
              </nav>

              <div className="flex space-x-9">
                <div className="w-4/6">
                  <div className="bg-white p-5 rounded-xl mb-2">
                    <div className="flex items-center space-x-4 mb-3">
                      <img src={profileImage} alt="" className="w-10 h-10" />
                      <h1 className="text-base font-GilroyMedium">
                        Jane Brown .
                      </h1>
                      <p className="text-sm font-GilroyMedium">12h</p>
                      <PeopleIcon />
                    </div>
                    <p className="text-base font-GilroyRegular mb-4">
                      A communities of Fitness freaks. Taking fitness to a whole
                      new level. We are committed to keeping body mind, body and
                      soul healthy and fit.
                    </p>
                    <div>
                      <img
                        src={cliqfreakheroImage}
                        alt=""
                        className="w-full h-[500px] rounded-xl"
                      />
                    </div>
                    <div className="flex items-center space-x-20 p-5">
                      <LikeIcon className="text-white cursor-pointer" />
                      <MessageIcon className="cursor-pointer" />
                      <BiShareAlt className="cursor-pointer" />
                    </div>
                    <div className="text-sm font-GilroyRegular flex items-start justify-between">
                      <div className="flex items-center space-x-2">
                        <LikeIcon className="text-fcRed2 h-5 w-5" />
                        <p>James and 2 others</p>
                      </div>
                      <p>10 comments</p>
                    </div>
                  </div>

                  <div className="bg-white flex items-center rounded-xl p-5 mb-5">
                    <div>
                      <img src={profileImage} alt="" className="mr-4" />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between border-none outline-none p-4 bg-fcGray6 w-full rounded-full ">
                        <p className="text-base font-GilroyMedium">
                          This is your comment
                        </p>
                        <img
                          src={sendIconImage}
                          alt=""
                          className="h-5 w-5 cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-5 rounded-2xl">
                    <h1 className="text-xl font-GilroySemiBold mb-10">
                      Comments (10)
                    </h1>
                    <div className="mb-10">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4 mb-3">
                          <img
                            src={profileImage}
                            alt=""
                            className="w-10 h-10"
                          />
                          <h1 className="text-base font-GilroyMedium">
                            Jane Brown .
                          </h1>
                          <p className="text-sm font-GilroyMedium">12h</p>
                        </div>
                        <div>
                          <LikeIcon className="text-fcRed2 h-5 w-5" />
                          <p className="text-xs font-GilroyMedium text-fcGray">
                            3
                          </p>
                        </div>
                      </div>
                      <p className="text-base font-GilroyRegular">
                        A communities of Fitness freaks. Taking fitness to a
                        whole new level. We are committed to keeping body mind,
                        body and soul healthy and fit.
                      </p>
                    </div>
                    <div className="mb-10">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4 mb-3">
                          <img
                            src={profileImage}
                            alt=""
                            className="w-10 h-10"
                          />
                          <h1 className="text-base font-GilroyMedium">
                            Jane Brown .
                          </h1>
                          <p className="text-sm font-GilroyMedium">12h</p>
                        </div>
                        <div>
                          <LikeIcon className="text-white h-5 w-5" />
                          <p className="text-xs font-GilroyMedium text-fcGray">
                            3
                          </p>
                        </div>
                      </div>
                      <p className="text-base font-GilroyRegular">
                        A communities of Fitness freaks. Taking fitness to a
                        whole new level. We are committed to keeping body mind,
                        body and soul healthy and fit.
                      </p>
                    </div>
                    <div className="mb-10">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4 mb-3">
                          <img
                            src={profileImage}
                            alt=""
                            className="w-10 h-10"
                          />
                          <h1 className="text-base font-GilroyMedium">
                            Jane Brown .
                          </h1>
                          <p className="text-sm font-GilroyMedium">12h</p>
                        </div>
                        <div>
                          <LikeIcon className="text-white h-5 w-5" />
                          <p className="text-xs font-GilroyMedium text-fcGray">
                            3
                          </p>
                        </div>
                      </div>
                      <p className="text-base font-GilroyRegular">
                        A communities of Fitness freaks. Taking fitness to a
                        whole new level. We are committed to keeping body mind,
                        body and soul healthy and fit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="bg-white p-5 rounded-2xl mb-8">
                    <h1 className="text-xl font-GilroySemiBold py-5">
                      Relevant people
                    </h1>
                    <div className="flex items-center space-x-4 mb-3">
                      <img src={profileImage} alt="" className="w-10 h-10" />
                      <div>
                        <h1 className="text-base font-GilroyMedium">
                          Jane Brown
                        </h1>
                        <p className="text-sm font-GilroyRegular text-fcGray">
                          Lekki, Nigeria
                        </p>
                      </div>
                    </div>
                    <div>
                      <Button className="w-full bg-fcGray6 flex items-center justify-center p-4 rounded-full text-xs font-GilroyMedium">
                        <AddUserIcon /> Add
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Post;
