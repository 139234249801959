import React, { useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import LogoIcon from '../../../components/icons/LogoIcon';
import { moreMenuElements, sidebarElements } from './sidebarElements';
import profileImage from '../../../assets/profile-img.png';
import addIconImg from '../../../assets/addicon.png';
import DropdownModal from '../../../components/dropdown/DropdownModal';
import Button from '../../../components/common/Button';
import CreateCliq from '../cliq/createCliq';
import DottedIcons from '../../../components/icons/DottedIcons';
import { HiOutlineX } from 'react-icons/hi';

import WeightMeterIcon from '../../../components/icons/WeightMeterIcon';
import EditIcon from '../../../components/icons/EditIcon';
import FlashIcon from '../../../components/icons/FlashIcon';
import PeopleIcon from '../../../components/icons/PeopleIcon';

const SideBar = () => {
  const [showAddContent, setShowAddContent] = useState(false);
  const [showCreateCliqPopUp, setShowCreateCliqPopUp] = useState(false);
  const [showMoreMenuItems, setShowMoreMenuItems] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  // const [isActive, setIsActive] = React.useState(false);
  const { pathname } = useLocation();
  return (
    <div className=" shadow-md fixed h-screen py-8 px-4 bg-fcWhite w-[18%]">
      <div className="mb-8">
        <Link to="/">
          <LogoIcon />
        </Link>
      </div>
      <nav className="">
        <div className="h-screen flex-col justify-between space-y-4">
          {sidebarElements.map((items, uid) => (
            <NavLink
              to={items.url}
              key={uid}
              className={({ isActive }) => {
                return (
                  'flex items-center [&:not(:last-child)]:mb-1 text-xl font-GilroyRegular' +
                  (isActive === true ? 'text-fcPrimary' : 'text-fcGray')
                );
              }}
            >
              <div className="mr-3 ">
                {pathname === items.url ? items.iconActive : items.icon}
              </div>
              <p
                className={` ${
                  pathname === items.url
                    ? 'text-fcPrimary font-GilroySemiBold text-lg '
                    : 'font-GilroyRegular text-fcNavy text-lg hover:text-fcPrimary'
                }`}
              >
                {items.title}
              </p>
            </NavLink>
          ))}
          {/* <NavLink to="/dashboard" className="flex items-center">
            <div className="mr-3 ">
              <ProfileCircleActive />
            </div>
            <p
              className={`font-GilroyRegular text-base ${
                pathname === '/dashboard' ? 'text-fcPrimary' : 'text-fcNavy'
              }`}
            >
              For you
            </p>
          </NavLink> */}
          <div className="relative">
            <div
              onClick={() => setShowMoreMenuItems(!showMoreMenuItems)}
              className="flex items-center mb-7 cursor-pointer text-xl font-GilroyRegular hover:bg-fcGray3 w-max rounded-full"
            >
              <DottedIcons className="mr-3" />
              <p className="font-GilroyRegular text-fcNavy text-base">More</p>
              <DropdownModal
                className="absolute z-50 bg-white bottom-10"
                show={showMoreMenuItems}
              >
                {moreMenuElements.map((items, uid) => (
                  <div
                    key={uid}
                    className="flex items-center mb-2 text-base font-GilroyRegular hover:bg-fcGray3 w-max p-1 rounded-full cursor-pointer"
                  >
                    <div className="mr-3">{items.icon}</div>
                    <Link to={items.url}>{items.title}</Link>
                  </div>
                ))}
              </DropdownModal>
            </div>
          </div>
          <div className="relative">
            <Button
              onClick={() => setShowAddContent(!showAddContent)}
              className="bg-fcPrimary flex items-center w-full p-6 rounded-full text-fcWhite text-[26px] font-GilroySemiBold h-[52px]"
            >
              <img src={addIconImg} alt="" className="mr-4 w-6 h-6" />{' '}
              <span className="text-[18px]">Add</span>
            </Button>
            <DropdownModal className="absolute" show={showAddContent}>
              <main className="w-60">
                <div
                  className="flex items-center mb-2 text-base font-GilroyRegular cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <WeightMeterIcon />
                  <p className="ml-4">Add Weight</p>
                </div>
                {showModal ? (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className="relative w-auto my-6 mx-auto max-w-2xl">
                        {/*content*/}
                        <div className="">
                          <div className="relative w-full max-w-3xl mx-auto bg-white rounded-lg rounded-t-xl shadow-lg my-auto ">
                            <div className="">
                              <div className=" bg-white  items-center justify-between  flex p-2">
                                <p className="font-bold text-2xl">Add Weight</p>
                                <HiOutlineX
                                  onClick={() => setShowModal(false)}
                                  className="text-2xl"
                                />
                              </div>

                              <div className="mx-2">
                                <input
                                  name="Date"
                                  type=""
                                  placeholder="Date"
                                  className="form-control mt-4  w-full  h-14 opacity-100 border border-fcGray4 rounded-xl p-2 text-base mb-1 items-center justify-center  outline-none shadow-none"
                                />

                                <input
                                  name="name"
                                  type="name"
                                  placeholder="Target Areas"
                                  className="form-control mt-2  w-full  h-14 opacity-100 border border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                                />
                                <input
                                  name="name"
                                  type="name"
                                  className="form-control  w-full  h-14 opacity-100 border-none border-fcGray4 rounded-xl p-2 text-sm mb-1 items-center justify-center  outline-none shadow-none"
                                />
                              </div>
                              <div className="flex justify-end bg-fcGray3  py-2">
                                <Link to="/dashboard/challenge">
                                  <button
                                    onClick={() => {
                                      setShowModal(true);
                                    }}
                                    className="text-[15px] bg-black text-white rounded-full mr-2 px-4 py-1  my-2"
                                  >
                                    Add
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                ) : null}
                <div
                  className="flex items-center mb-2 text-base font-GilroyRegular cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <EditIcon />
                  <p className="ml-4">Add Activity</p>
                </div>

                <div className="flex items-center mb-2 text-base font-GilroyRegular cursor-pointer">
                  <FlashIcon />
                  <Link to="/dashboard/challenge/new">
                    <p className="ml-4">Start a new Challenge</p>
                  </Link>
                </div>
                <div
                  onClick={() => setShowCreateCliqPopUp(true)}
                  className="flex items-center text-base font-GilroyRegular cursor-pointer"
                >
                  <PeopleIcon />
                  <p className="ml-4">Create a Cliq</p>
                  <CreateCliq
                    showCreateCliqPopUp={showCreateCliqPopUp}
                    setShowCreateCliqPopUp={setShowCreateCliqPopUp}
                  />
                  {/* <Modal
                    showCreateCliqPopUp={showCreateCliqPopUp}
                    setShowCreateCliqPopUp={setShowCreateCliqPopUp}
                  ></Modal> */}
                </div>
              </main>
            </DropdownModal>
          </div>
        </div>
        <div className="flex items-center absolute bottom-4 bg-fcGray6 py-2 rounded-full w-11/12 h-[72px]">
          <img src={profileImage} alt="" className="mr-3" />
          <div className="w-full">
            <Link to="/dashboard/profile">
              <p className="text-base font-GilroySemiBold">Adedapo Adeshina</p>
              <p className="text-sm font-GilroyRegular">My profile</p>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideBar;
