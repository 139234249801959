import React from "react";

const EmptyBoxMessage = ({ className }) => {
  return (
    <div className={className}>
      <div className="flex items-center justify-center w-full h-[77vh]">
        <div>
          <h1 className="text-xl font-GilroySemiBold mb-1">No messages yet</h1>
          <p className="text-sm font-GilroyMedium text-fcGray">
            Your messages would display here
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyBoxMessage;
