import DiscoverIcon from "../../../components/icons/DiscoverIcon";
import DiscoverIconActive from "../../../components/icons/DiscoverIconActive";
import FlashIcon from "../../../components/icons/FlashIcon";
import FlashIconActive from "../../../components/icons/FlashIconActive";
import FriendsIcon from "../../../components/icons/FriendsIcon";
import FriendsIconActive from "../../../components/icons/FriendsIconActive";
import ProfileCircle from '../../../components/icons/ProfileCircle';
import ProfileCircleActive from '../../../components/icons/ProfileCircleActive';
import MessageIcon from "../../../components/icons/MessageIcon";
import MessageIconActive from "../../../components/icons/MessageIconActive";
import Notification from "../../../components/icons/Notification";
import NotificationActive from "../../../components/icons/NotificationActive";
import PeopleIcon from "../../../components/icons/PeopleIcon";
import PeopleIconActive from "../../../components/icons/PeopleIconActive";
import ChartIcon from "../../../components/icons/ChartIcon";
import UserIcon from "../../../components/icons/UserIcon";
import ShopIcon from "../../../components/icons/ShopIcon";
import HelpMessageIcon from "../../../components/icons/HelpMessageIcon";


export const sidebarElements = [
    {
        icon: <ProfileCircle />,
        iconActive: <ProfileCircleActive />,
        title: "For you",
        url: "/dashboard",
        id: 0
    },
    {
        icon: <DiscoverIcon />,
        iconActive: <DiscoverIconActive />,
        title: "Discover",
        url: "/dashboard/discover",
        id: 1
    },
    {
        icon: <Notification />,
        iconActive: <NotificationActive />,
        title: "Notifications",
        url: "/dashboard/notification",
        id: 2
    },
    {
        icon: <MessageIcon />,
        iconActive: <MessageIconActive />,
        title: "Messages",
        url: "/dashboard/messages",
        id: 3
    },
    {
        icon: <FlashIcon />,
        iconActive: <FlashIconActive />,
        title: "Challenges",
        url: "/dashboard/challenge",
        id: 4
    },
    {
        icon: <PeopleIcon />,
        iconActive: <PeopleIconActive />,
        title: "Cliqs",
        url: "/dashboard/cliq",
        id: 5
    },
    {
        icon: <FriendsIcon />,
        iconActive: <FriendsIconActive />,
        title: "Friends",
        url: "/dashboard/friends",
        id: 6
    }
];

export const moreMenuElements = [
    {
        icon: <ChartIcon />,
        title: "My Statistics",
        url: "/dashboard/statistics"
    },
    {
        icon: <UserIcon />,
        title: "Coaches",
        url: "/dashboard/coach"
    },
    {
        icon: <ShopIcon />,
        title: "Store",
        url: ''
    },
    {
        icon: <HelpMessageIcon />,
        title: "Help",
        url: ''
    },
    {
        icon: <MessageIcon />,
        title: "Leave Feedback",
        url: ''
    }
];