import React from "react";
import redlogo from "../../assets/redlogo.png";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding4 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">
              <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step3">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>
          </div>
          <div className="text-center mb-8 ">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">When is your birthday?</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              We will use it to personalize your fitness plan
            </p>
          </div>
          <div className="py-6 mx-auto w-[460px] items-center justify-center gap-2 flex font-GilroyRegular">
            <div className="text-[14px] text-fcGray2 grid ">
              <label>Day</label>
              <input
                className="focus:outline-0  w-12 p-2 text-center border-none bg-fcGray3 opacity-1 mt-2    rounded-md "
                placeholder="DD"

              />
            </div>
            <div className="text-[14px] text-fcGray2 grid">
              <label>Month</label>
              <input
                className="focus:outline-0  w-12 p-2 text-center border-none bg-fcGray3 rounded-md  mt-2   "
                placeholder="MM"
                type="month"

              />
            </div>
            <div className="text-[14px] text-fcGray2 grid ">
              <label>Year</label>
              <input
                className="focus:outline-0  w-12 p-2 text-center border-none bg-fcGray3 rounded-md  mt-2 "
                placeholder="YYYY"

              />
            </div>
          </div>
            <p className="text-sm font-GilroyRegular text-center block">You’ll be <span className="font-bold">25yrs</span> on the 24th of Nov 2023</p>
          <a href="/onboard/step5">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding4;
