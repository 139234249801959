import React from "react";

const EmailNotification = () => {
  return (
    <div className="">
      <div className="bg-white   rounded-t-md pb-6 rounded-xl  ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Feedback Emails
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">Give feedback on Fitcliq.</p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-6 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Reminder Emails
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            Get notifications you may have missed.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Product Emails
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            Get tips and resources about Fitcliq’s tools.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            News Emails
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            Learn about new Fitcliq features.
          </p>
        </div>
      </div>
      <div className="bg-white   rounded-t-md pb-6 rounded-xl mt-4 ">
        <div className="px-4">
          <p className="text-xl text-fcDark font-GilroySemiBold py-4">
            Support Emails
          </p>

          <div className="">
            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy">off</p>
            </div>
            <div className="flex items-center gap-4 my-4">
              <input
                type="radio"
                name="people"
                className="accent-fcPrimary cursor-pointer h-5 w-5"
              />
              <p className="text-base font-GilroyRegular text-fcNavy ">on</p>
            </div>
          </div>
          <p className="text-fcGray text-sm">
            Get updates on reports and violations of our Community Guidelines.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailNotification;
