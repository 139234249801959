import React from "react";
import SideBar from "../sidebar/SideBar";
import { BiShareAlt } from "react-icons/bi";
import LongArrowLeftIcon from "../../../components/icons/LongArrowLeftIcon";
import HorrizontalDottedIcon from "../../../components/icons/HorrizontalDottedIcon";
import cliqfreakheroImage from "../../../assets/home-img-1.png";
import { Link } from "react-router-dom";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import profileImage from "../../../assets/profile-img.png";
import FieldInput from "../../../components/common/FieldInput";
import PeopleIcon from "../../../components/icons/PeopleIcon";
import LikeIcon from "../../../components/icons/LikeIcon";
import MessageIcon from "../../../components/icons/MessageIcon";
import SmallGlobalIcon from "../../../components/icons/SmallGlobalIcon";
import EyeIcon from "../../../components/icons/EyeIcon";
import { useState } from "react";
import CreatePost from "./CreatePost";
import TagPeople from "./TagPeople";
import CreatedPost from "./CreatedPost";
import Modal from "../../../components/common/Modal";

const CliqPage = () => {
  const [showCreatePostPopUp, setShowCreatePostPopUp] = useState(false);
  const [selectFile, setSelectFile] = useState([]);

  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85% ml-[15%] bg-fcGray6 px-60 py-20">
          <div>
            <nav className="flex items-center justify-between bg-fcGray6 sticky top-0 mb-10 py-5  z-50">
              <div className="flex items-center space-x-3">
                <div className="bg-white p-2 rounded-full">
                  <LongArrowLeftIcon className=" cursor-pointer" />
                </div>
                <h1 className="text-2xl font-GilroySemiBold">Fitness Freaks</h1>
              </div>
              <div className="flex items-center space-x-3">
                <div className="bg-white p-2 rounded-full">
                  {" "}
                  <BiShareAlt className="cursor-pointer" />
                </div>
                <div className="bg-white p-2 rounded-full">
                  {" "}
                  <HorrizontalDottedIcon className="cursor-pointer" />
                </div>
              </div>
            </nav>
            <div className="bg-white rounded-b-xl">
              <div className="bg-fcGray6 mb-8">
                <img
                  src={cliqfreakheroImage}
                  alt=""
                  className="w-full rounded-tl-xl rounded-tr-xl h-96 -mt-5"
                />
              </div>
              <div className="px-5 py-5 mb-10 ">
                <h1 className="text-2xl font-GilroySemiBold mb-8">
                  Fitness Freaks
                </h1>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <img src={profileImage} alt="" />
                      <img src={profileImage} alt="" className="-ml-6" />
                      <img src={profileImage} alt="" className="-ml-6" />
                      <img src={profileImage} alt="" className="-ml-6" />
                    </div>
                    <div className="flex items-center -ml-6">
                      <p className="text-white bg-fcPrimary p-2 font-GilroyMedium rounded-full text-base">
                        +6.3k
                      </p>
                    </div>
                    <p className="text-base font-GilroyMedium ml-3">
                      63,567 members
                    </p>
                  </div>
                  <div className="text-white">
                    <Link
                      to=""
                      className="flex items-center  py-2 px-3 text-sm font-GilroyMedium rounded-full space-x-3 bg-fcPrimary"
                    >
                      <AddUserIcon /> <span>Invite</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-9">
              <div className="w-4/6">
                <div className="bg-white flex items-center rounded-xl p-4 mb-5 h-[72px]">
                  <div>
                    <img src={profileImage} alt="" className="mr-4" />
                  </div>
                  <div
                    onClick={() => setShowCreatePostPopUp(!showCreatePostPopUp)}
                    className="w-full"
                  >
                    <FieldInput
                      type="text"
                      name="post"
                      id="post"
                      placeholder="Share updates, photos and more…"
                      className="border-none outline-none p-4 bg-fcGray6 w-full rounded-full cursor-pointer "
                    />
                  </div>
                    <CreatePost
                      showCreatePostPopUp={showCreatePostPopUp}
                      setCreatePostPopUp={setShowCreatePostPopUp}
                      selectFile={selectFile}
                      setSelectFile={setSelectFile}
                    />
                  {/* <Modal
                    show={showCreatePostPopUp}
                    setCreatePostPopUp={setShowCreatePostPopUp}
                  >
                    ifgidshcsd
                  </Modal> */}
                </div>
                <div className="bg-white p-4 rounded-xl">
                  <div className="flex items-center space-x-4 mb-3">
                    <img src={profileImage} alt="" className="w-10 h-10" />
                    <h1 className="text-base font-GilroyMedium">
                      Jane Brown .
                    </h1>
                    <p className="text-sm font-GilroyMedium">12h</p>
                    <PeopleIcon />
                  </div>
                  <p className="text-base font-GilroyRegular mb-4">
                    A communities of Fitness freaks. Taking fitness to a whole
                    new level. We are committed to keeping body mind, body and
                    soul healthy and fit.
                  </p>
                  <div>
                    <img
                      src={cliqfreakheroImage}
                      alt=""
                      className="w-full h-[500px] rounded-xl"
                    />
                  </div>
                  <div className="flex items-center space-x-20 p-5">
                    <LikeIcon className="text-white cursor-pointer" />
                    <MessageIcon className="cursor-pointer" />
                    <BiShareAlt className="cursor-pointer" />
                  </div>
                  <div className="text-sm font-GilroyRegular flex items-start justify-between">
                    <div className="flex items-center space-x-2">
                      <LikeIcon className="text-red-700" />
                      <p>James and 2 others</p>
                    </div>
                    <p>10 comments</p>
                  </div>
                </div>
              </div>
              <div className="w-1/3">
                <div className="bg-white p-4 rounded-xl mb-8">
                  <h1 className="text-xl font-GilroySemiBold py-5">About</h1>
                  <p className="text-base fobt-GilroyRegular mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Labore vero laborum quia ipsam sed blanditiis aspernatur
                    quasi ea hic? Sapiente.
                  </p>
                  <Link to="" className="underline text-base font-GilroyMedium">
                    See More
                  </Link>
                  <div className="flex space-x-4 mt-3">
                    <SmallGlobalIcon />
                    <div>
                      <p className="text-base font-GilroyMedium">Public</p>
                      <p className="text-base font-GilroyRegular">
                        Anyone can see who’s in the cliq and what they post.
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-4 mt-3">
                    <EyeIcon />
                    <div>
                      <p className="text-base font-GilroyMedium">Visible</p>
                      <p className="text-base font-GilroyRegular">
                        Anyone can see who’s in the cliq and what they post.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl">
                  <h1 className="text-xl font-GilroySemiBold py-5">
                    Group activity
                  </h1>
                  <div className="flex items-center space-x-4 mb-3">
                    <MessageIcon />
                    <p className="text-xs font-GilroyRegular">
                      35 in the last 25 days
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <PeopleIcon />
                    <p className="text-xs font-GilroyRegular">
                      2.2k total members
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CliqPage;
