import React from "react";
import Don from "../../../assets/Don.png";
import Eloho from "../../../assets/Eloho.png";
import Charles from "../../../assets/Charles.png";
import Tola from "../../../assets/Tola.png";
import { Link } from "react-router-dom";

const AllCoaches = () => {
  const arr1 = [
    {
      img: Don,
      name: "Dan Steve",
    },
    {
      img: Eloho,
      name: "Eloho Adeleke",
    },

    {
      img: Charles,
      name: "Charles Garuba",
    },

    {
      img: Tola,
      name: "Tola Adeyemo",
    },
  ];
  return (
    <div>
      <div>
        {arr1.map((e, i) => {
          return (
            <div key={i} className=" text-center items-center">
              <p className=" font-GilroyMedium ">{e.title}</p>
              <p className=" text-sm text-[#2A2E43CC] ">{e.subtitle}</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-around items-center my-8 ">
        <a href="/dashboard/coach/details">
          <div className=" items-center  ">
            <img
              src={Don}
              alt="don"
              className="items-center justify-center mx-auto"
            />
            <p className="text-center font-GilroyMedium text-fcDark text-base">
              Dan Steve
            </p>
          </div>
        </a>
        <div>
          <img
            src={Eloho}
            alt="don"
            className="items-center justify-center mx-auto"
          />
          <p className="text-center font-GilroyMedium text-fcDark text-base">
            Eloho Adeleke
          </p>
        </div>
        <div>
          <img
            src={Charles}
            alt="don"
            className="items-center justify-center mx-auto"
          />
          <p className="text-center font-GilroyMedium text-fcDark text-base">
            Charles Garuba
          </p>
        </div>
        <div>
          <img
            src={Tola}
            alt="don"
            className="items-center justify-center mx-auto"
          />
          <p className="text-center font-GilroyMedium text-fcDark text-base">
            Tola Adeyemo
          </p>
        </div>
      </div>
    </div>
  );
};

export default AllCoaches;
