import React from 'react';
import profileImageOne from "../../assets/img/profile-1.png";
import profileImageTwo from "../../assets/img/profile-2.png";
import profileImageThree from "../../assets/img/profile-3.png";
import whiteUserIcon from "../../assets/img/whiteuserIcon.png";
import {  Link } from 'react-router-dom';


function ChallengeCard() {
    return (
        <div id="emailbg-1" className=" p-6 rounded-xl ">
            <div class=" w-[350px] h-[218px] flex items-end">
                <div className="flex flex-col justify-between">
                    <Link to="/dashboard/challenge/124">
                    
                    <div>
                        <p class="text-xs text-white font-GilroyRegular mb-1">
                            10 days + 8 workouts hel
                        </p>
                        <p class="text-base text-white font-GilroySemiBold mb-1">
                            6-pack ABS with Alper
                        </p>
                    </div>
                    </Link>
                    <div class="flex items-center justify-between mt-3 w-full">
                        <div class="flex">
                            <img src={profileImageOne} alt="" class="" />
                            <img src={profileImageTwo} alt="" class="-ml-2" />
                            <img src={profileImageThree} alt="" class="-ml-2" />
                        </div>
                        <div class="flex items-center justify-between text-white bg-fcPrimary px-3 py-1 rounded-full space-x-2">
                            <a href="/">
                                <img src={whiteUserIcon} alt="" />
                            </a>
                            <span>Join</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChallengeCard;