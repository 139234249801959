import React from "react";

const ProfileCircle = ({className}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={className}
      >
        <g
          id="vuesax_linear_profile-circle"
          data-name="vuesax/linear/profile-circle"
          transform="translate(-492 -252)"
        >
          <g id="profile-circle">
            <path
              id="Vector"
              d="M3.4,6.55a.963.963,0,0,0-.24,0,3.28,3.28,0,1,1,.24,0Z"
              transform="translate(500.72 258.23)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M13.48,3.945a9.934,9.934,0,0,1-6.74,2.62A9.934,9.934,0,0,1,0,3.945a3.679,3.679,0,0,1,1.77-2.58,9.73,9.73,0,0,1,9.94,0A3.679,3.679,0,0,1,13.48,3.945Z"
              transform="translate(497.26 267.435)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10Z"
              transform="translate(494 254)"
              fill="none"
              stroke="#2a2e43"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(516 276) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default ProfileCircle;
