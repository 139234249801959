import React from "react";

const TextAreaField = ({
  name,
  id,
  cols,
  rows,
  placeholder,
  className,
  onChange,
  value,
}) => {
  return (
    <div>
      <textarea
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        className={className}
      ></textarea>
    </div>
  );
};

export default TextAreaField;
