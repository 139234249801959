import React from "react";
import { useState } from "react";
import Modal from '../../../../components/common/Modal';
import CreateCliqInfo from "./CreateCliqInfo";
import CreateYourCliq from "./CreateYourCliq";
import CropImage from "./CropImage";
import CloseIcon from '../../../../components/icons/CloseIcon';
import InviteFriends from "./InviteFriends";

const CreateCliq = ({ showCreateCliqPopUp, setShowCreateCliqPopUp }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadFile, setUploadFile] = useState([]);
  const [formData, setFormData] = useState({ name: "", description: "" });

  const displayCreateCliqContent = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <CreateCliqInfo
            setCurrentStep={setCurrentStep}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <CropImage setCurrentStep={setCurrentStep} uploadFile={uploadFile} />
        );
      case 3:
        return (
          <CreateYourCliq
            setCurrentStep={setCurrentStep}
            formData={formData}
            setFormData={setFormData}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
          />
        );
      case 4:
        return (
          <InviteFriends
            setCurrentStep={setCurrentStep}
            formData={formData}
            setFormData={setFormData}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <Modal
        show={showCreateCliqPopUp}
        setShowCreateCliqPopUp={setShowCreateCliqPopUp}
      >
        <nav className="flex items-center justify-between py-3 mb-5">
          <p className="text-2xl font-GilroyBold">Create your cliq</p>
          <CloseIcon
            onClick={() => setShowCreateCliqPopUp(false)}
            className="h-5 w-5 cursor-pointer"
          />
        </nav>
        <main>{displayCreateCliqContent(currentStep)}</main>
      </Modal>
    </>
  );
};

export default CreateCliq;
