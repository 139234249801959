import React, { useState } from "react";
import TextAreaField from "../../../../components/common/TextAreaField";
import GalleryAddIcon from "../../../../components/icons/GalleryAddIcon";
import Button from "../../../../components/common/Button";
import FieldInput from "../../../../components/common/FieldInput";

const CreateYourCliq = ({ uploadFile, setCurrentStep, formData }) => {
  return (
    <>
      <main>
        <form>
          <div className="bg-fcGray7 border border-fcPrimary mb-10 rounded-xl">
            <div className="h-64 flex items-center justify-center">
              <img
                src={uploadFile}
                alt=""
                className="w-full h-full rounded-xl"
              />
            </div>
          </div>
          <div className="mb-5">
            <div className="border rounded-xl border-fcGray4 w-full p-4 mb-10 outline-none">
              {formData.name}
            </div>
            <div className="border border-fcGray4 w-full rounded-xl font-GilroyRegular text-base outline-none p-4">
              {formData.description}
            </div>
          </div>
          <div className="flex items-center justify-between mb-5">
            <div>
              <p className="font-GilroyMedium text-base">Make private</p>
              <p className="font-GilroyRegular text-sm">
                When you make a Cliq private, only you can see it.
              </p>
            </div>
            <div>
              <FieldInput type="checkbox" className="h-5 w-5 cursor-pointer" />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button
              type="submit"
              onClick={() => setCurrentStep(4)}
              className="bg-fcDark text-white w-[66px] font-GilroySemiBold text-base h-8 rounded-full"
            >
              Next
            </Button>
          </div>
        </form>
      </main>
    </>
  );
};

export default CreateYourCliq;
