import React from "react";
import profileImageOne from "../../../assets/img/profile-1.png";
import profileImageTwo from "../../../assets/img/profile-2.png";
import profileImageThree from "../../../assets/img/profile-3.png";
import challengeCardImage from "../../../assets/img/emailbg-1.png";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const BrowseChallengeCard = () => {
  const [joinChallenge, setJoinChallenge] = useState(false);
  const navigate = useNavigate()
  return (
    <div>
      <div onClick={() =>navigate('joinchallenge')} className=" cursor-pointer p-2 rounded-xl relative bg-gradient-to-b(180deg, #00000000 0%, #ee5014 100%) mb-5">
        <div className="h-[350px] bg-gradient-to-r ">
          <img
            src={challengeCardImage}
            alt=""
            className="  rounded-md h-full w-full"
          />
        </div>
        <div class="absolute bottom-8 w-[98%] px-2">
          <p class="text-[12px] text-white font-GilroyMedium mb-1">
            10 days + 8 workouts
          </p>
          <p class="text-[16px] text-white font-GilroySemiBold mb-1">
            6-pack ABS with Alper
          </p>
          <div class="flex items-center justify-between">
            <div class="flex">
              <img src={profileImageOne} alt="" class="" />
              <img src={profileImageTwo} alt="" class="" />
              <img src={profileImageThree} alt="" class="" />
            </div>
            <div onClick={() => setJoinChallenge(true)} >
              <Link
                to=""
                className={`flex items-center text-white px-3 py-1 rounded-full ${
                  joinChallenge ? "bg-fcPrimary" : "bg-[#CC6841]"
                }`}
              >
                <AddUserIcon className="mr-2" />
                <span className={`${joinChallenge ? "hidden" : "block"}`}>
                  Join
                </span>
                <span className={`${joinChallenge ? "block" : "hidden"}`}>
                  Joined
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowseChallengeCard;
