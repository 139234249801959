import React from "react";
import profileImage from "../../../assets/profile-img.png";
import Abburner from "../../../assets/Ab-burner.png";
import LikeIcon from "../../../components/icons/LikeIcon";

const JoinChallengeProgressCard = () => {
  return (
    <div className="cursor-pointer py-2">
      <div className="w-36">
        <img src={Abburner} alt="ab" className="w-full" />
      </div>
      <div className="flex items-center gap-2 my-1 w-full">
        <img src={profileImage} alt="" className="w-8 h-8 rounded-full" />
        <p className=" font-GilroyMedium text-base">Sheriff</p>
      </div>
      <div className="flex items-center gap-5 w-full">
        <div className="text-transparent">
          <LikeIcon />
        </div>
        <div className="flex items-center ">
          <LikeIcon className="text-fcRed2" />
          <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">120</p>
        </div>
      </div>
    </div>
  );
};

export default JoinChallengeProgressCard;
