import React from "react";
import Logo from "../Logo";
import redlogo from "../../../src/assets/redlogo.png"
import { HiArrowRight } from "react-icons/hi";

const Header = () => {
  return (
    <div>
      <div className="bg-gray-100 mb-24 ">
        <header className="fixed top-0 z-30 w-full px-2 py-4 bg-white sm:px-4 ">
          <div className="flex items-center justify-between mx-auto max-w-7xl px-8 py-2">
            <div>
              <img src={redlogo} alt="redlogo" className="w-40" />
            </div>
            <div className="flex items-center space-x-1">
              <ul className="hidden space-x-2 md:inline-flex items-center text-fcNavy font-GilroyMedium text-base">
                <li>
                  <a
                    href="#"
                    className="px-4 py-2 font-semibold text-gray-600 rounded"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="px-4 py-2 font-semibold text-gray-600 rounded"
                  >
                    Store
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="px-4 py-2 font-semibold text-gray-600 rounded"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="px-4 py-2 font-semibold text-gray-600 rounded"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="px-4 py-2 font-semibold text-gray-600 rounded"
                  >
                    Blog
                  </a>
                </li>
                <li>
                    <button className="flex items-center bg-primary  rounded-full text-white px-4 py-4 gap-2">Join Now<HiArrowRight/></button>
                </li>
              </ul>
              <div className="inline-flex md:hidden">
                <button className="flex-none px-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                  <span className="sr-only">Open Menu</span>
                </button>
                <ul>
                  <li>
                    <a
                      href="#"
                      className="px-4 py-2 font-semibold text-gray-600 rounded"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="px-4 py-2 font-semibold text-gray-600 rounded"
                    >
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="px-4 py-2 font-semibold text-gray-600 rounded"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="px-4 py-2 font-semibold text-gray-600 rounded"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
