import React from 'react';

const WeightMeterIconOutline = ({ className }) => {
  return (
    <>
      <svg
        id="Group_52182"
        data-name="Group 52182"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="Path_39358"
          data-name="Path 39358"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <rect
          id="Rectangle_3702"
          data-name="Rectangle 3702"
          width="18"
          height="18"
          rx="4"
          transform="translate(3 3)"
          fill="none"
          stroke="#2a2e43"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_39359"
          data-name="Path 39359"
          d="M12,7a6.978,6.978,0,0,1,4.994,2.1L14.038,12a3,3,0,0,0-4.076,0L7.006,9.1A6.979,6.979,0,0,1,12,7Z"
          fill="none"
          stroke="#2a2e43"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default WeightMeterIconOutline;
