import React from "react";

const GoogleFitIcon = (className) => {
  return (
    <>
      <svg
        id="Google_Fit_icon__2018_"
        data-name="Google_Fit_icon_(2018)"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20.321"
        viewBox="0 0 24 20.321"
        className={className}
      >
        <path
          id="Path_38756"
          data-name="Path 38756"
          d="M2.3,10.75l1.209,1.209,2.611-2.6-1.2-1.209L4.369,7.6A2.265,2.265,0,0,1,3.7,5.974a2.214,2.214,0,0,1,.5-1.412,2.3,2.3,0,0,1,3.444-.2l.538.518L9.388,6.1,12.01,3.5,10.79,2.286l-.549-.528A6,6,0,0,0,0,5.984,5.947,5.947,0,0,0,.467,8.311a5.832,5.832,0,0,0,1.29,1.9l.538.538"
          fill="#ea4335"
        />
        <path
          id="Path_38757"
          data-name="Path 38757"
          d="M39.275,66.32,43,62.611,40.383,60l-3.272,3.261h0l-2.611,2.6,1.4,1.412,1.209,1.2,1.26-1.26"
          transform="translate(-30.995 -53.904)"
          fill="#fbbc04"
        />
        <path
          id="Path_38758"
          data-name="Path 38758"
          d="M68.694,100.584,74.587,94.7l-2.621-2.6-5.883,5.873-3.272-3.261-2.611,2.6,3.272,3.272-.01.01,2.621,2.469h0l2.621-2.479h0Z"
          transform="translate(-54.083 -82.742)"
          fill="#34a853"
        />
        <path
          id="Path_38759"
          data-name="Path 38759"
          d="M105.253,10.267a6.244,6.244,0,0,0,1.727-4.979A5.879,5.879,0,0,0,101.433.076a5.993,5.993,0,0,0-4.664,1.737L92.4,6.152l2.611,2.611L99.38,4.414a2.3,2.3,0,1,1,3.251,3.251l-1.737,1.748,2.621,2.6,1.737-1.748Z"
          transform="translate(-83.012 -0.056)"
          fill="#4285f4"
        />
      </svg>
    </>
  );
};

export default GoogleFitIcon;
