import React, { useState } from "react";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import SideBar from "../sidebar/SideBar";

import PlusIcon from "../../../components/icons/PlusIcon";
import PeoplesIcon from "../../../components/icons/PeoplesIcon";

// import Modal from "../../../components/common/Modal";
// import CloseIcon from "../../../components/icons/CloseIcon";
import WhoToFollow from "../../../components/common/WhoToFollow";

import CliqCardMin from "../../../components/common/CliqCardMin";
import CliqCardMax from "../../../components/common/CliqCardMax";

const Cliqs = () => {
  const [toggle, setToggle] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [inviteFriends, setInviteFriends] = useState(false);

  const Jane = "";
  const AccountIcon = " ";
  const AccountIconWhite = "";

  return (
    <div>
      <SideBar />
      <main className="w-[85% ml-[15%] bg-fcGray6 h-full px-40 py-5 ">
        <div className="bg-fcGray6 w-full h-full rounded-3xl">
          <nav className=" p-5 z-20 bg-fcGray6 sticky top-0">
            <p className="text-[25px] font-GilroySemiBold">Cliqs</p>
          </nav>
          <div className="flex gap-8 mt-2">
            <div className="w-[70%] z-10">
              <div className="bg-white p-4 mb-10  rounded-2xl">
                <div className="">
                  <div className="flex justify-between gap-4 items-center">
                    <div>
                      <p className="text-fcDark text-xl font-GilroySemiBold">
                        My Cliqs
                      </p>
                    </div>
                    <div className="flex gap-4">
                      <PlusIcon onClick={() => setToggle(true)} />
                      <ArrowAngleRight />
                    </div>
                  </div>
                  {toggle ? (
                    <div>
                      <div className="mx-auto items-center justify-center flex mt-8">
                        <PeoplesIcon />
                      </div>
                      <div>
                        <p className="text-center text-fcDark text-xl font-GilroySemiBold my-4 ">
                          Your Cliqs
                        </p>
                        <p className="text-center text-fcGray2 text-sm">
                          The cliqs you join and create will
                          <br /> show up here. Click on the “+”
                          <br /> button above to create a cliq.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="bg-white">
                      <div className="flex items-center overflow-x-scroll w-full flex-nowrap gap-2 whitespace-nowrap space-x-2 pb-6  mt-4 ">
                        <CliqCardMin />
                        <CliqCardMin />
                        <CliqCardMin />
                        <CliqCardMin />
                        <CliqCardMin />

                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="bg-white p-4 mb-10  rounded-2xl"
                onClick={() => setToggle(false)}
              >
                <div className="flex justify-between gap-4 items-center mb-5">
                  <div>
                    <p className="text-fcDark text-xl font-GilroySemiBold">Browse Cliqs</p>
                  </div>
                  <div className="">
                    <ArrowAngleRight />
                  </div>
                </div>
                <CliqCardMax />
                <CliqCardMax />
                <CliqCardMax />
                <CliqCardMax />

              </div>
            </div>
            <WhoToFollow />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cliqs;
