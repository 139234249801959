import React, { useState } from "react";
import ChallengeNav from "../../../components/common/ChallengeNav";
import PrimaryButton from "../../../components/common/PrimaryButton";
import workout from "../../../assets/workout.svg";
import Modal from "./Modal";

const Workout = () => {
  const [showModal, setShowModal] = useState(false);
  const arr = [
    {
      title: "Workouts",

      subtitle: "30 Days Challenge",
    },
  ];
  const arr2 = [
    {
      subtitle: "Your workouts",
      description: "The workouts you add to your",
    },
    {
      description: "challenge will show up here",
    },
  ];
  return (
    <div>
      <ChallengeNav />
      <div className="items-center justify-center grid mx-auto pt-5 h-[500px]">
        <div className="py-7">
          <div>
            {arr.map((e, i) => {
              return (
                <div key={i} className=" text-center items-center">
                  <p className=" font-GilroyMedium ">{e.title}</p>
                  <p className=" text-sm text-[#2A2E43CC] ">{e.subtitle}</p>
                </div>
              );
            })}
          </div>

          <div className="pt-9">
            <img
              src={workout}
              alt="workout"
              className="items-center mx-auto py-4"
            />
            {arr2.map((e, i) => {
              return (
                <div key={i} className=" text-center items-center">
                  <p className=" font-GilroyMedium  text-[15px]">
                    {e.subtitle}
                  </p>
                  <p className=" text-[12px] text-[#2A2E43CC] font-GilroyRegular ">
                    {e.description}
                  </p>
                </div>
              );
            })}
          </div>
          <PrimaryButton
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add Workout
          </PrimaryButton>
          {showModal && <Modal setOpenModal={setShowModal} />}
          
        </div>
      </div>
      <div className="">
        <hr className=" text-fcGray4 " />
        <div className="flex items-center justify-center mx-auto gap-4 mt-5  ">
          <button className="px-8 py-2 rounded-full border font-GilroySemi text-sm  text-center">
            Back
          </button>
          <button className="px-6 py-2 rounded-full  font-GilroySemi text-white text-sm text-center bg-slate-400 ">
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Workout;
