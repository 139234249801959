import React from "react";
import redlogo from "../../assets/redlogo.png";
import OptionButton from "../../components/common/OptionButton";
import ArrowLeft from "../../components/icons/ArrowLeft";


const Onboarding13 = () => {
  return (
    <div>
      <section className="p-6">
        <di>
          <img src={redlogo} alt="redlogo" className="w-[100px]" />
        </di>
      </section>
      <section className="flex justify-center items-center  mx-auto w-1/3">
        <div>
          <div>
            <div className=" bg-fcOrange3 opacity-1 h-1">             
            <div className="bg-primary h-1 w-[50px]"></div>
            </div>
            <a href="/onboard/step12">
              <ArrowLeft className="my-4 w-6 h-6 cursor-pointer" />
            </a>

          </div>
          <div className="text-center mb-8">
            <p className="text-2xl font-GilroySemiBold text-fcNavy2">What’s your current level of fitness?</p>
            <p className="text-base pt-2 opacity-100 font-GilroyRegular text-fcNavy2">
              Select any of the levels that fits you
            </p>
          </div>
          <div className="py-4 space-y-4">
            <OptionButton title="Beginner" smallText="New to lifting weights and unfamiliar with most exercises " />
            <OptionButton title="Intermediate" smallText="Familiar with most exercises and comfortable working out " />
            <OptionButton title="Advanced" smallText="Experienced with training and will often help out friends who are starting out" />
          
          </div>
          <a href="/onboard/step14">
            <button className=" bg-primary w-full p-5 rounded-full text-yellow-50 font-GilroySemiBold text-sm my-7 text-center ">
              NEXT STEP
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Onboarding13;
