import React, {useRef, useEffect} from "react";
import ReactDOM from "react-dom";

const Modal = ({ children, show, className, setShowCreateCliqPopUp, setShowCreatePostPopUp, setAddUser, setInviteFriends }) => {
    const menuRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current?.contains(event.target)) {
        // setShowCreateCliqPopUp(false)
        // setShowCreatePostPopUp(false)
        // setAddUser(false)
        setInviteFriends(false)
      }
    });
  });
  if (!show) return null;
  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay bg-fcBlack fixed top-0 left-0 right-0 bottom-0 ">
        <div ref={menuRef} className="fixed md:top-1/2 md:left-1/2 bottom-0 md:bottom-auto z-[1000] px-8 py-4 md:-translate-y-2/4 md:-translate-x-2/4 md:w-2/5 w-full border-none rounded-t-2xl md:rounded-lg  shadow bg-white">
          <main className={className}>{children}</main>
        </div>
      </div>
    </>,
    document.body
  );
};

export default Modal;
