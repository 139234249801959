import React, { useState } from "react";
import profileImage from "../../../assets/profile-img.png";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import Button from "../../../components/common/Button";
import { Link } from "react-router-dom";
import yogaImage from "../../../assets/img/yogga.png";
import emailbgImage from "../../../assets/img/emailbg-3.png";
import RatingFilledIcon from "../../../components/icons/RatingFilledIcon";
import RatingEmptyIcon from "../../../components/icons/RatingEmptyIcon";
import CallingIcon from "../../../components/icons/CallingIcon";
import MapIcon from "../../../components/icons/MapIcon";
import {GrFormCheckmark} from "react-icons/gr"

const TopSearch = () => {
  const [addToCliq, setAddToCliq] = useState(false);
  const [joinCliq, setJoinCliq] = useState(false);
  return (
    <div className="p-4">
      <div className="bg-white rounded-b-xl w-full mb-10">
        <div className="">
          <h1 className="text-xl font-GilroySemiBold mb-4">Who to follow</h1>
          <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
            <Button
              onClick={() => setAddToCliq(!addToCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full ${
                addToCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon />
              <span className={`${addToCliq ? "hidden" : "block"}`}>Add</span>
              <span className={`${addToCliq ? "block" : "hidden"}`}>
                Invited
              </span>
            </Button>
          </div>
          <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
            <Button
              onClick={() => setAddToCliq(!addToCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full ${
                addToCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon />
              <span className={`${addToCliq ? "hidden" : "block"}`}>Add</span>
              <span className={`${addToCliq ? "block" : "hidden"}`}>
                Invited
              </span>
            </Button>
          </div>
          <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
            <Button
              onClick={() => setAddToCliq(!addToCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full ${
                addToCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon />
              <span className={`${addToCliq ? "hidden" : "block"}`}>Add</span>
              <span className={`${addToCliq ? "block" : "hidden"}`}>
                Invited
              </span>
            </Button>
          </div>
          <div className="flex items-center justify-between mb-4 hover:bg-fcGray6 cursor-pointer py-2">
            <div className="flex items-center space-x-3">
              <div>
                <img src={profileImage} alt="" className="h-12 w-12" />
              </div>
              <div>
                <p className="text-base font-GilroyMedium">Adeshina Adedapo</p>
                <p className="text-sm font-GilroyRegular text-fcGray">
                  Lekki, Nigeria
                </p>
              </div>
            </div>
            <Button
              onClick={() => setAddToCliq(!addToCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full ${
                addToCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon />
              <span className={`${addToCliq ? "hidden" : "block"}`}>Add</span>
              <span className={`${addToCliq ? "block" : "hidden"}`}>
                Invited
              </span>
            </Button>
          </div>
          <div className="flex items-center justify-center">
            <Link
              to=""
              className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
            >
              More people to follow
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-xl w-full p-4 mb-10">
        <h1 className="text-xl font-GilroySemiBold mb-4">
          Coaches recommended for you
        </h1>
        <div className="grid grid-cols-4 gap-5 mb-5">
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={profileImage} alt="" className="w-12 h-12" />
            <p className="text-sm font-GilroyMedium">Dan Steve</p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Link
            to=""
            className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
          >
            More people to follow
          </Link>
        </div>
      </div>
      <div className="bg-white rounded-xl w-full p-4 mb-10">
        <h1 className="text-xl font-GilroySemiBold ">Cliqs to join</h1>
        <p className="text-base font-GilroyRegular my-2">
          Check out these popular cliqs just for you
        </p>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex space-x-3">
            <div>
              <img src={yogaImage} alt="" className="h-24 w-24" />
            </div>
            <div>
              <h2 className="text-base font-GilroySemiBold">Yoga Lovers</h2>
              <p className="text-base font-GilroySemiBold">
                A communities of Fitness freaks. Taking fitness to a whole new
                level.
              </p>
              <div className="flex items-center">
                <img src={profileImage} alt="" className="w-8 h-8 " />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <img src={profileImage} alt="" className="w-8 h-8 -ml-4" />
                <div className="flex items-center p-1 rounded-2xl -ml-4 justify-center bg-fcPrimary text-white text-xs">
                  {" "}
                  <p>+2.4k</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => setJoinCliq(!joinCliq)}
              className={`flex items-center justify-center py-1 px-3 rounded-full space-x-1 ${
                joinCliq ? "bg-fcPrimary text-white" : "bg-fcGray4"
              }`}
            >
              <AddUserIcon className={`${joinCliq ? "hidden" : "block"}`} />{" "}
              <GrFormCheckmark
                className={`${joinCliq ? "block text-white" : "hidden"}`}
              />{" "}
              <span className={`${joinCliq ? "hidden" : "block"}`}>Join</span>
              <span className={`${joinCliq ? "block" : "hidden"}`}>Joined</span>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Link
            to=""
            className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
          >
            More people to follow
          </Link>
        </div>
      </div>
      <div className="bg-white rounded-xl w-full p-4">
        <h1 className="text-base font-GilroySemiBold mb-2">Gyms near you</h1>
        <p className="text-base font-GilroyRegular mb-4">
          Check out these popular gyms near you
        </p>
        <div className="first-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">
            Fitness Central Lekki
          </h2>
          <p className="text-base font-GilroyRegular text-fcGray">
            Adekola Balogun Street, Prechstone & Graeys Cl, lekki
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <MapIcon className="mr-1" />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <CallingIcon className="mr-1" />
              Call
            </Button>
          </div>
        </div>
        <div className="second-gym mb-5">
          <div className="mb-2">
            <img src={emailbgImage} alt="" className="w-full h-64 rounded-xl" />
          </div>
          <h2 className="text-base font-GilroySemiBold">Skyfit Lagos</h2>
          <p className="text-base font-GilroyRegular text-fcGray">
            4th Floor, Lagos Oriental Hotel, 3 Lekki - Epe Wxpy, Victoria Island
          </p>
          <div className="flex items-center mb-2">
            <p className="text-xs font-GilroyMedium mr-1">4.4</p>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingEmptyIcon />
            <RatingEmptyIcon />
          </div>
          <p className="text-xs font-GilroyMedium mb-3">
            <span className="text-[#00AF36]">Open</span> . Closes 11 pm
          </p>
          <div className="flex items-center space-x-3">
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <MapIcon className="mr-1" />
              Directions
            </Button>
            <Button className="text-base font-GilroyMedium flex items-center space-x-1 p-2 rounded-full border border-fcPrimary">
              <CallingIcon className="mr-1" />
              Call
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Link
            to=""
            className="text-base font-GilroyMedium bg-fcGray6 py-2 px-3 rounded-full"
          >
            More people to follow
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopSearch;
