import React from "react";
import sendImage from "../../../assets/img/workoutimg-2.png";
import LoadingIcon from "../../../components/icons/LoadingIcon";

const MessageSent = () => {
  return (
    <div className="flex flex-col items-end justify-end space-y-1">
      <div className="bg-fcPrimary p-3 rounded-tl-3xl rounded-bl-3xl rounded-tr-3xl">
        <p className="text-white text-base font-GilroyMedium w-32">
          Hello beauti3xl
        </p>
      </div>
      <div className="bg-fcPrimary p-3 rounded-tl-3xl rounded-bl-3xl">
        <p className="text-white text-base font-GilroyMedium w-96">
          Well, I’m new here. Looking for a community for fellow fitness buff to
          help in achieving my fitness goals
        </p>
      </div>
      <div className="bg-fcPrimary p-3 text-center rounded-tl-3xl rounded-bl-3xl rounded-br-3xl">
        <p className="text-white text-base font-GilroyMedium w-32">
          Hello beauti3xl
        </p>
      </div>
      <div>
        <img src={sendImage} alt="" className="h-36 w-36 rounded-xl" />
      </div>
      <LoadingIcon />
      <p className="text-base font-GilroyMedium">12:00pm</p>
    </div>
  );
};

export default MessageSent;
