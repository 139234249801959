import React from "react";
import BoldFlashIcon from "../../../components/icons/BoldFlashIcon";

const NoChallenges = ({ className }) => {
  return (
    <div className={className}>
      <div className="">
        <div className="flex items-center justify-center">
          <BoldFlashIcon className="w-16 h-16" />
        </div>
        <h1 className="text-xl font-GilroySemiBold text-center">
          Your Challenges
        </h1>
        <p className="text-base font-GilroyRegular text-center">
          The challenges you join and create will show up here. <br /> Click on
          the “+” button above to create <br /> a challenge.
        </p>
      </div>
    </div>
  );
};

export default NoChallenges;
