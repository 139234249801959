import React from 'react'

const PrimaryButton = ({ children, buttonType, type, ...rest }) => {
 
 
  return (
    <>
      <button as={buttonType} {...rest}  type={type} className={"bg-primary w-full px-2 py-2 rounded-full text-yellow-50 font-GilroySemi text-sm my-5 text-center"}>
        {children}
      </button>
    </>
  )
}

export default PrimaryButton
