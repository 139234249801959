import React from 'react'
import profileImage from "../../assets/profile-img.png";

function CoachCardMin() {
  return (
      <div className="flex flex-col items-center justify-center">
          <img src={profileImage} alt="" className="w-12 h-12" />
          <p className="text-sm font-GilroyMedium">Dan Steve</p>
      </div>
  )
}

export default CoachCardMin