import React, {useState} from "react";
import ArrowLeft from "../../../assets/icons/ArrowLeft.svg";
import profileImage from "../../../assets/profile-img.png";
import ExportIcon from "../../../components/icons/ExportIcon";
import { Link } from "react-router-dom";
import { BiShareAlt } from "react-icons/bi";
import SideBar from "../sidebar/SideBar";
import ActiveClients from "../coach/ActiveClients";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import WorkoutIcon from "../../../components/icons/WorkoutIcon";
import SmallTimerIcon from "../../../components/icons/SmallTimerIcon";
import CaloriesIcon from "../../../components/icons/CaloriesIcon";
import GalleryAddIcon from "../../../components/icons/GalleryAddIcon";
import SettingsIcon from "../../../components/icons/SettingsIcon";
import Modal from "../coach/Modal";

const Profile = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <SideBar />
      <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
        <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
          <div className="mt-6">
            <div className="w-[100%] z-40">
              <nav className="py-4 z-50 bg-fcGray6 sticky top-0 flex justify-between">
                <div className="flex items-center gap-2">
                  <Link to="/dashboard/coach">
                    <img
                      src={ArrowLeft}
                      className="bg-white p-2 rounded-full"
                    />
                  </Link>

                  <p className="font-GilroySemiBold text-xl text-fcNavy ">
                    My Profile
                  </p>
                </div>
                <div className="flex space-x-2">
                  
                  <div className="bg-white p-2 rounded-full" onClick={() => {
                    setShowModal(true);
                  }}>
                    <BiShareAlt className="cursor-pointer" />
                  </div>
                  {showModal && <Modal setOpenModal={setShowModal} />}
                  <Link to="/dashboard/settings">
                    <div className="bg-white p-2 rounded-full">
                      <SettingsIcon />
                    </div>
                  </Link>
                </div>
              </nav>
              <div className="shadow-sm relative overflow-x-hidden">
                <div className="bg-[#D1D5DB]  rounded-t-md h-28 "></div>
                <div className="bg-white   rounded-t-md pb-6 rounded-b-md ">
                  <img
                    src={profileImage}
                    alt="dan"
                    className="flex mx-auto w-28 absolute top-12 right-[370px]"
                  />
                  <div className="pt-10 ">
                    <p className="text-center text-fcNavy2 text-2xl font-GilroyBold">
                      Adeshina Adedapo
                    </p>
                    <div className="flex items-center justify-center gap-2 py-2 text-sm text-fcGray font-GilroyMedium">
                      <p>Lagos,NG</p>
                      <p>Male</p>
                      <p>6 ft 2 inches</p>
                    </div>
                    <div className="flex justify-evenly items-center my-2">
                      <div className="items-center">
                        <p className="text-primary text-4xl font-GilroySemiBold ">
                          200K
                        </p>
                        <p className="text-fcGray font-GilroyMedium">Friends</p>
                      </div>
                      <div className="items-center">
                        <p className="text-primary text-4xl font-GilroySemiBold ">
                          2
                        </p>
                        <p className="text-fcGray font-GilroyMedium">Cliqs</p>
                      </div>
                      <div className="items-center">
                        <p className="text-primary text-4xl font-GilroySemiBold ">
                          10
                        </p>
                        <p className="text-fcGray font-GilroyMedium">
                          FC points
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-6 flex justify-between gap-8">
                <div className="w-[70%]">
                  <div className="bg-white w-full rounded-lg shadow-sm pb-3">
                    <div className="flex items-center justify-between  mx-4 pt-4">
                      <p className="text-xl text-fcDark font-GilroySemiBold">
                        My Journey
                      </p>
                      <Link
                        to=""
                        className="flex items-center gap-4 bg-fcGray3 px-2 py-1 rounded-full"
                      >
                        <ExportIcon />
                        <p className="text-xl text-fcDark font-GilroySemiBold">
                          Share
                        </p>
                      </Link>
                    </div>
                    <div className="flex items-center justify-around mb-16">
                      <div>
                        <h1 className="text-[54px] font-GilroySemiBold">150</h1>
                        <div className="flex items-center gap-2">
                          <SmallTimerIcon />
                          <p className="text-[14px] font-GilroyMedium text-fcGray">
                            Minutes
                          </p>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-[54px] font-GilroySemiBold">367</h1>
                        <div className="flex items-center gap-2">
                          <WorkoutIcon />
                          <p className="text-[14px] font-GilroyMedium text-fcGray">
                            Workouts
                          </p>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-[54px] font-GilroySemiBold">
                          9871
                        </h1>
                        <div className="flex items-center gap-2">
                          <CaloriesIcon />
                          <p className="text-[14px] font-GilroyMedium text-fcGray">
                            Calories
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full rounded-lg shadow-sm mt-6 pb-4 pl-4">
                    <div className="w-32 ">
                      <p className="text-xl  font-GilroySemiBold text-fcDark py-4">
                        Progress
                      </p>
                      <div className="bg-fcGray3 py-6 items-center text-center border-2 rounded-xl border-fcGray4 ">
                        <div className="items-center flex justify-center">
                          <GalleryAddIcon />
                        </div>
                        <p>Add Image</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white w-[30%] pl-4 pb-10 rounded-md  h-56 shadow-lg">
                  <div className="flex justify-between items-center mx-2">
                    <p className="pt-4 text-fcDark font-GilroyBold text-xl">
                      Friends
                    </p>
                    <ArrowAngleRight className="" />
                  </div>

                  {Array(2)
                    .fill(true)
                    .map((_, i) => (
                      <ActiveClients key={i} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profile;
