import React from "react";
import ChallengeNav from "../../../components/common/ChallengeNav";
import onebar from "../../../assets/onebar.svg";
import twobar from "../../../assets/twobar.svg";
import fullbar from "../../../assets/fullbar.svg";
import hotbody from "../../../assets/hotbody.png";
import Abburner from "../../../assets/Ab-burner.png";
import fullbody from "../../../assets/fullbody.png";
import Quickcore from "../../../assets/Quick-core.png";
import "../../../App.css";

const Workout2 = () => {
 
  return (
    <div>
      <ChallengeNav />
      <section className="items-center  justify-center mx-auto grid mt-10 ">
        <p className="text-center font-GilroyMedium">Workouts</p>
        <p className="text-sm text-fcGray5 text-center">30 Days Challenge</p>
        <div className="flex items-center gap-3">
          <div className=" mx-auto items-center ">
            <div className="w-4 h-4 text-fcGray4 bg-white rounded-full  border-2"></div>
            <div className="vertical border-l-2 text-fcGray4 h-[100px]  ml-[7px] my-2"></div>
            <div className="w-4 h-4 text-fcGray4 bg-white rounded-full border-2"></div>
            <div className="vertical border-l-2 text-fcGray4 h-[100px]  ml-[7px] my-2"></div>
            <div className="w-4 h-4 text-fcGray4 bg-white rounded-full border-2"></div>
            <div className="vertical border-l-2 text-fcGray4 h-[100px]   ml-[7px] my-2"></div>
            <div></div>
          </div>
          <div className="">
            <div className="">
              <div className="flex items-center gap-2 my-6">
                <img src={Abburner} alt="ab" className="w-[100px]" />
                <div>
                  <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                    Ab Burner
                  </p>
                  <div className="flex gap-2">
                    <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                      30 mins . intensity
                    </p>
                    <img src={onebar} alt="onebar" className="w-[13px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 my-6">
              <img src={Quickcore} alt="ab" className="w-[100px]" />
              <div>
                <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                  Quick Core
                </p>
                <div className="flex gap-2">
                  <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                    10 mins . intensity
                  </p>
                  <img src={twobar} alt="onebar" className="w-[13px]" />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 my-6">
              <img src={hotbody} alt="ab" className="w-[100px]" />
              <div>
                <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                  Hot Body, Arms, Abs and strong
                  <br /> core
                </p>
                <div className="flex gap-2">
                  <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                    10 mins . intensity
                  </p>
                  <img src={fullbar} alt="onebar" className="w-[13px]" />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 my-6">
              <img src={fullbody} alt="ab" className="w-[100px]" />
              <div>
                <p className="text-[#28354D] font-GilroySemiBold text-[16px]">
                  Full Body Build
                </p>
                <div className="flex gap-2">
                  <p className="text-[13px] text-[#2A2E43CC] font-GilroyMedium">
                    7 mins . intensity
                  </p>
                  <img src={fullbar} alt="onebar" className="w-[13px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <button className="mb-5 text-white px-2 font-GilroyMedium py-1 bg-primary text-[14px] rounded-full mx-auto items-center justify-center flex ">
        Add Workout
      </button>
      <div className="my-9">
        <hr className=" text-fcGray4 " />
        <div className="flex items-center justify-center mx-auto gap-4 mt-7  ">
          <button className="px-10 py-2 rounded-full border font-GilroySemi text-sm  text-center">
            Back
          </button>
          <button className="px-8 py-2 rounded-full  font-GilroySemi text-white text-sm text-center bg-primary ">
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Workout2;
