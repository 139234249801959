import React, { useState } from "react";
import MessageIcon from "../../../components/icons/MessageIcon";
import ProfileIcon from "../../../components/icons/BlockIcon";
import BlockIcon from "../../../components/icons/ProfileIcons";

const Modal = ({ setOpenModal }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed  w-full h-full opacity-50"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg mx-auto bg-white ">
            <div className="absolute top-[350px] right-2">
              <div className=" bg-white  px-4  rounded-2xl shadow-sm  ">
                <div className="flex  gap-4 px-2 py-2">
                  <MessageIcon />
                  <p className="font-GilroyMedium text-xl">Message Adedapo</p>
                </div>
                <div className="flex gap-4 px-2 py-2 my-4">
                  <ProfileIcon />
                  <p className="font-GilroyMedium text-xl">Unfriend Adedapo</p>
                </div>
                <div className="flex gap-4 px-2 py-2">
                  <BlockIcon />
                  <p className="font-GilroyMedium text-xl">Block Adedapo</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
