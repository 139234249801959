import React, { useState } from "react";
import BoldAngleArrowRight from "../../../components/icons/BoldAngleArrowRight";
import SideBar from "../sidebar/SideBar";
import profileImage from "../../../assets/profile-img.png";
import Button from "../../../components/common/Button";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import { Link } from "react-router-dom";
import EmptyChallenge from "./EmptyChallenge";
import Challenges from "./Challenges";
import Modal from "../../../components/common/Modal";
import AccountIcon from "../../../assets/AccountIcon.svg";
import ArrowAngleRight from "../../../components/icons/ArrowAngleRight";
import AccountIconWhite from "../../../assets/AccountIconWhite.svg";
import CustomModal from "../../../components/common/CustomModal";
import PeoplesIcon from "../../../components/icons/PeoplesIcon";
import { HiOutlineX } from "react-icons/hi";
import InviteFriends from "../../../components/common/WhoToFollow/InviteFriends";
// import EmptyMember from "./EmptyMember";
import SearchIcon from "../../../components/icons/SearchIcon";
import WhoToFollow from "../../../components/common/WhoToFollow";

const Challenge = () => {
  const [showChallenges, setShowChallenges] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [switchMemberList, setSwitchMemberList] = useState(false);
  return (
    <div>
      <div>
        <SideBar />
        <main className="w-[85%] ml-[15%] bg-fcGray6 px-40 py-10 ">
          <nav className="flex items-center bg-fcGray6 justify-between z-20 sticky top-0 mb-5 py-5  ">
            <h1 className="text-2xl font-GilroySemiBold">Challenges</h1>
          </nav>
          <div className="flex justify-between w-full space-x-4">
            <div className="flex w-2/3 ">
              <div className="w-full">
                <EmptyChallenge
                  setShowChallenges={setShowChallenges}
                  className={` cursor-pointer ${showChallenges ? "hidden" : "block"
                    }`}
                />
                <Challenges
                  className={`${showChallenges ? "block" : "hidden"}`}
                />
              </div>
            </div>
            <WhoToFollow />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Challenge;
