import React from 'react';

const DiscoverIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="vuesax_bold_discover"
          data-name="vuesax/bold/discover"
          transform="translate(-492 -508)"
        >
          <g id="discover" transform="translate(492 508)">
            <path
              id="Vector"
              d="M20.875,1.119a3.773,3.773,0,0,0-3.619-1l-10.78,2.7A5.016,5.016,0,0,0,2.813,6.476L.118,17.267A3.8,3.8,0,0,0,3.792,22a3.411,3.411,0,0,0,.935-.121l10.791-2.7a5.016,5.016,0,0,0,3.663-3.663l2.7-10.791A3.746,3.746,0,0,0,20.875,1.119ZM11,15.265A4.268,4.268,0,1,1,15.265,11,4.272,4.272,0,0,1,11,15.265Z"
              transform="translate(2.003 2.003)"
              fill="#ee5014"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H26V26H0Z"
              transform="translate(26 26) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default DiscoverIcon;
