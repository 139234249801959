import React from 'react'
import brandingBottleOne from "../../assets/img/brandingbottle-1.png";


function DealCard() {
  return (
      <div className="cursor-pointer">
          <div className=" h-64 w-52">
              <img
                  src={brandingBottleOne}
                  alt=""
                  className="w-full"
              />
          </div>
          <p className="text-base font-GilroyBold my-2">
              Fitness Buddies Sport Bot…
          </p>
          <p className="text-base font-GilroyBold">NGN 15,000</p>
      </div>
  )
}

export default DealCard