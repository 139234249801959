import Reac, { useState } from "react";
import SideBar from "../sidebar/SideBar";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../assets/icons/ArrowLeft.svg";
import ArrowDownIcon from "../../../components/icons/ArrowDownIcon";
import Button from "../../../components/common/Button";
import Toggle from "../../../components/common/Toggle";
import EmailNotification from "./EmailNotification";
import PushNotification from "./PushNotification";
const SettingsNotification = () => {
  const [showNotifcations, setShowNotification] = useState(false);
  return (
    <div>
      <div>
        <SideBar />
        <main className=" ml-[18%] bg-fcGray6 mx-auto px-40 h-screen  overflow-x-hidden py-5 ">
          <div className="bg-fcGray6 w-full h-screen mx-auto rounded-3xl">
            <div className="mt-6">
              <div className="w-[65%] z-40 mx-auto">
                <nav className="py-4 z-50 bg-fcGray6 sticky top-0 flex justify-between">
                  <div className="flex items-center gap-4">
                    <Link to="/dashboard/settings">
                      <img
                        src={ArrowLeft}
                        className="bg-white p-2 rounded-full"
                      />
                    </Link>

                    <p className="font-GilroySemiBold text-xl text-fcNavy ">
                      Notifications
                    </p>
                  </div>
                </nav>
                <div className="shadow-sm  overflow-x-hidden">
                  <div className="bg-white   rounded-t-md  rounded-xl  ">
                    <div className="px-4">
                      <div className="flex justify-evenly font-GilroySemiBold pt-4 ">
                        <p
                          className=" text-fcGray5   text-base hover:underline font-GilroySemiBold underline-offset-[20px] decoration-primary decoration-4 cursor-pointer  "
                          onClick={() => setShowNotification(true)}
                        >
                          Email Notifications
                        </p>
                        <p
                          className=" text-fcGray5  text-base hover:underline font-GilroySemiBold underline-offset-[20px] decoration-primary decoration-4 cursor-pointer  "
                          onClick={() => setShowNotification(false)}
                        >
                          Push Notifications
                        </p>
                      </div>
                    </div>
                    <hr className="w-full mt-4 text-fcGray4" />
                  </div>
                
                  {showNotifcations ? (
                    <EmailNotification />
                  ) : (
                    <PushNotification />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsNotification;
