import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
import ArrowAngleRight from '../../../components/icons/ArrowAngleRight';
import Button from '../../../components/common/Button';
import WeightIconOutline from '../../../components/icons/WeightIconOutline';
import WeightMeterIconOutline from '../../../components/icons/WeightMeterIconOutline';
import { HiArrowLeft } from 'react-icons/hi';
import ProfileCircle from '../../../components/icons/ProfileCircle';
import SmallNotification from '../../../components/icons/SmallNotification';
import Privacy from '../../../components/icons/Privacy';
import Global from '../../../components/icons/Global';
import Height from '../../../components/icons/Height';
import Policy from '../../../components/icons/Policy';
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg';

const Settings = () => {
  return (
    <div className="bg-fcGray6 ">
      <div className="bg-fcGray6 ">
        <SideBar />
        <main className="w-ful ml-[18%] bg-fcGray6 mx-auto px-52 h-screen  py-5">
          <div className="bg-fcGray6 ">
            <div className=" mt-5">
              <div className="w-[80%] z-40">
                <nav className=" border-fcGray4 mx-auto pb-5 z-50 bg-fcGray6 sticky top-0 flex items-center gap-2">
                  <div className="text-2xl rounded-full  bg-white   ">
                    <img
                      src={ArrowLeft}
                      className="bg-white p-2 rounded-full"
                    />
                  </div>

                  <p className="text-2xl text-fcNavy2 font-GilroyBold">
                    Settings
                  </p>
                </nav>
                <div className="bg-white p-4 mb-10 rounded-xl  ">
                  <p className="text-fcNavy text-xl font-GilroyBold">Account</p>
                  <Link to="/dashboard/settings/edit">
                    <div className="flex justify-between pt-4 cursor-pointer">
                      <div className="flex items-center space-x-2">
                        <ProfileCircle />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Edit Profile
                        </p>
                      </div>
                      <Link to="/dashboard/settings/edit">
                        <ArrowAngleRight />
                      </Link>
                    </div>
                    <hr className="border-fcGray4 w-full my-4" />{' '}
                  </Link>
                  <Link to="/dashboard/settings/notification">
                    <div className="flex justify-between pt-4 cursor-pointer">
                      <div className="flex items-center space-x-2">
                        <SmallNotification />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Notification
                        </p>
                      </div>
                      <Link to="/dashboard/settings/notification">
                        <ArrowAngleRight />
                      </Link>
                    </div>
                    <hr className="border-fcGray4 w-full my-4" />{' '}
                  </Link>
                  <Link to="/dashboard/settings/privacy">
                    <div className="flex justify-between pt-4 cursor-pointer">
                      <div className="flex items-center space-x-2">
                        <Privacy />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Privacy
                        </p>
                      </div>
                      <Link to="/dashboard/settings/privacy">
                        <ArrowAngleRight />
                      </Link>
                    </div>
                    <hr className="border-fcGray4 w-full my-4" />{' '}
                  </Link>

                  <Link to="/dashboard/settings/reference">
                    <div className="flex justify-between pt-4 cursor-pointer">
                      <div className="flex items-center space-x-2">
                        <WeightIconOutline />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Workout Preference
                        </p>
                      </div>
                      <Link to="/dashboard/settings/reference">
                        <ArrowAngleRight />
                      </Link>
                    </div>
                  </Link>
                </div>

                <div className="bg-white p-4 mb-10 rounded-xl relative">
                  <p className="text-fcNavy text-xl font-GilroyBold">Units</p>
                  <div>
                    <div className="flex justify-between pt-4 my-4">
                      <div className="flex items-center space-x-2">
                        <WeightMeterIconOutline />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Weight
                        </p>
                      </div>
                      <div className="flex justify-center items-center pt-4 text-[13px]  ">
                        <div className="bg-[#fce5dc] flex rounded-md py-[2px]">
                          <p className="px-2 py-[1px] rounded-md font-GilroySemiBold text-sm ">
                            LBS
                          </p>
                          <p className=" bg-primary text-white px-1 rounded-md font-GilroySemiBold text-sm  ">
                            KB
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="border-fcGray4 w-full my-6" />{' '}
                  </div>
                  <div>
                    <div className="flex justify-between ">
                      <div className="flex items-center space-x-2">
                        <Height />
                        <p className="text-base text-fcNavy2 font-GilroyMedium">
                          Height
                        </p>
                      </div>
                      <div className="flex justify-center items-center pt-4 text-[13px]  ">
                        <div className="bg-[#fce5dc] flex rounded-md py-[2px]">
                          <p className=" bg-primary font-GilroySemiBold text-sm  text-white px-1 rounded-md ">
                            FT
                          </p>
                          <p className="px-2 py-[1px] rounded-md font-GilroySemiBold text-sm  ">
                            CM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 mb-10 rounded-xl relative">
                  <p className="text-fcNavy text-xl font-GilroyBold">Legal</p>
                  <div>
                    <Link to="/dashboard/settings/terms">
                      <div className="flex justify-between pt-4 my-4">
                        <div className="flex items-center space-x-2">
                          <Policy />
                          <p className="text-base text-fcNavy2 font-GilroyMedium">
                            Terms of Service
                          </p>
                        </div>

                        <ArrowAngleRight />
                      </div>
                    </Link>
                    <hr className="border-fcGray4 w-full my-6" />{' '}
                  </div>
                  <div>
                    <Link to="/dashboard/settings/policy">
                      <div className="flex justify-between ">
                        <div className="flex items-center space-x-2">
                          <Policy />
                          <p className="text-base text-fcNavy2 font-GilroyMedium">
                            Privacy Policy
                          </p>
                        </div>
                        <ArrowAngleRight />
                      </div>
                    </Link>
                  </div>
                </div>
                <Button className="border-2 border-primary w-full rounded-full my-4 py-4 text-primary font-GilroyBold">
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Settings;
